@use '../variables' as *;

.data-table {
    .el-table {
        font: $font-regular-primary;

        .cell {
            line-height: 18px;
        }

        th .column-title, td .cell {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .error-row {
            --el-table-tr-bg-color: var(--el-color-error-light-9);
        }
    }

    .footer {
        border-top: 1px solid $border-color;
        padding: 8px 10px;

        .pages {
            align-items: center;

            &> * {
                margin-left: 10px;
            }
        }
    }

    .el-table__cell.is-sortable .cell {
        position: relative;
        padding-right: 24px;

        .caret-wrapper {
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -7px;
        }
    }
}

.table-settings {
    label.el-checkbox {
        height: auto;
        display: block;
        margin: 0 0 5px 0;
        max-width: 250px;

        .el-checkbox__label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
