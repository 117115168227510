@use '../variables' as *;

.form-row + .form-row,
.form-block + .form-block,
.form-block + .form-row,
.form-row + .form-block {
	margin-top: 15px;
}

.form-row {
    &__header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        label {
            margin-bottom: 2px;
            display: inline-block;
            font-weight: 500;
            color: $color-grey;
        }
    }

	.errors {
		padding: 0;
		margin: 1px 0 0 0;
		list-style: none;
		position: absolute;

		li {
			color: $color-danger;
			font: $font-small-primary;
		}
	}

	&.inline {
		display: flex;
		flex-direction: row;
		align-items: center;

		label {
			flex: 1;
			margin-bottom: 0;
            color: $color-grey;
		}

        &-between {
            justify-content: space-between;
        }
	}

    .daterange{
        margin-bottom: 10px;
    }
}

.required {
    label:not(.el-checkbox)::after {
        content: '*';
        color: $color-danger;
    }
}

.form-input-group {
    display: flex;
    justify-content: center;
    gap: 5px;

    .form-row + .form-row,
    .form-block + .form-block,
    .form-block + .form-row,
    .form-row + .form-block {
        margin-top: 0!important;
    }
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
.hidden {
    display: none;
}
