@use "sass:color";

// Typography
$font-xlarge-primary: 18px Roboto, sans-serif;
$font-large-primary: 14px Roboto, sans-serif;
$font-regular-primary: 12px Roboto, sans-serif;
$font-small-primary: 10px Roboto, sans-serif;

$font-large-secondary: 36px "Neo Sans Pro Regular", serif ;
$font-medium-secondary: 24px "Neo Sans Pro Regular", serif ;
$font-regular-secondary: 18px "Neo Sans Pro Regular", serif;
$font-small-secondary: 14px "Neo Sans Pro Regular", serif;
$font-tiny-secondary: 12px "Neo Sans Pro Regular", serif;

$line-height-base: 1.6;

// Colors
$color-primary: #116732;
$color-secondary: #17783C;
$color-danger: #CF0C47;
$color-info: #6D7C97;
$color-white: #FFFFFF;
$color-black: #000000;
$color-dark: #222222;
$color-disabled: #888888;
$color-grey: #4F5A6E;

$color-menu-background: $color-secondary;
$font-color: $color-dark;
$border-color: color.scale($color-secondary, $lightness: 70%);
$background-color: color.scale($border-color, $lightness: 70%);
$selection-color: #70B77E;
$selection-color-1: #E3FAEC;
$link-color: $color-secondary;
