@use '../variables' as *;

$point-offset: 5px;

.tree-component {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 $point-offset * 3;

    .tree-container {
        overflow-y: auto;

        .el-tree {
            .el-tree-node {

                &:hover > .el-tree-node__content {
                    background-color: $selection-color-1;
                }

                &.is-expanded > .el-tree-node__content {
                    .el-tree-node__expand-icon {
                        transform: rotate(90deg);
                        transition: transform 0.3s ease-in-out;
                    }
                }

                &__content {
                    font-size: 16px;
                    line-height: 21px;
                    color: $color-grey;
                    padding-top: $point-offset;
                    padding-bottom: $point-offset;
                    display: flex;
                    align-items: center;

                    .item-tree-controls {
                        display: none;
                        gap: $point-offset * 3;
                    }

                    &:hover {
                        .item-tree-controls {
                            display: inline-flex;
                        }
                    }
                }

                .grey {
                    color: $color-disabled;
                }

                &__expand-icon {
                    transform: rotate(0);
                    transition: transform 0.3s ease-in-out;
                    padding-right: $point-offset;

                    &.is-leaf {
                        color: transparent;
                        cursor: default;
                    }
                }

                .custom-tree-node {
                    &__additional {
                        margin-left: $point-offset * 3;
                    }
                }
            }
            .el-tree-node.is-current > .el-tree-node__content {
                background-color: $selection-color-1;
            }
        }
    }
}

