.image-cropper {
$offset: 15px;

    .buttons {
        display: flex;
        gap: $offset;
    }

    .cropper-wrapper {
        display: flex;
        justify-content: center;

        img {
            max-width: 100%;
            height: auto;
        }

        .cropper {
            margin-top: $offset*2;
            display: flex;
            gap: $offset;
            justify-content: center;

            &__cropper {
                width: 300px;
            }
            &__preview {
                overflow: hidden;
                width: 105px;
                height: 135px;
            }
        }
    }
}
