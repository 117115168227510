@use 'variables' as *;
@use "sass:color";

a {
    color: $link-color;
    text-decoration: none;

    &:hover {
        color: color.scale($link-color, $lightness: 50%);
    }
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.flex-column {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.rigid-column {
    display: flex;
    flex-direction: column;
    flex: none;
}

.flex-row {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.rigid-row {
    display: flex;
    flex-direction: row;
    flex: none;
}

.items-center {
    align-items: center;
}

.flex-expand {
    flex: 1;
}

.flex-shrink {
    flex: none;
}

.mb-15 {
    margin-bottom: 15px;
}

.mt-15 {
    margin-top: 15px;
}

.pl-5 {
    padding-left: 5px;
}

.pl-15 {
    padding-left: 15px;
}

.mb-80 {
    margin-bottom: 80px;
}

.w-full {
    width: 100%;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 7px;
}

.question > .el-col {
    display: flex;
    align-items: flex-end;

    .form-row {
        word-break: break-word;
        width: 100%;
        .el-col {
            margin-top: 5px!important;
        }
    }
}

.answer {
    .el-checkbox {
        display: flex;
        align-items: flex-end;
    }
    .content {
        display: flex;
        align-items: flex-end;
        gap: 10px;
        flex-wrap: wrap;
    }
    .checkbox-field-input {
        max-width: 250px;
    }
}

.special-title {
    label {
        font-size: 16px;
        font-weight: bold;
    }
}

.page-heading__header {
    display: flex;
    gap: 15px;
    align-items: center;

    .buttons {
        display: flex;
        gap: 40px;
        align-items: center;
    }
}

.main-control {
    height: 420px;
    overflow-y: auto;

    .buttons {
        display: flex;
        justify-content: space-between;

        .el-button {
            flex: 1;
            text-transform: uppercase;
        }
    }

    .el-button.grey {
        color: $color-info!important;
        border-color: $color-info!important;
    }

    .el-col {
        margin-top: 10px;
    }
}

.search-filter {
    display: flex;
    flex-direction: column;
    padding: 10px;

    .filter-buttons {
        padding: 10px 0;
    }
}

.img-preview {
    max-width: 200px;
    height: auto;
}

.cursor {
    &-pointer {
        cursor: pointer;
    }
}

.flex {
    display: flex;
}

.justify-space-between {
    justify-content: space-between;
}
