@use '../variables' as *;

h1 {
    font: inherit;
    font-size: 24px;
}

.el-input__wrapper {
    width: -webkit-fill-available;
}

.footer-buttons {
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .button {

        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 120%;

        letter-spacing: 2px;
        text-transform: uppercase;

        margin: 0 20px;
        width: -webkit-fill-available;
    }
}
