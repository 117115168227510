.el-upload {
    display: inline-block;
    text-align: center;
    cursor: pointer;
    outline: none;

    &__input {
        display: none;
    }

    &__tip {
        font-size: 12px;
        color: #606266;
        margin-top: 7px;
    }
}
