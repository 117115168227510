@use '../variables' as *;

.svg-icon {
    display: inline-block;

    svg, span {
        vertical-align: middle;
    }
    span {
        margin-left: 5px;
    }
    svg {
        width: 25px;
        height: 25px;
        fill: $color-primary;
    }

    &.disabled{
        svg {
            fill: grey!important;
            cursor: default!important;
        }
    }

    &.icon-large svg {
        width: 30px;
        height: 30px;
    }
    &.icon-small svg {
        width: 20px;
        height: 20px;
    }
    &.icon-tiny svg {
        width: 16px;
        height: 16px;
    }
    &.icon-xtiny svg {
        width: 13px;
        height: 13px;
    }
    &.icon-tiddly svg {
        width: 10px;
        height: 10px;
    }

    &.icon-white svg {
        fill: $color-white;
    }
    &.icon-grey svg {
        fill: $color-secondary;
    }
    &.icon-hover:hover svg {
        fill: $color-primary;
    }
    &.icon-white-hover:hover svg {
        fill: $color-white;
    }
    &.icon-grey-hover:hover svg {
        fill: $color-secondary;
    }
}
