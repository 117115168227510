.pie-chart-set {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 5%;
}

.analytics-filters {
    padding: 20px;

    .buttons {
        margin-top: 20px;
    }
}

.no-data {
    display: flex;
    justify-content: center;
    align-items: center;
}
