@use 'variables' as *;

body {
    margin: 0;
    padding: 0;
    background: none;

    font: $font-regular-primary;
}

.main-container {
    min-height: 100vh;
}

.el-main {
    padding: 0;
}

.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .page-heading {
        padding: 0 10px;
        flex: none;

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__addons {
                display: flex;
                align-items: center;
                gap: 40px;
            }

            h2 {
                font: $font-medium-secondary;
                margin: 10px 0;
            }
        }
    }
}

section.dialog-body {
    padding: 15px 40px;
}

section.dialog-body + section.dialog-body {
    border-top: 1px solid $border-color;
}

section.dialog-footer {
    padding: 15px;
    border-top: 1px solid $border-color;
    text-align: right;
}

section.popover-footer {
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid $border-color;
    text-align: right;
}

.el-col {
    margin-top: 20px;
}

