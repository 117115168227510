@use '../variables' as *;

.cv-wrapper {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	height: 100%;
	min-height: 100%;
	max-height: 100%;
	overflow-x: hidden;
	overflow-y: hidden
}

.cv-wrapper,
.cv-wrapper div {
	box-sizing: border-box;
	line-height: 1em;
	font-size: 1em
}

.cv-header-days {
	display: flex;
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: auto;
	flex-flow: row nowrap;
	border-width: 0 0 0 1px
}

.cv-header-day {
	display: flex;
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: 0;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	text-align: center;
	border-width: 1px 1px 0 0
}

.cv-weeks {
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
	flex-flow: column nowrap;
	border-width: 0 0 1px 1px;
	overflow-y: auto;
	-ms-overflow-style: none
}

.cv-weeknumber {
	width: 2rem;
	position: relative;
	text-align: center;
	border-width: 1px 1px 0 0;
	border-style: solid;
	line-height: 1
}

.cv-week {
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;
	flex-flow: row nowrap;
	min-height: 3em;
	border-width: 0;
	position: relative;
	width: 100%;
	overflow-y: auto;
	-ms-overflow-style: none
}

.cv-weekdays {
	display: flex;
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: 0;
	flex-flow: row nowrap;
	direction: ltr;
	position: relative;
	overflow-y: auto
}

.cv-day {
	display: flex;
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: 0;
	position: relative;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	border-width: 1px 1px 0 0;
	direction: initial
}

.cv-day-number {
	height: auto;
	align-self: flex-start
}

.cv-day[draggable],
.cv-item[draggable] {
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none
}

.cv-wrapper.wrap-item-title-on-hover .cv-item:hover {
	white-space: normal;
	z-index: 1
}

.cv-header-days,
.cv-header-day,
.cv-weeks,
.cv-week,
.cv-day {
	border-style: solid;
	border-color: $border-color;
}

.cv-item {
    position: absolute;
	white-space: nowrap;
	overflow: hidden;
	direction: initial;
	background-color: $background-color;
	color: $color-primary;
    border-radius: 3px;
    padding: 6px 3px;

    .endTime::before {
        content: ' - ';
    }

    .endTime::after {
        content: '.';
    }
}

.cv-header-day {
	padding: .2em
}

.cv-day-number {
    padding: 5px;
    flex-grow: 1;
}

.cv-item.offset0 {
	left: 0
}

.cv-item.offset1 {
	left: 14.28571%
}

.cv-item.offset2 {
	left: 28.57143%
}

.cv-item.offset3 {
	left: 42.85714%
}

.cv-item.offset4 {
	left: 57.14286%
}

.cv-item.offset5 {
	left: 71.42857%
}

.cv-item.offset6 {
	left: 85.71429%
}

.cv-item.span1 {
	width: calc(14.28571% - 0.05em)
}

.cv-item.span2 {
	width: calc(28.57143% - 0.05em)
}

.cv-item.span3 {
	width: calc(42.85714% - 0.05em)
}

.cv-item.span4 {
	width: calc(57.14286% - 0.05em)
}

.cv-item.span5 {
	width: calc(71.42857% - 0.05em)
}

.cv-item.span6 {
	width: calc(85.71429% - 0.05em)
}

.cv-item.span7 {
	width: calc(100% - 0.05em)
}

.holidaytime {
    background-color: red;
    color: white;
}

.cv-weeks::-webkit-scrollbar,
.cv-week::-webkit-scrollbar {
	width: 0;
	background: transparent
}

.cv-header {
	display: flex;
	flex-grow: 1;
	flex-flow: row nowrap;
	align-items: center;

    button {
        border: 2px solid $color-primary;
        border-radius: 3px;
        background-color: $color-white;
        padding: 6px 15px;
        color: $color-primary;
        font-weight: 600;
        margin-right: 10px;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    .periodLabel {
        font: $font-xlarge-primary;
    }
}

.schedule-container {
    .buttons {
        border-top: 1px solid $border-color;
        padding: 8px 10px;
    }

    .header-container {
        border: 1px solid $border-color;
        padding: 8px 10px;
    }
}

.cv-day.outsideOfMonth {
    background-color: $background-color;
}

.cv-day .el-checkbox {
    width: 18px;
    height: 20px;
}

.schedule-form {
	.schedule-item {
		position: relative;
	}

	.schedule-item + .schedule-item {
		padding-top: 25px;
	}

	.remove-item {
		position: absolute;
		top: 5px;
		right: 15px;
		color: $color-danger;
	}
}
