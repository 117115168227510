@use 'variables' as *;
@use "sass:color";

@forward 'element-plus/theme-chalk/src/common/var.scss' with (
  $colors: (
    'primary': (
      'base': $color-primary,
    ),
    'danger': (
      'base': $color-danger,
    ),
    'info': (
      'base': $color-info,
    ),
  ),
  $common-component-size: (
    'large': 40px,
    'default': 30px,
    'small': 24px,
  ),
  $input: (
    'border-radius': 3px,
    'transparent-border': 0 0 0 2px transparent inset,
  ),
  $input-font-size: (
    'large': 12px,
    'default': 12px,
    'small': 10px,
  ),
  $input-padding-horizontal: (
    'large': 12px,
    'default': 8px,
    'small': 5px,
  ),
  $button-border-radius: (
    'large': 3px,
    'default': 3px,
    'small': 2px,
  ),
  $button-font-size: (
    'large': 12px,
    'default': 12px,
    'small': 10px,
  ),
  $button: (
    'font-weight': 600,
  ),
  $button-padding-vertical: (
    'large': 13px,
    'default': 7px,
    'small': 6px,
  ),
  $radio-height: (
    'large': 40px,
    'default': 30px,
    'small': 24px,
  ),
  $menu: (
    'active-color': $color-white,
    'text-color': $color-white,
    'hover-text-color': $color-white,
    'bg-color': $color-secondary,
    'hover-bg-color': color.scale($color-secondary, $lightness: -30%),
    'item-height': 70px,
    'border-color': transparent,
  ),
  $table: (
    'border-color': $border-color,
    'header-bg-color': $background-color,
    'row-hover-bg-color': $background-color,
    'current-row-bg-color': $selection-color,
  ),
  $table-padding: (
    'large': 8px 0,
    'default': 4px 0,
    'small': 2px 0,
  ),
  $table-cell-padding: (
    'large': 0 10px,
    'default': 0 5px,
    'small': 0 3px,
  ),
  $date-editor: (
    'width': 100%,
    'monthrange-width': 100%,
    'daterange-width': 100%,
    'datetimerange-width': 100%,
  ),
  $form: (
    'label-font-size': 12px,
  ),
);

// Use only those components you really need
@use '~element-plus/theme-chalk/src/base.scss';
@use '~element-plus/theme-chalk/src/overlay.scss';
@use '~element-plus/theme-chalk/src/input.scss';
@use '~element-plus/theme-chalk/src/select.scss';
@use '~element-plus/theme-chalk/src/select-dropdown.scss';
@use '~element-plus/theme-chalk/src/option.scss';
@use '~element-plus/theme-chalk/src/popper.scss';
@use '~element-plus/theme-chalk/src/button.scss';
@use '~element-plus/theme-chalk/src/switch.scss';
@use '~element-plus/theme-chalk/src/table.scss';
@use '~element-plus/theme-chalk/src/table-column.scss';
@use '~element-plus/theme-chalk/src/dialog.scss';
@use '~element-plus/theme-chalk/src/message.scss';
@use '~element-plus/theme-chalk/src/loading.scss';
@use '~element-plus/theme-chalk/src/aside.scss';
@use '~element-plus/theme-chalk/src/dropdown-item.scss';
@use '~element-plus/theme-chalk/src/dropdown-menu.scss';
@use '~element-plus/theme-chalk/src/dropdown.scss';
@use '~element-plus/theme-chalk/src/col.scss';
@use '~element-plus/theme-chalk/src/row.scss';
@use '~element-plus/theme-chalk/src/container.scss';
@use '~element-plus/theme-chalk/src/main.scss';
@use '~element-plus/theme-chalk/src/sub-menu.scss';
@use '~element-plus/theme-chalk/src/menu.scss';
@use '~element-plus/theme-chalk/src/menu-item.scss';
@use '~element-plus/theme-chalk/src/message-box.scss';
@use '~element-plus/theme-chalk/src/radio-button.scss';
@use '~element-plus/theme-chalk/src/radio-group.scss';
@use '~element-plus/theme-chalk/src/date-picker.scss';
@use '~element-plus/theme-chalk/src/time-select.scss';
@use '~element-plus/theme-chalk/src/time-picker.scss';
@use '~element-plus/theme-chalk/src/pagination.scss';
@use '~element-plus/theme-chalk/src/scrollbar.scss';
@use '~element-plus/theme-chalk/src/popover.scss';
@use '~element-plus/theme-chalk/src/checkbox.scss';
@use '~element-plus/theme-chalk/src/checkbox-group.scss';
@use '~element-plus/theme-chalk/src/tabs.scss';
@use '~element-plus/theme-chalk/src/tab-pane.scss';
@use '~element-plus/theme-chalk/src/tag.scss';
@use '~element-plus/theme-chalk/src/option-group.scss';
@use '~element-plus/theme-chalk/src/option-item.scss';
@use '~element-plus/theme-chalk/src/image';
@use '~element-plus/theme-chalk/src/image-viewer';
@use '~element-plus/theme-chalk/src/descriptions';
@use '~element-plus/theme-chalk/src/descriptions-item';

// Overrides

// Buttons
.el-button:not(.el-button--primary):not(.el-button--danger):not(.el-button--info) {
  border-width: 2px;
  border-color: $color-primary;
  border-radius: 4px;
  color: $color-primary;

  &.is-disabled {
    color: $color-disabled;
    border-color: $color-disabled;
  }

  &:hover:not(.is-disabled),
  &:focus:not(.is-disabled) {
    border-color: $color-primary;
  }

  &.icon-button {
    padding-left: 6px;
    padding-right: 6px;
  }
}

// Input
.el-input, .el-textarea {
  font: $font-regular-primary;
    .el-input__wrapper, .el-textarea__inner {
        box-shadow: 0 0 0 2px $color-grey inset;
    }
}

.el-input {
    .el-input__wrapper {
        box-shadow: 0 0 0 2px $color-grey inset;
    }
    .el-input-group__prepend {
        width: 1px;
    }
    .centered {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
    }
}

.el-textarea__inner {
  padding: 5px 7px;
}

// Radio
.el-radio-group {
    display: flex;

    .el-radio-button {
        flex: 1;
        height: 30px;

        &__inner {
            width: 100%;
            height: 100%;
            border-width: 2px;
            border-color: $color-grey;
        }
    }
    .el-radio-button--large {
        height: 40px;
    }
}

.el-radio-button:first-child .el-radio-button__inner {
    border-left-width: 2px;
    border-left-color: $color-grey;
}

.el-textarea.span-2 .el-textarea__inner {
  height: 91px;
}

// Select
.el-select {
  display: block;
}

.el-select-dropdown__item {
  font: $font-regular-primary;
  line-height: 30px;
  height: 30px;
}

.el-select-dropdown__item.selected {
  font-weight: 500;
}

.el-select .el-select__tags-text {
  justify-content: left;
}

.el-select-group__title {
    color: black;
    font-size: 18px;
}

// Dialog
.el-dialog__header {
  border-bottom: 1px solid $border-color;
  padding: 15px;
}

.el-dialog__title {
  font: $font-regular-secondary;
}

.el-dialog__body {
  padding: 0;
  font: $font-regular-primary;
}

// Tabs
.el-tabs__item {
  font: $font-regular-primary;
  font-weight: 500;
  height: 32px;
  line-height: 32px;
}

.el-tabs__nav-wrap::after {
  background-color: $background-color;
  line-height: 32px;
  height: 32px;
}

.el-tabs__header {
  background-color: $background-color;
  margin: 0;
  padding: 0 10px;
}

.el-tabs__nav-next, .el-tabs__nav-prev {
    z-index: 2;
    line-height: 32px!important;
    height: 32px!important;
}

// Operator status
.status-panel {
    display: flex;
    justify-content: space-between;
}

// warning text new pupil image
.img-warning {
    color: #dc122a;
    div {
        word-break: normal;
        margin-bottom: 20px;
    }
}

// upload
.el-upload__tip {
    font-size: 12px;
    color: #606266;
    margin-top: 7px;
    font-style: italic;
}

.el-upload__upload_button {
    margin-left: 10px;
}

.el-upload-list {
    margin: 15px 0 0;
    padding: 0;
    list-style: none;
    position: relative;

    &__item {
        margin-bottom: 5px;
        position: relative;
        box-sizing: border-box;
        border-radius: 4px;
        width: 100%;

        &:last-child {
            margin-bottom: 0;
        }

        &-name {
            cursor: pointer;
        }

        &-info {
            display: inline-flex;
            justify-content: center;
            flex-direction: column;
            width: calc(100% - 30px);
            margin-left: 4px;
        }

        &-status-label {
            position: absolute;
            right: 5px;
            top: 0;
            line-height: inherit;
            display: inline-flex;
            height: 100%;
            justify-content: center;
            align-items: center;
            color: var(--el-color-success);
        }

        .el-icon {
            &--close {
                display: none;
                position: absolute;
                right: 5px;
                top: 50%;
                cursor: pointer;
                opacity: .75;
                color: var(--el-color-danger);
                transition: opacity var(--el-transition-duration);
                transform: translateY(-50%);

                &-tip {
                    display: none;
                    position: absolute;
                    top: 1px;
                    right: 5px;
                    font-size: 12px;
                    cursor: pointer;
                    opacity: 1;
                    color: var(--el-color-primary);
                    font-style: normal;
                }
            }
        }

        &:hover {
            .el-upload-list__item-status-label {
                display: none;
                opacity: 0;
            }
            .el-icon--close {
                display: inline-flex;
            }
        }
    }

}


.controls {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    row-gap: 12px;

    .el-button + .el-button {
        margin-left: 0;
    }
}


.loading-block {
    width: 100%;
    height: 30px;
}

.category-tree-btn{
    margin-left: 15px;
}

.el-descriptions {
    &__title {
        font-size: 14px; /* Розмір шрифту для заголовків */
    }

    .el-descriptions__body .el-descriptions__table .el-descriptions__cell {
        font-size: 12px; /* Розмір шрифту для тексту в комірках */
        white-space: nowrap; /* Забороняє перенос тексту */
        overflow: hidden; /* Обрізає текст, якщо він не вміщується */
        text-overflow: ellipsis; /* Додає три крапки для обрізаного тексту */
        vertical-align: middle; /* Вирівнює текст вертикально по центру */
    }

    .el-descriptions__body .el-descriptions__table.is-bordered .el-descriptions__cell {
        padding: 4px 11px; /* Відступи у межах комірок */
    }
}
