@font-face {
  font-family: 'Neo Sans Pro Regular';
  src: url('fonts/NeoSansPro/NeoSansPro-Regular.eot');
  src: local('Neo Sans Pro'), local('NeoSansPro-Regular'),
  url('fonts/NeoSansPro/NeoSansPro-Regular.eot?#iefix') format('embedded-opentype'),
  url('fonts/NeoSansPro/NeoSansPro-Regular.woff') format('woff'),
  url('fonts/NeoSansPro/NeoSansPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500');