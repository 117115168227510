.change-log {
    line-height: 150%;
    white-space: normal;

    .attribute {
        font-weight: 500;
    }
    
    .old {
        text-decoration: line-through;
    }
}