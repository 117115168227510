@use '../variables' as *;

.bg-login {
    background: url('/svg/start-screen.png') $color-primary no-repeat center;
    background-size: cover;

    .content {
        .el-main {
            padding: 20px !important;
        }
    }
}

.login-form-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 370px;
    margin: 0 auto;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    color: $color-white;

    .login-app-logo {
        width: 300px;
        height: 130px;
        background: url("/svg/app-logo.svg") $color-primary no-repeat center;
        border-radius: 12px;
    }

    h1 {
        color: $color-white;
        margin: 25px 0 20px 0;
        font: $font-medium-secondary;
        line-height: 30px;
    }

    .login-form-data {
        max-width: 200px;
    }

    .form-row {
        text-align: start;
        label {
            font: $font-small-primary;
            color: $color-white;
        }
    }

    .login-form-input {
        text-align: left;
        font: $font-regular-primary;
    }

    .form-footer {
        margin: 20px 0;
    }

    .form-input {
        margin-bottom: 10px;
    }

    .el-input .el-input__inner {
        background: none;
        border: none;
        border-radius: 0;
        color: $color-black;
        height: auto;
        line-height: 30px;
        padding-left: 0;

        &:focus {
            color: $color-black;
        }
    }

    label {
        text-align: left;
        color: $color-white;
    }

    .btn-diia{
        width: 180px;
        height: 50px;
        background: url('/svg/idGovUaDiia.svg') $color-primary no-repeat center;
        background-size: 75% auto;
    }


    .btn-diia1 {
        width: 180px;
        height: 50px;
        background: url('/svg/idgov_logo.png') no-repeat center;
        background-size: 75% auto;
        cursor: pointer;

        &:hover {
            box-shadow: 0 0 0 2px $color-white inset;
            border-radius: 4px;
        }
    }
}
