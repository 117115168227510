@use '../variables' as *;

.el-aside {
    transition: width .5s;
}

.aside-wrapper {
    background-color: $color-secondary;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 0 8px 8px 0;

    .logo, .menu-footer {
        flex-shrink: 1;
    }

    .side-menu-container {
        flex-grow: 1;
    }

    .logo {
        font: $font-regular-secondary;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        color: $color-white;
        overflow: hidden;
        white-space: nowrap;

        .logo-item {
            flex: none;
            width: 97px;
            height: 32px;
            background: url('/svg/sidebar-logo.svg') $color-secondary no-repeat center;
            margin-left: 15px;
            border-radius: 8px;
        }

        .version {
            margin-right: 15px;
        }
    }

    .menu-footer {
        margin: 5px;
        border-top: 1px solid darken($color-secondary, 5%);
        color: $color-white;
        padding: 10px 5px 5px 5px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        .user-info {
            flex-grow: 1;

            .name {
                margin: 0 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                flex: auto;
                font: $font-large-primary;
            }

            svg {
                width: 28px;
                height: 28px;
            }
        }

        .toggle-menu-btn {
            width: 28px;
            height: 28px;
            flex-shrink: 1;
            position: relative;

            &::before {
                content: ' ';
                width: 14px;
                height: 14px;
                border-top: 3px solid $color-white;
                border-left: 3px solid $color-white;
                display: block;
                top: 50%;
                left: 50%;
                margin: -7px 0 0 -7px;
                position: absolute;
                transform: rotate(-45deg);
                transition: transform .5s;
            }
        }
    }

    &.collapsed {
        .menu-footer .toggle-menu-btn::before {
            transform: rotate(135deg);
        }

        .el-menu-item {
            .svg-icon svg {
                margin-right: 0;
            }
        }

        .logo .logo-item {
            width: 60px;
            background: url("/svg/min-logo.svg") $color-secondary no-repeat center;
            margin-left: 0;
            height: 40px;
        }
    }

    .el-menu-item {
        font: $font-regular-primary;
        font-weight: 500 !important;
        display: flex;
        padding: 0 10px !important;
        height: 40px;

        .el-tooltip__trigger {
            padding: 0 10px !important;
        }

        .svg-icon svg {
            width: 30px;
            height: 30px;
            margin-right: 10px;
            margin-left: 5px;
        }
    }

    .el-menu-item.is-active {
        background: $color-primary;
    }
}

.el-menu--horizontal {
    border-bottom: none;
    background-color: $color-white;

    .el-menu-item {
        height: 30px;
        font: $font-regular-primary;
        color: $color-grey;
        font-weight: 500;
        border-bottom-width: 3px;

        &.is-active {
            color: $color-primary!important;
            border-bottom-color: $color-primary;
            border-bottom-width: 3px;
        }
    }

    .el-sub-menu.is-active .el-sub-menu__title {
        border-bottom-color: $color-primary;
        border-bottom-width: 3px;
    }

    .el-sub-menu .el-sub-menu__title:hover {
        color: $color-primary;
    }

    .el-menu-item:not(.is-disabled):hover,
    .el-menu-item:not(.is-disabled):focus {
        background-color: $color-white;
        color: $color-primary!important;
    }
}
