@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500");
@font-face {
  font-family: "Neo Sans Pro Regular";
  src: url("fonts/NeoSansPro/NeoSansPro-Regular.eot");
  src: local("Neo Sans Pro"), local("NeoSansPro-Regular"), url("fonts/NeoSansPro/NeoSansPro-Regular.eot?#iefix") format("embedded-opentype"), url("fonts/NeoSansPro/NeoSansPro-Regular.woff") format("woff"), url("fonts/NeoSansPro/NeoSansPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/* Element Chalk Variables */
:root {
  --el-color-white: #ffffff;
  --el-color-black: #000000;
  --el-color-primary-rgb: 17, 103, 50;
  --el-color-success-rgb: 103, 194, 58;
  --el-color-warning-rgb: 230, 162, 60;
  --el-color-danger-rgb: 207, 12, 71;
  --el-color-error-rgb: 245, 108, 108;
  --el-color-info-rgb: 109, 124, 151;
  --el-font-size-extra-large: 20px;
  --el-font-size-large: 18px;
  --el-font-size-medium: 16px;
  --el-font-size-base: 14px;
  --el-font-size-small: 13px;
  --el-font-size-extra-small: 12px;
  --el-font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
  --el-font-weight-primary: 500;
  --el-font-line-height-primary: 24px;
  --el-index-normal: 1;
  --el-index-top: 1000;
  --el-index-popper: 2000;
  --el-border-radius-base: 4px;
  --el-border-radius-small: 2px;
  --el-border-radius-round: 20px;
  --el-border-radius-circle: 100%;
  --el-transition-duration: 0.3s;
  --el-transition-duration-fast: 0.2s;
  --el-transition-function-ease-in-out-bezier: cubic-bezier(0.645, 0.045, 0.355, 1);
  --el-transition-function-fast-bezier: cubic-bezier(0.23, 1, 0.32, 1);
  --el-transition-all: all var(--el-transition-duration) var(--el-transition-function-ease-in-out-bezier);
  --el-transition-fade: opacity var(--el-transition-duration) var(--el-transition-function-fast-bezier);
  --el-transition-md-fade: transform var(--el-transition-duration) var(--el-transition-function-fast-bezier), opacity var(--el-transition-duration) var(--el-transition-function-fast-bezier);
  --el-transition-fade-linear: opacity var(--el-transition-duration-fast) linear;
  --el-transition-border: border-color var(--el-transition-duration-fast) var(--el-transition-function-ease-in-out-bezier);
  --el-transition-box-shadow: box-shadow var(--el-transition-duration-fast) var(--el-transition-function-ease-in-out-bezier);
  --el-transition-color: color var(--el-transition-duration-fast) var(--el-transition-function-ease-in-out-bezier);
}

:root {
  color-scheme: light;
  --el-color-white: #ffffff;
  --el-color-black: #000000;
  --el-color-primary: #116732;
  --el-color-primary-light-3: #589570;
  --el-color-primary-light-5: #88b399;
  --el-color-primary-light-7: #b8d1c2;
  --el-color-primary-light-8: #cfe1d6;
  --el-color-primary-light-9: #e7f0eb;
  --el-color-primary-dark-2: #0e5228;
  --el-color-success: #67c23a;
  --el-color-success-light-3: #95d475;
  --el-color-success-light-5: #b3e19d;
  --el-color-success-light-7: #d1edc4;
  --el-color-success-light-8: #e1f3d8;
  --el-color-success-light-9: #f0f9eb;
  --el-color-success-dark-2: #529b2e;
  --el-color-warning: #e6a23c;
  --el-color-warning-light-3: #eebe77;
  --el-color-warning-light-5: #f3d19e;
  --el-color-warning-light-7: #f8e3c5;
  --el-color-warning-light-8: #faecd8;
  --el-color-warning-light-9: #fdf6ec;
  --el-color-warning-dark-2: #b88230;
  --el-color-danger: #CF0C47;
  --el-color-danger-light-3: #dd557e;
  --el-color-danger-light-5: #e786a3;
  --el-color-danger-light-7: #f1b6c8;
  --el-color-danger-light-8: #f5ceda;
  --el-color-danger-light-9: #fae7ed;
  --el-color-danger-dark-2: #a60a39;
  --el-color-error: #f56c6c;
  --el-color-error-light-3: #f89898;
  --el-color-error-light-5: #fab6b6;
  --el-color-error-light-7: #fcd3d3;
  --el-color-error-light-8: #fde2e2;
  --el-color-error-light-9: #fef0f0;
  --el-color-error-dark-2: #c45656;
  --el-color-info: #6D7C97;
  --el-color-info-light-3: #99a3b6;
  --el-color-info-light-5: #b6becb;
  --el-color-info-light-7: #d3d8e0;
  --el-color-info-light-8: #e2e5ea;
  --el-color-info-light-9: #f0f2f5;
  --el-color-info-dark-2: #576379;
  --el-bg-color: #ffffff;
  --el-bg-color-page: #ffffff;
  --el-bg-color-overlay: #ffffff;
  --el-text-color-primary: #303133;
  --el-text-color-regular: #606266;
  --el-text-color-secondary: #909399;
  --el-text-color-placeholder: #a8abb2;
  --el-text-color-disabled: #c0c4cc;
  --el-border-color: #dcdfe6;
  --el-border-color-light: #e4e7ed;
  --el-border-color-lighter: #ebeef5;
  --el-border-color-extra-light: #f2f6fc;
  --el-border-color-dark: #d4d7de;
  --el-border-color-darker: #cdd0d6;
  --el-fill-color: #f0f2f5;
  --el-fill-color-light: #f5f7fa;
  --el-fill-color-lighter: #fafafa;
  --el-fill-color-extra-light: #fafcff;
  --el-fill-color-dark: #ebedf0;
  --el-fill-color-darker: #e6e8eb;
  --el-fill-color-blank: #ffffff;
  --el-box-shadow: 0px 12px 32px 4px rgba(0, 0, 0, 0.04), 0px 8px 20px rgba(0, 0, 0, 0.08);
  --el-box-shadow-light: 0px 0px 12px rgba(0, 0, 0, 0.12);
  --el-box-shadow-lighter: 0px 0px 6px rgba(0, 0, 0, 0.12);
  --el-box-shadow-dark: 0px 16px 48px 16px rgba(0, 0, 0, 0.08), 0px 12px 32px rgba(0, 0, 0, 0.12), 0px 8px 16px -8px rgba(0, 0, 0, 0.16);
  --el-disabled-bg-color: var(--el-fill-color-light);
  --el-disabled-text-color: var(--el-text-color-placeholder);
  --el-disabled-border-color: var(--el-border-color-light);
  --el-overlay-color: rgba(0, 0, 0, 0.8);
  --el-overlay-color-light: rgba(0, 0, 0, 0.7);
  --el-overlay-color-lighter: rgba(0, 0, 0, 0.5);
  --el-mask-color: rgba(255, 255, 255, 0.9);
  --el-mask-color-extra-light: rgba(255, 255, 255, 0.3);
  --el-border-width: 1px;
  --el-border-style: solid;
  --el-border-color-hover: var(--el-text-color-disabled);
  --el-border: var(--el-border-width) var(--el-border-style) var(--el-border-color);
  --el-svg-monochrome-grey: var(--el-border-color);
}

.fade-in-linear-enter-active,
.fade-in-linear-leave-active {
  transition: var(--el-transition-fade-linear);
}

.fade-in-linear-enter-from,
.fade-in-linear-leave-to {
  opacity: 0;
}

.el-fade-in-linear-enter-active,
.el-fade-in-linear-leave-active {
  transition: var(--el-transition-fade-linear);
}

.el-fade-in-linear-enter-from,
.el-fade-in-linear-leave-to {
  opacity: 0;
}

.el-fade-in-enter-active,
.el-fade-in-leave-active {
  transition: all var(--el-transition-duration) cubic-bezier(0.55, 0, 0.1, 1);
}

.el-fade-in-enter-from,
.el-fade-in-leave-active {
  opacity: 0;
}

.el-zoom-in-center-enter-active,
.el-zoom-in-center-leave-active {
  transition: all var(--el-transition-duration) cubic-bezier(0.55, 0, 0.1, 1);
}

.el-zoom-in-center-enter-from,
.el-zoom-in-center-leave-active {
  opacity: 0;
  transform: scaleX(0);
}

.el-zoom-in-top-enter-active,
.el-zoom-in-top-leave-active {
  opacity: 1;
  transform: scaleY(1);
  transition: var(--el-transition-md-fade);
  transform-origin: center top;
}
.el-zoom-in-top-enter-active[data-popper-placement^=top],
.el-zoom-in-top-leave-active[data-popper-placement^=top] {
  transform-origin: center bottom;
}

.el-zoom-in-top-enter-from,
.el-zoom-in-top-leave-active {
  opacity: 0;
  transform: scaleY(0);
}

.el-zoom-in-bottom-enter-active,
.el-zoom-in-bottom-leave-active {
  opacity: 1;
  transform: scaleY(1);
  transition: var(--el-transition-md-fade);
  transform-origin: center bottom;
}

.el-zoom-in-bottom-enter-from,
.el-zoom-in-bottom-leave-active {
  opacity: 0;
  transform: scaleY(0);
}

.el-zoom-in-left-enter-active,
.el-zoom-in-left-leave-active {
  opacity: 1;
  transform: scale(1, 1);
  transition: var(--el-transition-md-fade);
  transform-origin: top left;
}

.el-zoom-in-left-enter-from,
.el-zoom-in-left-leave-active {
  opacity: 0;
  transform: scale(0.45, 0.45);
}

.collapse-transition {
  transition: var(--el-transition-duration) height ease-in-out, var(--el-transition-duration) padding-top ease-in-out, var(--el-transition-duration) padding-bottom ease-in-out;
}

.el-collapse-transition-leave-active,
.el-collapse-transition-enter-active {
  transition: var(--el-transition-duration) max-height ease-in-out, var(--el-transition-duration) padding-top ease-in-out, var(--el-transition-duration) padding-bottom ease-in-out;
}

.horizontal-collapse-transition {
  transition: var(--el-transition-duration) width ease-in-out, var(--el-transition-duration) padding-left ease-in-out, var(--el-transition-duration) padding-right ease-in-out;
}

.el-list-enter-active,
.el-list-leave-active {
  transition: all 1s;
}

.el-list-enter-from,
.el-list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.el-list-leave-active {
  position: absolute !important;
}

.el-opacity-transition {
  transition: opacity var(--el-transition-duration) cubic-bezier(0.55, 0, 0.1, 1);
}

.el-icon-loading {
  animation: rotating 2s linear infinite;
}

.el-icon--right {
  margin-left: 5px;
}

.el-icon--left {
  margin-right: 5px;
}

@keyframes rotating {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
.el-icon {
  --color: inherit;
  height: 1em;
  width: 1em;
  line-height: 1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  fill: currentColor;
  color: var(--color);
  font-size: inherit;
}
.el-icon.is-loading {
  animation: rotating 2s linear infinite;
}

.el-icon svg {
  height: 1em;
  width: 1em;
}

.el-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  height: 100%;
  background-color: var(--el-overlay-color-lighter);
  overflow: auto;
}
.el-overlay .el-overlay-root {
  height: 0;
}

.el-textarea {
  --el-input-text-color: var(--el-text-color-regular);
  --el-input-border: var(--el-border);
  --el-input-hover-border: var(--el-border-color-hover);
  --el-input-focus-border: var(--el-color-primary);
  --el-input-transparent-border: 0 0 0 2px transparent inset;
  --el-input-border-color: var(--el-border-color);
  --el-input-border-radius: 3px;
  --el-input-bg-color: var(--el-fill-color-blank);
  --el-input-icon-color: var(--el-text-color-placeholder);
  --el-input-placeholder-color: var(--el-text-color-placeholder);
  --el-input-hover-border-color: var(--el-border-color-hover);
  --el-input-clear-hover-color: var(--el-text-color-secondary);
  --el-input-focus-border-color: var(--el-color-primary);
}

.el-textarea {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: bottom;
  font-size: var(--el-font-size-base);
}
.el-textarea__inner {
  position: relative;
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  color: var(--el-input-text-color, var(--el-text-color-regular));
  background-color: var(--el-input-bg-color, var(--el-fill-color-blank));
  background-image: none;
  -webkit-appearance: none;
  box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color)) inset;
  border-radius: var(--el-input-border-radius, 3px);
  transition: var(--el-transition-box-shadow);
  border: none;
}
.el-textarea__inner::placeholder {
  color: var(--el-input-placeholder-color, var(--el-text-color-placeholder));
}
.el-textarea__inner:hover {
  box-shadow: 0 0 0 1px var(--el-input-hover-border-color) inset;
}
.el-textarea__inner:focus {
  outline: none;
  box-shadow: 0 0 0 1px var(--el-input-focus-border-color) inset;
}

.el-textarea .el-input__count {
  color: var(--el-color-info);
  background: var(--el-fill-color-blank);
  position: absolute;
  font-size: 12px;
  line-height: 14px;
  bottom: 5px;
  right: 10px;
}
.el-textarea.is-disabled .el-textarea__inner {
  background-color: var(--el-disabled-bg-color);
  border-color: var(--el-disabled-border-color);
  color: var(--el-disabled-text-color);
  cursor: not-allowed;
}
.el-textarea.is-disabled .el-textarea__inner::placeholder {
  color: var(--el-text-color-placeholder);
}

.el-textarea.is-exceed .el-textarea__inner {
  border-color: var(--el-color-danger);
}
.el-textarea.is-exceed .el-input__count {
  color: var(--el-color-danger);
}

.el-input {
  --el-input-text-color: var(--el-text-color-regular);
  --el-input-border: var(--el-border);
  --el-input-hover-border: var(--el-border-color-hover);
  --el-input-focus-border: var(--el-color-primary);
  --el-input-transparent-border: 0 0 0 2px transparent inset;
  --el-input-border-color: var(--el-border-color);
  --el-input-border-radius: 3px;
  --el-input-bg-color: var(--el-fill-color-blank);
  --el-input-icon-color: var(--el-text-color-placeholder);
  --el-input-placeholder-color: var(--el-text-color-placeholder);
  --el-input-hover-border-color: var(--el-border-color-hover);
  --el-input-clear-hover-color: var(--el-text-color-secondary);
  --el-input-focus-border-color: var(--el-color-primary);
  position: relative;
  font-size: var(--el-font-size-base);
  display: inline-flex;
  width: 100%;
  line-height: 30px;
  box-sizing: border-box;
}
.el-input::-webkit-scrollbar {
  z-index: 11;
  width: 6px;
}
.el-input::-webkit-scrollbar:horizontal {
  height: 6px;
}
.el-input::-webkit-scrollbar-thumb {
  border-radius: 5px;
  width: 6px;
  background: var(--el-text-color-disabled);
}
.el-input::-webkit-scrollbar-corner {
  background: var(--el-fill-color-blank);
}
.el-input::-webkit-scrollbar-track {
  background: var(--el-fill-color-blank);
}
.el-input::-webkit-scrollbar-track-piece {
  background: var(--el-fill-color-blank);
  width: 6px;
}
.el-input .el-input__clear, .el-input .el-input__password {
  color: var(--el-input-icon-color);
  font-size: 12px;
  cursor: pointer;
  transition: var(--el-transition-color) !important;
  margin-left: 8px;
}
.el-input .el-input__clear:hover, .el-input .el-input__password:hover {
  color: var(--el-input-clear-hover-color);
}
.el-input .el-input__count {
  height: 100%;
  display: inline-flex;
  align-items: center;
  color: var(--el-color-info);
  font-size: 12px;
}
.el-input .el-input__count .el-input__count-inner {
  background: var(--el-fill-color-blank);
  line-height: initial;
  display: inline-block;
  padding: 0 5px;
}
.el-input__wrapper {
  display: inline-flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 0 7px;
  background-color: var(--el-input-bg-color, var(--el-fill-color-blank));
  background-image: none;
  border-radius: var(--el-input-border-radius, 3px);
  transition: var(--el-transition-box-shadow);
  box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color)) inset;
}
.el-input__wrapper:hover {
  box-shadow: 0 0 0 1px var(--el-input-hover-border-color) inset;
}
.el-input__wrapper.is-focus {
  box-shadow: 0 0 0 1px var(--el-input-focus-border-color) inset;
}

.el-input__inner {
  width: 100%;
  flex-grow: 1;
  -webkit-appearance: none;
  color: var(--el-input-text-color, var(--el-text-color-regular));
  font-size: inherit;
  height: 30px;
  line-height: 30px;
  padding: 0;
  outline: none;
  border: none;
  background: none;
}
.el-input__inner:focus {
  outline: none;
}
.el-input__inner::placeholder {
  color: var(--el-input-placeholder-color, var(--el-text-color-placeholder));
}
.el-input__inner[type=password]::-ms-reveal {
  display: none;
}

.el-input__prefix {
  display: inline-flex;
  white-space: nowrap;
  flex-shrink: 0;
  flex-wrap: nowrap;
  height: 100%;
  text-align: center;
  color: var(--el-input-icon-color, var(--el-text-color-placeholder));
  transition: all var(--el-transition-duration);
  pointer-events: none;
}

.el-input__prefix-inner {
  pointer-events: all;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.el-input__prefix-inner > :last-child {
  margin-right: 8px;
}

.el-input__suffix {
  display: inline-flex;
  white-space: nowrap;
  flex-shrink: 0;
  flex-wrap: nowrap;
  height: 100%;
  text-align: center;
  color: var(--el-input-icon-color, var(--el-text-color-placeholder));
  transition: all var(--el-transition-duration);
  pointer-events: none;
}

.el-input__suffix-inner {
  pointer-events: all;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.el-input__suffix-inner > :first-child {
  margin-left: 8px;
}

.el-input .el-input__icon {
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all var(--el-transition-duration);
}
.el-input__validateIcon {
  pointer-events: none;
}

.el-input.is-active .el-input__wrapper {
  box-shadow: 0 0 0 1px var(--el-input-focus-border, ) inset;
}

.el-input.is-disabled {
  cursor: not-allowed;
}
.el-input.is-disabled .el-input__wrapper {
  background-color: var(--el-disabled-bg-color);
  box-shadow: 0 0 0 1px var(--el-disabled-border-color) inset;
}
.el-input.is-disabled .el-input__inner {
  color: var(--el-disabled-text-color);
}
.el-input.is-disabled .el-input__inner::placeholder {
  color: var(--el-text-color-placeholder);
}
.el-input.is-disabled .el-input__icon {
  cursor: not-allowed;
}

.el-input.is-exceed .el-input__wrapper {
  box-shadow: 0 0 0 1px var(--el-color-danger) inset;
}
.el-input.is-exceed .el-input__suffix .el-input__count {
  color: var(--el-color-danger);
}

.el-input--large {
  font-size: 12px;
  line-height: 38px;
}
.el-input--large .el-input__wrapper {
  padding: 0 11px;
}

.el-input--large .el-input__inner {
  height: 40px;
  line-height: 40px;
}

.el-input--large .el-input__icon {
  line-height: 40px;
}

.el-input--small {
  font-size: 10px;
  line-height: 22px;
}
.el-input--small .el-input__wrapper {
  padding: 0 4px;
}

.el-input--small .el-input__inner {
  height: 24px;
  line-height: 24px;
}

.el-input--small .el-input__icon {
  line-height: 24px;
}

.el-input-group {
  display: inline-flex;
  width: 100%;
  align-items: center;
}
.el-input-group__append, .el-input-group__prepend {
  background-color: var(--el-fill-color-light);
  color: var(--el-color-info);
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: var(--el-input-border-radius);
  padding: 0 20px;
  white-space: nowrap;
}
.el-input-group__append:focus, .el-input-group__prepend:focus {
  outline: none;
}
.el-input-group__append .el-select,
.el-input-group__append .el-button, .el-input-group__prepend .el-select,
.el-input-group__prepend .el-button {
  display: inline-block;
  margin: 0 -20px;
}
.el-input-group__append button.el-button,
.el-input-group__append button.el-button:hover,
.el-input-group__append div.el-select .el-input__wrapper,
.el-input-group__append div.el-select:hover .el-input__wrapper, .el-input-group__prepend button.el-button,
.el-input-group__prepend button.el-button:hover,
.el-input-group__prepend div.el-select .el-input__wrapper,
.el-input-group__prepend div.el-select:hover .el-input__wrapper {
  border-color: transparent;
  background-color: transparent;
  color: inherit;
}
.el-input-group__append .el-button,
.el-input-group__append .el-input, .el-input-group__prepend .el-button,
.el-input-group__prepend .el-input {
  font-size: inherit;
}

.el-input-group__prepend {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 1px 0 0 0 var(--el-input-border-color) inset, 0 1px 0 0 var(--el-input-border-color) inset, 0 -1px 0 0 var(--el-input-border-color) inset;
}

.el-input-group__append {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: 0 1px 0 0 var(--el-input-border-color) inset, 0 -1px 0 0 var(--el-input-border-color) inset, -1px 0 0 0 var(--el-input-border-color) inset;
}

.el-input-group--prepend > .el-input__wrapper {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.el-input-group--prepend .el-input-group__prepend .el-select .el-input .el-input__inner {
  box-shadow: none !important;
}
.el-input-group--prepend .el-input-group__prepend .el-select .el-input .el-input__wrapper {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 1px 0 0 0 var(--el-input-border-color) inset, 0 1px 0 0 var(--el-input-border-color) inset, 0 -1px 0 0 var(--el-input-border-color) inset;
}
.el-input-group--prepend .el-input-group__prepend .el-select .el-input.is-focus .el-input__inner {
  box-shadow: none !important;
}
.el-input-group--prepend .el-input-group__prepend .el-select .el-input.is-focus .el-input__wrapper {
  box-shadow: 1px 0 0 0 var(--el-input-focus-border-color) inset, 1px 0 0 0 var(--el-input-focus-border-color), 0 1px 0 0 var(--el-input-focus-border-color) inset, 0 -1px 0 0 var(--el-input-focus-border-color) inset !important;
  z-index: 2;
}
.el-input-group--prepend .el-input-group__prepend .el-select .el-input.is-focus .el-input__wrapper:focus {
  outline: none;
  z-index: 2;
  box-shadow: 1px 0 0 0 var(--el-input-focus-border-color) inset, 1px 0 0 0 var(--el-input-focus-border-color), 0 1px 0 0 var(--el-input-focus-border-color) inset, 0 -1px 0 0 var(--el-input-focus-border-color) inset !important;
}
.el-input-group--prepend .el-input-group__prepend .el-select:hover .el-input__inner {
  box-shadow: none !important;
}
.el-input-group--prepend .el-input-group__prepend .el-select:hover .el-input__wrapper {
  z-index: 1;
  box-shadow: 1px 0 0 0 var(--el-input-hover-border-color) inset, 1px 0 0 0 var(--el-input-hover-border-color), 0 1px 0 0 var(--el-input-hover-border-color) inset, 0 -1px 0 0 var(--el-input-hover-border-color) inset !important;
}

.el-input-group--append > .el-input__wrapper {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.el-input-group--append .el-input-group__append .el-select .el-input .el-input__inner {
  box-shadow: none !important;
}
.el-input-group--append .el-input-group__append .el-select .el-input .el-input__wrapper {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: 0 1px 0 0 var(--el-input-border-color) inset, 0 -1px 0 0 var(--el-input-border-color) inset, -1px 0 0 0 var(--el-input-border-color) inset;
}
.el-input-group--append .el-input-group__append .el-select .el-input.is-focus .el-input__inner {
  box-shadow: none !important;
}
.el-input-group--append .el-input-group__append .el-select .el-input.is-focus .el-input__wrapper {
  z-index: 2;
  box-shadow: -1px 0 0 0 var(--el-input-focus-border-color), -1px 0 0 0 var(--el-input-focus-border-color) inset, 0 1px 0 0 var(--el-input-focus-border-color) inset, 0 -1px 0 0 var(--el-input-focus-border-color) inset !important;
}
.el-input-group--append .el-input-group__append .el-select:hover .el-input__inner {
  box-shadow: none !important;
}
.el-input-group--append .el-input-group__append .el-select:hover .el-input__wrapper {
  z-index: 1;
  box-shadow: -1px 0 0 0 var(--el-input-hover-border-color), -1px 0 0 0 var(--el-input-hover-border-color) inset, 0 1px 0 0 var(--el-input-hover-border-color) inset, 0 -1px 0 0 var(--el-input-hover-border-color) inset !important;
}

.el-select-dropdown {
  z-index: calc(var(--el-index-top) + 1);
  border-radius: var(--el-border-radius-base);
  box-sizing: border-box;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  color: var(--el-color-primary);
  background-color: var(--el-bg-color-overlay);
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected.hover {
  background-color: var(--el-fill-color-light);
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 20px;
  border-top: none;
  border-right: none;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--el-color-primary);
  mask: url("data:image/svg+xml;utf8,%3Csvg class='icon' width='200' height='200' viewBox='0 0 1024 1024' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='currentColor' d='M406.656 706.944L195.84 496.256a32 32 0 10-45.248 45.248l256 256 512-512a32 32 0 00-45.248-45.248L406.592 706.944z'%3E%3C/path%3E%3C/svg%3E") no-repeat;
  mask-size: 100% 100%;
  -webkit-mask: url("data:image/svg+xml;utf8,%3Csvg class='icon' width='200' height='200' viewBox='0 0 1024 1024' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='currentColor' d='M406.656 706.944L195.84 496.256a32 32 0 10-45.248 45.248l256 256 512-512a32 32 0 00-45.248-45.248L406.592 706.944z'%3E%3C/path%3E%3C/svg%3E") no-repeat;
  -webkit-mask-size: 100% 100%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
}

.el-select-dropdown .el-select-dropdown__option-item.is-selected::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 20px;
  border-top: none;
  border-right: none;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--el-color-primary);
  mask: url("data:image/svg+xml;utf8,%3Csvg class='icon' width='200' height='200' viewBox='0 0 1024 1024' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='currentColor' d='M406.656 706.944L195.84 496.256a32 32 0 10-45.248 45.248l256 256 512-512a32 32 0 00-45.248-45.248L406.592 706.944z'%3E%3C/path%3E%3C/svg%3E") no-repeat;
  mask-size: 100% 100%;
  -webkit-mask: url("data:image/svg+xml;utf8,%3Csvg class='icon' width='200' height='200' viewBox='0 0 1024 1024' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='currentColor' d='M406.656 706.944L195.84 496.256a32 32 0 10-45.248 45.248l256 256 512-512a32 32 0 00-45.248-45.248L406.592 706.944z'%3E%3C/path%3E%3C/svg%3E") no-repeat;
  -webkit-mask-size: 100% 100%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
}
.el-select-dropdown .el-scrollbar.is-empty .el-select-dropdown__list {
  padding: 0;
}

.el-select-dropdown__empty {
  padding: 10px 0;
  margin: 0;
  text-align: center;
  color: var(--el-text-color-secondary);
  font-size: var(--el-select-font-size);
}

.el-select-dropdown__wrap {
  max-height: 274px;
}

.el-select-dropdown__list {
  list-style: none;
  padding: 6px 0;
  margin: 0;
  box-sizing: border-box;
}

.el-select {
  --el-select-border-color-hover: var(--el-border-color-hover);
  --el-select-disabled-border: var(--el-disabled-border-color);
  --el-select-font-size: var(--el-font-size-base);
  --el-select-close-hover-color: var(--el-text-color-secondary);
  --el-select-input-color: var(--el-text-color-placeholder);
  --el-select-multiple-input-color: var(--el-text-color-regular);
  --el-select-input-focus-border-color: var(--el-color-primary);
  --el-select-input-font-size: 14px;
}

.el-select {
  display: inline-block;
  position: relative;
  line-height: 30px;
}
.el-select__popper.el-popper[role=tooltip] {
  background: var(--el-bg-color-overlay);
  border: 1px solid var(--el-border-color-light);
  box-shadow: var(--el-box-shadow-light);
}
.el-select__popper.el-popper[role=tooltip] .el-popper__arrow::before {
  border: 1px solid var(--el-border-color-light);
}
.el-select__popper.el-popper[role=tooltip][data-popper-placement^=top] .el-popper__arrow::before {
  border-top-color: transparent;
  border-left-color: transparent;
}
.el-select__popper.el-popper[role=tooltip][data-popper-placement^=bottom] .el-popper__arrow::before {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.el-select__popper.el-popper[role=tooltip][data-popper-placement^=left] .el-popper__arrow::before {
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.el-select__popper.el-popper[role=tooltip][data-popper-placement^=right] .el-popper__arrow::before {
  border-right-color: transparent;
  border-top-color: transparent;
}

.el-select .el-select-tags-wrapper.has-prefix {
  margin-left: 6px;
}
.el-select--large {
  line-height: 40px;
}
.el-select--large .el-select-tags-wrapper.has-prefix {
  margin-left: 8px;
}

.el-select--small {
  line-height: 24px;
}
.el-select--small .el-select-tags-wrapper.has-prefix {
  margin-left: 4px;
}

.el-select .el-select__tags > span {
  display: inline-block;
}
.el-select:hover:not(.el-select--disabled) .el-input__wrapper {
  box-shadow: 0 0 0 1px var(--el-select-border-color-hover) inset;
}
.el-select .el-select__tags-text {
  text-overflow: ellipsis;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.el-select .el-input__wrapper {
  cursor: pointer;
}
.el-select .el-input__wrapper.is-focus {
  box-shadow: 0 0 0 1px var(--el-select-input-focus-border-color) inset !important;
}

.el-select .el-input__inner {
  cursor: pointer;
}
.el-select .el-input {
  display: flex;
}
.el-select .el-input .el-select__caret {
  color: var(--el-select-input-color);
  font-size: var(--el-select-input-font-size);
  transition: transform var(--el-transition-duration);
  transform: rotateZ(180deg);
  cursor: pointer;
}
.el-select .el-input .el-select__caret.is-reverse {
  transform: rotateZ(0deg);
}

.el-select .el-input .el-select__caret.is-show-close {
  font-size: var(--el-select-font-size);
  text-align: center;
  transform: rotateZ(180deg);
  border-radius: var(--el-border-radius-circle);
  color: var(--el-select-input-color);
  transition: var(--el-transition-color);
}
.el-select .el-input .el-select__caret.is-show-close:hover {
  color: var(--el-select-close-hover-color);
}

.el-select .el-input .el-select__caret.el-icon {
  position: relative;
  height: inherit;
  z-index: 2;
}
.el-select .el-input.is-disabled .el-input__wrapper {
  cursor: not-allowed;
}
.el-select .el-input.is-disabled .el-input__wrapper:hover {
  box-shadow: 0 0 0 1px var(--el-select-disabled-border) inset;
}
.el-select .el-input.is-disabled .el-input__inner {
  cursor: not-allowed;
}
.el-select .el-input.is-disabled .el-select__caret {
  cursor: not-allowed;
}
.el-select .el-input.is-focus .el-input__wrapper {
  box-shadow: 0 0 0 1px var(--el-select-input-focus-border-color) inset !important;
}
.el-select__input {
  border: none;
  outline: none;
  padding: 0;
  margin-left: 15px;
  color: var(--el-select-multiple-input-color);
  font-size: var(--el-select-font-size);
  appearance: none;
  height: 28px;
  background-color: transparent;
}

.el-select__close {
  cursor: pointer;
  position: absolute;
  top: 8px;
  z-index: var(--el-index-top);
  right: 25px;
  color: var(--el-select-input-color);
  line-height: 18px;
  font-size: var(--el-select-input-font-size);
}
.el-select__close:hover {
  color: var(--el-select-close-hover-color);
}

.el-select__tags {
  position: absolute;
  line-height: normal;
  top: 50%;
  transform: translateY(-50%);
  white-space: normal;
  z-index: var(--el-index-normal);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.el-select__collapse-tags {
  white-space: normal;
  z-index: var(--el-index-normal);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.el-select__collapse-tag {
  line-height: inherit;
  height: inherit;
  display: flex;
}

.el-select .el-select__tags .el-tag {
  box-sizing: border-box;
  border-color: transparent;
  margin: 2px 6px 2px 0;
}
.el-select .el-select__tags .el-tag:last-child {
  margin-right: 0;
}
.el-select .el-select__tags .el-tag .el-icon-close {
  background-color: var(--el-text-color-placeholder);
  right: -7px;
  top: 0;
  color: #ffffff;
}
.el-select .el-select__tags .el-tag .el-icon-close:hover {
  background-color: var(--el-text-color-secondary);
}
.el-select .el-select__tags .el-tag .el-icon-close::before {
  display: block;
  transform: translate(0, 0.5px);
}
.el-select .el-select__tags .el-tag--info {
  background-color: var(--el-fill-color);
}

.el-select-dropdown__item {
  font-size: var(--el-font-size-base);
  padding: 0 32px 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--el-text-color-regular);
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
  cursor: pointer;
}
.el-select-dropdown__item.is-disabled {
  color: var(--el-text-color-placeholder);
  cursor: not-allowed;
}

.el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
  background-color: var(--el-fill-color-light);
}
.el-select-dropdown__item.selected {
  color: var(--el-color-primary);
  font-weight: bold;
}

.el-popper {
  --el-popper-border-radius: var(--el-popover-border-radius, 4px);
}

.el-popper {
  position: absolute;
  border-radius: var(--el-popper-border-radius);
  padding: 5px 11px;
  z-index: 2000;
  font-size: 12px;
  line-height: 20px;
  min-width: 10px;
  word-wrap: break-word;
  visibility: visible;
}
.el-popper.is-dark {
  color: var(--el-bg-color);
  background: var(--el-text-color-primary);
  border: 1px solid var(--el-text-color-primary);
}
.el-popper.is-dark .el-popper__arrow::before {
  border: 1px solid var(--el-text-color-primary);
  background: var(--el-text-color-primary);
  right: 0;
}

.el-popper.is-light {
  background: var(--el-bg-color-overlay);
  border: 1px solid var(--el-border-color-light);
}
.el-popper.is-light .el-popper__arrow::before {
  border: 1px solid var(--el-border-color-light);
  background: var(--el-bg-color-overlay);
  right: 0;
}

.el-popper.is-pure {
  padding: 0;
}

.el-popper__arrow {
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: -1;
}
.el-popper__arrow::before {
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: -1;
  content: " ";
  transform: rotate(45deg);
  background: var(--el-text-color-primary);
  box-sizing: border-box;
}

.el-popper[data-popper-placement^=top] > .el-popper__arrow {
  bottom: -5px;
}
.el-popper[data-popper-placement^=top] > .el-popper__arrow::before {
  border-bottom-right-radius: 2px;
}
.el-popper[data-popper-placement^=bottom] > .el-popper__arrow {
  top: -5px;
}
.el-popper[data-popper-placement^=bottom] > .el-popper__arrow::before {
  border-top-left-radius: 2px;
}
.el-popper[data-popper-placement^=left] > .el-popper__arrow {
  right: -5px;
}
.el-popper[data-popper-placement^=left] > .el-popper__arrow::before {
  border-top-right-radius: 2px;
}
.el-popper[data-popper-placement^=right] > .el-popper__arrow {
  left: -5px;
}
.el-popper[data-popper-placement^=right] > .el-popper__arrow::before {
  border-bottom-left-radius: 2px;
}
.el-popper[data-popper-placement^=top] .el-popper__arrow::before {
  border-top-color: transparent !important;
  border-left-color: transparent !important;
}
.el-popper[data-popper-placement^=bottom] .el-popper__arrow::before {
  border-bottom-color: transparent !important;
  border-right-color: transparent !important;
}
.el-popper[data-popper-placement^=left] .el-popper__arrow::before {
  border-left-color: transparent !important;
  border-bottom-color: transparent !important;
}
.el-popper[data-popper-placement^=right] .el-popper__arrow::before {
  border-right-color: transparent !important;
  border-top-color: transparent !important;
}

.el-button {
  --el-button-font-weight: 600;
  --el-button-border-color: var(--el-border-color);
  --el-button-bg-color: var(--el-fill-color-blank);
  --el-button-text-color: var(--el-text-color-regular);
  --el-button-disabled-text-color: var(--el-disabled-text-color);
  --el-button-disabled-bg-color: var(--el-fill-color-blank);
  --el-button-disabled-border-color: var(--el-border-color-light);
  --el-button-divide-border-color: rgba(255, 255, 255, 0.5);
  --el-button-hover-text-color: var(--el-color-primary);
  --el-button-hover-bg-color: var(--el-color-primary-light-9);
  --el-button-hover-border-color: var(--el-color-primary-light-7);
  --el-button-active-text-color: var(--el-button-hover-text-color);
  --el-button-active-border-color: var(--el-color-primary);
  --el-button-active-bg-color: var(--el-button-hover-bg-color);
}

.el-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  height: 30px;
  white-space: nowrap;
  cursor: pointer;
  background-color: var(--el-button-bg-color);
  border: var(--el-border);
  border-color: var(--el-button-border-color);
  color: var(--el-button-text-color);
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  transition: 0.1s;
  font-weight: var(--el-button-font-weight);
  user-select: none;
  vertical-align: middle;
  padding: 6px 15px;
  font-size: 12px;
  border-radius: 3px;
}
.el-button > span {
  display: inline-flex;
  align-items: center;
}
.el-button + .el-button {
  margin-left: 12px;
}
.el-button.is-round {
  padding: 6px 15px;
}
.el-button:hover, .el-button:focus {
  color: var(--el-button-hover-text-color);
  border-color: var(--el-button-hover-border-color);
  background-color: var(--el-button-hover-bg-color);
  outline: none;
}
.el-button:active {
  color: var(--el-button-active-text-color);
  border-color: var(--el-button-active-border-color);
  background-color: var(--el-button-active-bg-color);
  outline: none;
}
.el-button::-moz-focus-inner {
  border: 0;
}
.el-button [class*=el-icon] + span {
  margin-left: 6px;
}
.el-button [class*=el-icon] svg {
  vertical-align: bottom;
}
.el-button.is-plain {
  --el-button-hover-text-color: var(--el-color-primary);
  --el-button-hover-bg-color: var(--el-fill-color-blank);
  --el-button-hover-border-color: var(--el-color-primary);
}

.el-button.is-active {
  color: var(--el-button-active-text-color);
  border-color: var(--el-button-active-border-color);
  background-color: var(--el-button-active-bg-color);
  outline: none;
}

.el-button.is-disabled, .el-button.is-disabled:hover, .el-button.is-disabled:focus {
  color: var(--el-button-disabled-text-color);
  cursor: not-allowed;
  background-image: none;
  background-color: var(--el-button-disabled-bg-color);
  border-color: var(--el-button-disabled-border-color);
}

.el-button.is-loading {
  position: relative;
  pointer-events: none;
}
.el-button.is-loading:before {
  z-index: 1;
  pointer-events: none;
  content: "";
  position: absolute;
  left: -1px;
  top: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: inherit;
  background-color: var(--el-mask-color-extra-light);
}

.el-button.is-round {
  border-radius: var(--el-border-radius-round);
}

.el-button.is-circle {
  border-radius: 50%;
  padding: 6px;
}

.el-button__text--expand {
  letter-spacing: 0.3em;
  margin-right: -0.3em;
}

.el-button--primary {
  --el-button-text-color: var(--el-color-white);
  --el-button-bg-color: var(--el-color-primary);
  --el-button-border-color: var(--el-color-primary);
  --el-button-hover-text-color: var(--el-color-white);
  --el-button-hover-bg-color: var(--el-color-primary-light-3);
  --el-button-hover-border-color: var(--el-color-primary-light-3);
  --el-button-active-bg-color: var(--el-color-primary-dark-2);
  --el-button-active-border-color: var(--el-color-primary-dark-2);
  --el-button-disabled-text-color: var(--el-color-white);
  --el-button-disabled-bg-color: var(--el-color-primary-light-5);
  --el-button-disabled-border-color: var(--el-color-primary-light-5);
}
.el-button--primary.is-plain {
  --el-button-text-color: var(--el-color-primary);
  --el-button-bg-color: var(--el-color-primary-light-9);
  --el-button-border-color: var(--el-color-primary-light-5);
  --el-button-hover-text-color: var(--el-color-white);
  --el-button-hover-bg-color: var(--el-color-primary);
  --el-button-hover-border-color: var(--el-color-primary);
  --el-button-active-text-color: var(--el-color-white);
}
.el-button--primary.is-plain.is-disabled, .el-button--primary.is-plain.is-disabled:hover, .el-button--primary.is-plain.is-disabled:focus, .el-button--primary.is-plain.is-disabled:active {
  color: var(--el-color-primary-light-5);
  background-color: var(--el-color-primary-light-9);
  border-color: var(--el-color-primary-light-8);
}

.el-button--success {
  --el-button-text-color: var(--el-color-white);
  --el-button-bg-color: var(--el-color-success);
  --el-button-border-color: var(--el-color-success);
  --el-button-hover-text-color: var(--el-color-white);
  --el-button-hover-bg-color: var(--el-color-success-light-3);
  --el-button-hover-border-color: var(--el-color-success-light-3);
  --el-button-active-bg-color: var(--el-color-success-dark-2);
  --el-button-active-border-color: var(--el-color-success-dark-2);
  --el-button-disabled-text-color: var(--el-color-white);
  --el-button-disabled-bg-color: var(--el-color-success-light-5);
  --el-button-disabled-border-color: var(--el-color-success-light-5);
}
.el-button--success.is-plain {
  --el-button-text-color: var(--el-color-success);
  --el-button-bg-color: var(--el-color-success-light-9);
  --el-button-border-color: var(--el-color-success-light-5);
  --el-button-hover-text-color: var(--el-color-white);
  --el-button-hover-bg-color: var(--el-color-success);
  --el-button-hover-border-color: var(--el-color-success);
  --el-button-active-text-color: var(--el-color-white);
}
.el-button--success.is-plain.is-disabled, .el-button--success.is-plain.is-disabled:hover, .el-button--success.is-plain.is-disabled:focus, .el-button--success.is-plain.is-disabled:active {
  color: var(--el-color-success-light-5);
  background-color: var(--el-color-success-light-9);
  border-color: var(--el-color-success-light-8);
}

.el-button--warning {
  --el-button-text-color: var(--el-color-white);
  --el-button-bg-color: var(--el-color-warning);
  --el-button-border-color: var(--el-color-warning);
  --el-button-hover-text-color: var(--el-color-white);
  --el-button-hover-bg-color: var(--el-color-warning-light-3);
  --el-button-hover-border-color: var(--el-color-warning-light-3);
  --el-button-active-bg-color: var(--el-color-warning-dark-2);
  --el-button-active-border-color: var(--el-color-warning-dark-2);
  --el-button-disabled-text-color: var(--el-color-white);
  --el-button-disabled-bg-color: var(--el-color-warning-light-5);
  --el-button-disabled-border-color: var(--el-color-warning-light-5);
}
.el-button--warning.is-plain {
  --el-button-text-color: var(--el-color-warning);
  --el-button-bg-color: var(--el-color-warning-light-9);
  --el-button-border-color: var(--el-color-warning-light-5);
  --el-button-hover-text-color: var(--el-color-white);
  --el-button-hover-bg-color: var(--el-color-warning);
  --el-button-hover-border-color: var(--el-color-warning);
  --el-button-active-text-color: var(--el-color-white);
}
.el-button--warning.is-plain.is-disabled, .el-button--warning.is-plain.is-disabled:hover, .el-button--warning.is-plain.is-disabled:focus, .el-button--warning.is-plain.is-disabled:active {
  color: var(--el-color-warning-light-5);
  background-color: var(--el-color-warning-light-9);
  border-color: var(--el-color-warning-light-8);
}

.el-button--danger {
  --el-button-text-color: var(--el-color-white);
  --el-button-bg-color: var(--el-color-danger);
  --el-button-border-color: var(--el-color-danger);
  --el-button-hover-text-color: var(--el-color-white);
  --el-button-hover-bg-color: var(--el-color-danger-light-3);
  --el-button-hover-border-color: var(--el-color-danger-light-3);
  --el-button-active-bg-color: var(--el-color-danger-dark-2);
  --el-button-active-border-color: var(--el-color-danger-dark-2);
  --el-button-disabled-text-color: var(--el-color-white);
  --el-button-disabled-bg-color: var(--el-color-danger-light-5);
  --el-button-disabled-border-color: var(--el-color-danger-light-5);
}
.el-button--danger.is-plain {
  --el-button-text-color: var(--el-color-danger);
  --el-button-bg-color: var(--el-color-danger-light-9);
  --el-button-border-color: var(--el-color-danger-light-5);
  --el-button-hover-text-color: var(--el-color-white);
  --el-button-hover-bg-color: var(--el-color-danger);
  --el-button-hover-border-color: var(--el-color-danger);
  --el-button-active-text-color: var(--el-color-white);
}
.el-button--danger.is-plain.is-disabled, .el-button--danger.is-plain.is-disabled:hover, .el-button--danger.is-plain.is-disabled:focus, .el-button--danger.is-plain.is-disabled:active {
  color: var(--el-color-danger-light-5);
  background-color: var(--el-color-danger-light-9);
  border-color: var(--el-color-danger-light-8);
}

.el-button--info {
  --el-button-text-color: var(--el-color-white);
  --el-button-bg-color: var(--el-color-info);
  --el-button-border-color: var(--el-color-info);
  --el-button-hover-text-color: var(--el-color-white);
  --el-button-hover-bg-color: var(--el-color-info-light-3);
  --el-button-hover-border-color: var(--el-color-info-light-3);
  --el-button-active-bg-color: var(--el-color-info-dark-2);
  --el-button-active-border-color: var(--el-color-info-dark-2);
  --el-button-disabled-text-color: var(--el-color-white);
  --el-button-disabled-bg-color: var(--el-color-info-light-5);
  --el-button-disabled-border-color: var(--el-color-info-light-5);
}
.el-button--info.is-plain {
  --el-button-text-color: var(--el-color-info);
  --el-button-bg-color: var(--el-color-info-light-9);
  --el-button-border-color: var(--el-color-info-light-5);
  --el-button-hover-text-color: var(--el-color-white);
  --el-button-hover-bg-color: var(--el-color-info);
  --el-button-hover-border-color: var(--el-color-info);
  --el-button-active-text-color: var(--el-color-white);
}
.el-button--info.is-plain.is-disabled, .el-button--info.is-plain.is-disabled:hover, .el-button--info.is-plain.is-disabled:focus, .el-button--info.is-plain.is-disabled:active {
  color: var(--el-color-info-light-5);
  background-color: var(--el-color-info-light-9);
  border-color: var(--el-color-info-light-8);
}

.el-button--large {
  --el-button-size: 40px;
  height: var(--el-button-size);
  padding: 12px 19px;
  font-size: 12px;
  border-radius: 3px;
}
.el-button--large [class*=el-icon] + span {
  margin-left: 8px;
}
.el-button--large.is-round {
  padding: 12px 19px;
}
.el-button--large.is-circle {
  width: var(--el-button-size);
  padding: 12px;
}

.el-button--small {
  --el-button-size: 24px;
  height: var(--el-button-size);
  padding: 5px 11px;
  font-size: 10px;
  border-radius: 2px;
}
.el-button--small [class*=el-icon] + span {
  margin-left: 4px;
}
.el-button--small.is-round {
  padding: 5px 11px;
}
.el-button--small.is-circle {
  width: var(--el-button-size);
  padding: 5px;
}

.el-button--text {
  border-color: transparent;
  color: var(--el-color-primary);
  background: transparent;
  padding-left: 0;
  padding-right: 0;
}
.el-button--text:hover, .el-button--text:focus {
  color: var(--el-color-primary-light-3);
  border-color: transparent;
  background-color: transparent;
}
.el-button--text:active {
  color: var(--el-color-primary-dark-2);
  border-color: transparent;
  background-color: transparent;
}
.el-button--text.is-disabled, .el-button--text.is-disabled:hover, .el-button--text.is-disabled:focus {
  border-color: transparent;
}

.el-switch {
  --el-switch-on-color: var(--el-color-primary);
  --el-switch-off-color: var(--el-border-color);
  --el-switch-core-border-radius: 10px;
  --el-switch-width: 40px;
  --el-switch-height: 20px;
  --el-switch-button-size: 16px;
}

.el-switch {
  display: inline-flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  line-height: var(--el-switch-height);
  height: 32px;
  vertical-align: middle;
}
.el-switch.is-disabled .el-switch__core, .el-switch.is-disabled .el-switch__label {
  cursor: not-allowed;
}

.el-switch__label {
  transition: var(--el-transition-duration-fast);
  height: var(--el-switch-height);
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  vertical-align: middle;
  color: var(--el-text-color-primary);
}
.el-switch__label.is-active {
  color: var(--el-color-primary);
}

.el-switch__label--left {
  margin-right: 10px;
}

.el-switch__label--right {
  margin-left: 10px;
}

.el-switch__label * {
  line-height: 1;
  font-size: 14px;
  display: inline-block;
}
.el-switch__label .el-icon {
  height: inherit;
}
.el-switch__label .el-icon svg {
  vertical-align: middle;
}

.el-switch__input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  margin: 0;
}

.el-switch__core {
  margin: 0;
  display: inline-block;
  position: relative;
  width: var(--el-switch-width);
  height: var(--el-switch-height);
  border: 1px solid var(--el-switch-off-color);
  outline: none;
  border-radius: var(--el-switch-core-border-radius);
  box-sizing: border-box;
  background: var(--el-switch-off-color);
  cursor: pointer;
  transition: border-color var(--el-transition-duration), background-color var(--el-transition-duration);
  vertical-align: middle;
}
.el-switch__core .el-switch__inner {
  position: absolute;
  top: 1px;
  left: 1px;
  transition: all var(--el-transition-duration);
  width: var(--el-switch-button-size);
  height: var(--el-switch-button-size);
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  white-space: nowrap;
}
.el-switch__core .el-switch__inner .is-icon,
.el-switch__core .el-switch__inner .is-text {
  color: var(--el-color-white);
  transition: opacity var(--el-transition-duration);
  position: absolute;
  user-select: none;
}
.el-switch__core .el-switch__action {
  position: absolute;
  top: 1px;
  left: 1px;
  border-radius: var(--el-border-radius-circle);
  transition: all var(--el-transition-duration);
  width: var(--el-switch-button-size);
  height: var(--el-switch-button-size);
  background-color: var(--el-color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--el-switch-off-color);
}
.el-switch__core .el-switch__action .is-icon,
.el-switch__core .el-switch__action .is-text {
  transition: opacity var(--el-transition-duration);
  position: absolute;
  user-select: none;
}
.el-switch__core .is-text {
  font-size: 12px;
}
.el-switch__core .is-show {
  opacity: 1;
}
.el-switch__core .is-hide {
  opacity: 0;
}

.el-switch.is-checked .el-switch__core {
  border-color: var(--el-switch-on-color);
  background-color: var(--el-switch-on-color);
}
.el-switch.is-checked .el-switch__core .el-switch__action {
  left: 100%;
  margin-left: calc(-1px - var(--el-switch-button-size));
  color: var(--el-switch-on-color);
}
.el-switch.is-checked .el-switch__core .el-switch__inner {
  left: 50%;
  white-space: nowrap;
  margin-left: calc(-1px - var(--el-switch-button-size));
}

.el-switch.is-disabled {
  opacity: 0.6;
}

.el-switch--wide .el-switch__label.el-switch__label--left span {
  left: 10px;
}
.el-switch--wide .el-switch__label.el-switch__label--right span {
  right: 10px;
}

.el-switch .label-fade-enter-from, .el-switch .label-fade-leave-active {
  opacity: 0;
}
.el-switch--large {
  font-size: 14px;
  height: 40px;
}
.el-switch--large .el-switch__label {
  font-size: 14px;
}
.el-switch--large .el-switch__label * {
  font-size: 14px;
}

.el-switch--small {
  font-size: 12px;
  height: 24px;
}
.el-switch--small .el-switch__label {
  font-size: 12px;
}
.el-switch--small .el-switch__label * {
  font-size: 12px;
}

.el-table {
  --el-table-border-color: #a3edbf;
  --el-table-border: 1px solid var(--el-table-border-color);
  --el-table-text-color: var(--el-text-color-regular);
  --el-table-header-text-color: var(--el-text-color-secondary);
  --el-table-row-hover-bg-color: #e3faec;
  --el-table-current-row-bg-color: #70B77E;
  --el-table-header-bg-color: #e3faec;
  --el-table-fixed-box-shadow: var(--el-box-shadow-light);
  --el-table-bg-color: var(--el-fill-color-blank);
  --el-table-tr-bg-color: var(--el-fill-color-blank);
  --el-table-expanded-cell-bg-color: var(--el-fill-color-blank);
  --el-table-fixed-left-column: inset 10px 0 10px -10px rgba(0, 0, 0, 0.15);
  --el-table-fixed-right-column: inset -10px 0 10px -10px rgba(0, 0, 0, 0.15);
}

.el-table {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  height: fit-content;
  width: 100%;
  max-width: 100%;
  background-color: var(--el-table-bg-color);
  font-size: 14px;
  color: var(--el-table-text-color);
}
.el-table__inner-wrapper {
  position: relative;
}
.el-table__inner-wrapper::before {
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 1px;
  z-index: 3;
}

.el-table.has-footer .el-table__inner-wrapper::before {
  bottom: 1px;
}
.el-table__empty-block {
  position: sticky;
  left: 0;
  min-height: 60px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-table__empty-text {
  line-height: 60px;
  width: 50%;
  color: var(--el-text-color-secondary);
}

.el-table__expand-column .cell {
  padding: 0;
  text-align: center;
  user-select: none;
}

.el-table__expand-icon {
  position: relative;
  cursor: pointer;
  color: var(--el-text-color-regular);
  font-size: 12px;
  transition: transform var(--el-transition-duration-fast) ease-in-out;
  height: 20px;
}
.el-table__expand-icon--expanded {
  transform: rotate(90deg);
}

.el-table__expand-icon > .el-icon {
  font-size: 12px;
}

.el-table__expanded-cell {
  background-color: var(--el-table-expanded-cell-bg-color);
}
.el-table__expanded-cell[class*=cell] {
  padding: 20px 50px;
}
.el-table__expanded-cell:hover {
  background-color: transparent !important;
}

.el-table__placeholder {
  display: inline-block;
  width: 20px;
}

.el-table__append-wrapper {
  overflow: hidden;
}

.el-table--fit {
  border-right: 0;
  border-bottom: 0;
}
.el-table--fit .el-table__cell.gutter {
  border-right-width: 1px;
}

.el-table thead {
  color: var(--el-table-header-text-color);
  font-weight: 500;
}
.el-table thead.is-group th.el-table__cell {
  background: var(--el-fill-color-light);
}
.el-table .el-table__cell {
  padding: 4px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
  z-index: 1;
}
.el-table .el-table__cell.is-center {
  text-align: center;
}

.el-table .el-table__cell.is-right {
  text-align: right;
}

.el-table .el-table__cell.gutter {
  width: 15px;
  border-right-width: 0;
  border-bottom-width: 0;
  padding: 0;
}
.el-table .el-table__cell.is-hidden > * {
  visibility: hidden;
}
.el-table .cell {
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  line-height: 23px;
  padding: 0 12px;
}
.el-table .cell.el-tooltip {
  white-space: nowrap;
  min-width: 50px;
}
.el-table--large {
  font-size: var(--el-font-size-base);
}
.el-table--large .el-table__cell {
  padding: 8px 0;
}
.el-table--large .cell {
  padding: 0 10px;
}

.el-table--small {
  font-size: 12px;
}
.el-table--small .el-table__cell {
  padding: 2px 0;
}
.el-table--small .cell {
  padding: 0 3px;
}

.el-table tr {
  background-color: var(--el-table-tr-bg-color);
}
.el-table tr input[type=checkbox] {
  margin: 0;
}
.el-table th.el-table__cell.is-leaf,
.el-table td.el-table__cell {
  border-bottom: var(--el-table-border);
}
.el-table th.el-table__cell.is-sortable {
  cursor: pointer;
}
.el-table th.el-table__cell {
  user-select: none;
  background-color: var(--el-table-header-bg-color);
}
.el-table th.el-table__cell > .cell {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  width: 100%;
}
.el-table th.el-table__cell > .cell.highlight {
  color: var(--el-color-primary);
}
.el-table th.el-table__cell.required > div::before {
  display: inline-block;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ff4d51;
  margin-right: 5px;
  vertical-align: middle;
}
.el-table td.el-table__cell div {
  box-sizing: border-box;
}
.el-table td.el-table__cell.gutter {
  width: 0;
}
.el-table--group .el-table__inner-wrapper tr:first-child td:first-child,
.el-table--group .el-table__inner-wrapper tr:first-child th:first-child, .el-table--group .el-table__footer-wrapper tr:first-child td:first-child,
.el-table--group .el-table__footer-wrapper tr:first-child th:first-child, .el-table--border .el-table__inner-wrapper tr:first-child td:first-child,
.el-table--border .el-table__inner-wrapper tr:first-child th:first-child, .el-table--border .el-table__footer-wrapper tr:first-child td:first-child,
.el-table--border .el-table__footer-wrapper tr:first-child th:first-child {
  border-left: var(--el-table-border);
}

.el-table--group .el-table__footer-wrapper, .el-table--border .el-table__footer-wrapper {
  border-top: var(--el-table-border);
}

.el-table--border::after, .el-table--border::before, .el-table--border .el-table__inner-wrapper::after, .el-table__inner-wrapper::before {
  content: "";
  position: absolute;
  background-color: var(--el-table-border-color);
  z-index: 3;
}

.el-table--border .el-table__inner-wrapper::after {
  left: 0;
  top: 0;
  width: 100%;
  height: 1px;
  z-index: 3;
}

.el-table--border::before {
  top: -1px;
  left: 0;
  width: 1px;
  height: 100%;
  z-index: 3;
}
.el-table--border::after {
  top: -1px;
  right: 0;
  width: 1px;
  height: 100%;
  z-index: 3;
}
.el-table--border .el-table__inner-wrapper {
  border-right: none;
  border-bottom: none;
}

.el-table--border .el-table__footer-wrapper {
  position: relative;
}

.el-table--border .el-table__footer-wrapper {
  margin-top: -2px;
}

.el-table--border .el-table__cell {
  border-right: var(--el-table-border);
}
.el-table--border .el-table__cell:first-child .cell {
  padding-left: 10px;
}
.el-table--border th.el-table__cell.gutter:last-of-type {
  border-bottom: var(--el-table-border);
  border-bottom-width: 1px;
}
.el-table--border th.el-table__cell {
  border-bottom: var(--el-table-border);
}

.el-table--hidden {
  visibility: hidden;
}

.el-table__header-wrapper, .el-table__body-wrapper, .el-table__footer-wrapper {
  width: 100%;
}
.el-table__header-wrapper tr td.el-table-fixed-column--left, .el-table__header-wrapper tr td.el-table-fixed-column--right,
.el-table__header-wrapper tr th.el-table-fixed-column--left,
.el-table__header-wrapper tr th.el-table-fixed-column--right, .el-table__body-wrapper tr td.el-table-fixed-column--left, .el-table__body-wrapper tr td.el-table-fixed-column--right,
.el-table__body-wrapper tr th.el-table-fixed-column--left,
.el-table__body-wrapper tr th.el-table-fixed-column--right, .el-table__footer-wrapper tr td.el-table-fixed-column--left, .el-table__footer-wrapper tr td.el-table-fixed-column--right,
.el-table__footer-wrapper tr th.el-table-fixed-column--left,
.el-table__footer-wrapper tr th.el-table-fixed-column--right {
  position: sticky !important;
  z-index: 2;
  background: var(--el-bg-color);
}
.el-table__header-wrapper tr td.el-table-fixed-column--left.is-last-column::before, .el-table__header-wrapper tr td.el-table-fixed-column--left.is-first-column::before, .el-table__header-wrapper tr td.el-table-fixed-column--right.is-last-column::before, .el-table__header-wrapper tr td.el-table-fixed-column--right.is-first-column::before,
.el-table__header-wrapper tr th.el-table-fixed-column--left.is-last-column::before,
.el-table__header-wrapper tr th.el-table-fixed-column--left.is-first-column::before,
.el-table__header-wrapper tr th.el-table-fixed-column--right.is-last-column::before,
.el-table__header-wrapper tr th.el-table-fixed-column--right.is-first-column::before, .el-table__body-wrapper tr td.el-table-fixed-column--left.is-last-column::before, .el-table__body-wrapper tr td.el-table-fixed-column--left.is-first-column::before, .el-table__body-wrapper tr td.el-table-fixed-column--right.is-last-column::before, .el-table__body-wrapper tr td.el-table-fixed-column--right.is-first-column::before,
.el-table__body-wrapper tr th.el-table-fixed-column--left.is-last-column::before,
.el-table__body-wrapper tr th.el-table-fixed-column--left.is-first-column::before,
.el-table__body-wrapper tr th.el-table-fixed-column--right.is-last-column::before,
.el-table__body-wrapper tr th.el-table-fixed-column--right.is-first-column::before, .el-table__footer-wrapper tr td.el-table-fixed-column--left.is-last-column::before, .el-table__footer-wrapper tr td.el-table-fixed-column--left.is-first-column::before, .el-table__footer-wrapper tr td.el-table-fixed-column--right.is-last-column::before, .el-table__footer-wrapper tr td.el-table-fixed-column--right.is-first-column::before,
.el-table__footer-wrapper tr th.el-table-fixed-column--left.is-last-column::before,
.el-table__footer-wrapper tr th.el-table-fixed-column--left.is-first-column::before,
.el-table__footer-wrapper tr th.el-table-fixed-column--right.is-last-column::before,
.el-table__footer-wrapper tr th.el-table-fixed-column--right.is-first-column::before {
  content: "";
  position: absolute;
  top: 0px;
  width: 10px;
  bottom: -1px;
  overflow-x: hidden;
  overflow-y: hidden;
  box-shadow: none;
  touch-action: none;
  pointer-events: none;
}
.el-table__header-wrapper tr td.el-table-fixed-column--left.is-first-column::before, .el-table__header-wrapper tr td.el-table-fixed-column--right.is-first-column::before,
.el-table__header-wrapper tr th.el-table-fixed-column--left.is-first-column::before,
.el-table__header-wrapper tr th.el-table-fixed-column--right.is-first-column::before, .el-table__body-wrapper tr td.el-table-fixed-column--left.is-first-column::before, .el-table__body-wrapper tr td.el-table-fixed-column--right.is-first-column::before,
.el-table__body-wrapper tr th.el-table-fixed-column--left.is-first-column::before,
.el-table__body-wrapper tr th.el-table-fixed-column--right.is-first-column::before, .el-table__footer-wrapper tr td.el-table-fixed-column--left.is-first-column::before, .el-table__footer-wrapper tr td.el-table-fixed-column--right.is-first-column::before,
.el-table__footer-wrapper tr th.el-table-fixed-column--left.is-first-column::before,
.el-table__footer-wrapper tr th.el-table-fixed-column--right.is-first-column::before {
  left: -10px;
}
.el-table__header-wrapper tr td.el-table-fixed-column--left.is-last-column::before, .el-table__header-wrapper tr td.el-table-fixed-column--right.is-last-column::before,
.el-table__header-wrapper tr th.el-table-fixed-column--left.is-last-column::before,
.el-table__header-wrapper tr th.el-table-fixed-column--right.is-last-column::before, .el-table__body-wrapper tr td.el-table-fixed-column--left.is-last-column::before, .el-table__body-wrapper tr td.el-table-fixed-column--right.is-last-column::before,
.el-table__body-wrapper tr th.el-table-fixed-column--left.is-last-column::before,
.el-table__body-wrapper tr th.el-table-fixed-column--right.is-last-column::before, .el-table__footer-wrapper tr td.el-table-fixed-column--left.is-last-column::before, .el-table__footer-wrapper tr td.el-table-fixed-column--right.is-last-column::before,
.el-table__footer-wrapper tr th.el-table-fixed-column--left.is-last-column::before,
.el-table__footer-wrapper tr th.el-table-fixed-column--right.is-last-column::before {
  right: -10px;
  box-shadow: none;
}
.el-table__header-wrapper tr td.el-table__fixed-right-patch,
.el-table__header-wrapper tr th.el-table__fixed-right-patch, .el-table__body-wrapper tr td.el-table__fixed-right-patch,
.el-table__body-wrapper tr th.el-table__fixed-right-patch, .el-table__footer-wrapper tr td.el-table__fixed-right-patch,
.el-table__footer-wrapper tr th.el-table__fixed-right-patch {
  position: sticky !important;
  z-index: 2;
  background: #fff;
  right: 0;
}

.el-table__header-wrapper tr th.el-table-fixed-column--left, .el-table__header-wrapper tr th.el-table-fixed-column--right {
  background-color: var(--el-table-header-bg-color);
}

.el-table__header, .el-table__body, .el-table__footer {
  table-layout: fixed;
  border-collapse: separate;
}

.el-table__header-wrapper, .el-table__footer-wrapper {
  overflow: hidden;
}
.el-table__header-wrapper tbody td.el-table__cell, .el-table__footer-wrapper tbody td.el-table__cell {
  background-color: var(--el-table-row-hover-bg-color);
  color: var(--el-table-text-color);
}

.el-table__header-wrapper .el-table-column--selection .el-checkbox, .el-table__body-wrapper .el-table-column--selection .el-checkbox {
  height: unset;
}

.el-table.is-scrolling-left .el-table-fixed-column--right.is-first-column::before {
  box-shadow: var(--el-table-fixed-right-column);
}
.el-table.is-scrolling-left.el-table--border .el-table-fixed-column--left.is-last-column.el-table__cell {
  border-right: var(--el-table-border);
}
.el-table.is-scrolling-left th.el-table-fixed-column--left {
  background-color: var(--el-table-header-bg-color);
}

.el-table.is-scrolling-right .el-table-fixed-column--left.is-last-column::before {
  box-shadow: var(--el-table-fixed-left-column);
}
.el-table.is-scrolling-right .el-table-fixed-column--left.is-last-column.el-table__cell {
  border-right: none;
}
.el-table.is-scrolling-right th.el-table-fixed-column--right {
  background-color: var(--el-table-header-bg-color);
}

.el-table.is-scrolling-middle .el-table-fixed-column--left.is-last-column.el-table__cell {
  border-right: none;
}
.el-table.is-scrolling-middle .el-table-fixed-column--right.is-first-column::before {
  box-shadow: var(--el-table-fixed-right-column);
}
.el-table.is-scrolling-middle .el-table-fixed-column--left.is-last-column::before {
  box-shadow: var(--el-table-fixed-left-column);
}

.el-table.is-scrolling-none .el-table-fixed-column--left.is-first-column::before, .el-table.is-scrolling-none .el-table-fixed-column--left.is-last-column::before,
.el-table.is-scrolling-none .el-table-fixed-column--right.is-first-column::before,
.el-table.is-scrolling-none .el-table-fixed-column--right.is-last-column::before {
  box-shadow: none;
}
.el-table.is-scrolling-none th.el-table-fixed-column--left,
.el-table.is-scrolling-none th.el-table-fixed-column--right {
  background-color: var(--el-table-header-bg-color);
}

.el-table__body-wrapper {
  overflow: hidden;
  position: relative;
}
.el-table__body-wrapper .el-scrollbar__bar {
  z-index: 2;
}

.el-table .caret-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  height: 14px;
  width: 24px;
  vertical-align: middle;
  cursor: pointer;
  overflow: initial;
  position: relative;
}
.el-table .sort-caret {
  width: 0;
  height: 0;
  border: solid 5px transparent;
  position: absolute;
  left: 7px;
}
.el-table .sort-caret.ascending {
  border-bottom-color: var(--el-text-color-placeholder);
  top: -5px;
}
.el-table .sort-caret.descending {
  border-top-color: var(--el-text-color-placeholder);
  bottom: -3px;
}
.el-table .ascending .sort-caret.ascending {
  border-bottom-color: var(--el-color-primary);
}
.el-table .descending .sort-caret.descending {
  border-top-color: var(--el-color-primary);
}
.el-table .hidden-columns {
  visibility: hidden;
  position: absolute;
  z-index: -1;
}
.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background: var(--el-fill-color-lighter);
}
.el-table--striped .el-table__body tr.el-table__row--striped.current-row td.el-table__cell {
  background-color: var(--el-table-current-row-bg-color);
}

.el-table__body tr.hover-row > td.el-table__cell, .el-table__body tr.hover-row.current-row > td.el-table__cell, .el-table__body tr.hover-row.el-table__row--striped > td.el-table__cell, .el-table__body tr.hover-row.el-table__row--striped.current-row > td.el-table__cell {
  background-color: var(--el-table-row-hover-bg-color);
}
.el-table__body tr.current-row > td.el-table__cell {
  background-color: var(--el-table-current-row-bg-color);
}

.el-table__column-resize-proxy {
  position: absolute;
  left: 200px;
  top: 0;
  bottom: 0;
  width: 0;
  border-left: var(--el-table-border);
  z-index: 10;
}

.el-table__column-filter-trigger {
  display: inline-block;
  cursor: pointer;
}
.el-table__column-filter-trigger i {
  color: var(--el-color-info);
  font-size: 14px;
  vertical-align: middle;
}

.el-table__border-left-patch {
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  z-index: 3;
  position: absolute;
  background-color: var(--el-table-border-color);
}

.el-table__border-bottom-patch {
  left: 0;
  height: 1px;
  z-index: 3;
  position: absolute;
  background-color: var(--el-table-border-color);
}

.el-table__border-right-patch {
  top: 0;
  height: 100%;
  width: 1px;
  z-index: 3;
  position: absolute;
  background-color: var(--el-table-border-color);
}

.el-table--enable-row-transition .el-table__body td.el-table__cell {
  transition: background-color 0.25s ease;
}

.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background-color: var(--el-table-row-hover-bg-color);
}

.el-table [class*=el-table__row--level] .el-table__expand-icon {
  display: inline-block;
  width: 12px;
  line-height: 12px;
  height: 12px;
  text-align: center;
  margin-right: 8px;
}

.el-table-column--selection .cell {
  padding-left: 14px;
  padding-right: 14px;
}

.el-table-filter {
  border: solid 1px var(--el-border-color-lighter);
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: var(--el-box-shadow-light);
  box-sizing: border-box;
  /** used for dropdown mode */
}
.el-table-filter__list {
  padding: 5px 0;
  margin: 0;
  list-style: none;
  min-width: 100px;
}

.el-table-filter__list-item {
  line-height: 36px;
  padding: 0 10px;
  cursor: pointer;
  font-size: var(--el-font-size-base);
}
.el-table-filter__list-item:hover {
  background-color: var(--el-color-primary-light-9);
  color: var(--el-color-primary-light-3);
}
.el-table-filter__list-item.is-active {
  background-color: var(--el-color-primary);
  color: #ffffff;
}

.el-table-filter__content {
  min-width: 100px;
}

.el-table-filter__bottom {
  border-top: 1px solid var(--el-border-color-lighter);
  padding: 8px;
}
.el-table-filter__bottom button {
  background: transparent;
  border: none;
  color: var(--el-text-color-regular);
  cursor: pointer;
  font-size: var(--el-font-size-small);
  padding: 0 3px;
}
.el-table-filter__bottom button:hover {
  color: var(--el-color-primary);
}
.el-table-filter__bottom button:focus {
  outline: none;
}
.el-table-filter__bottom button.is-disabled {
  color: var(--el-disabled-text-color);
  cursor: not-allowed;
}

.el-table-filter__wrap {
  max-height: 280px;
}

.el-table-filter__checkbox-group {
  padding: 10px;
}
.el-table-filter__checkbox-group label.el-checkbox {
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-bottom: 12px;
  margin-left: 5px;
  height: unset;
}
.el-table-filter__checkbox-group .el-checkbox:last-child {
  margin-bottom: 0;
}

:root {
  --el-popup-modal-bg-color: var(--el-color-black);
  --el-popup-modal-opacity: 0.5;
}

.v-modal-enter {
  animation: v-modal-in var(--el-transition-duration-fast) ease;
}

.v-modal-leave {
  animation: v-modal-out var(--el-transition-duration-fast) ease forwards;
}

@keyframes v-modal-in {
  0% {
    opacity: 0;
  }
}
@keyframes v-modal-out {
  100% {
    opacity: 0;
  }
}
.v-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: var(--el-popup-modal-opacity);
  background: var(--el-popup-modal-bg-color);
}

.el-popup-parent--hidden {
  overflow: hidden;
}

.el-dialog {
  --el-dialog-width: 50%;
  --el-dialog-margin-top: 15vh;
  --el-dialog-bg-color: var(--el-bg-color);
  --el-dialog-box-shadow: var(--el-box-shadow);
  --el-dialog-title-font-size: var(--el-font-size-large);
  --el-dialog-content-font-size: 14px;
  --el-dialog-font-line-height: var(--el-font-line-height-primary);
  --el-dialog-padding-primary: 20px;
  --el-dialog-border-radius: var(--el-border-radius-small);
  position: relative;
  margin: var(--el-dialog-margin-top, 15vh) auto 50px;
  background: var(--el-dialog-bg-color);
  border-radius: var(--el-dialog-border-radius);
  box-shadow: var(--el-dialog-box-shadow);
  box-sizing: border-box;
  width: var(--el-dialog-width, 50%);
}
.el-dialog.is-fullscreen {
  --el-dialog-width: 100%;
  --el-dialog-margin-top: 0;
  margin-bottom: 0;
  height: 100%;
  overflow: auto;
}

.el-dialog__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
}

.el-dialog.is-draggable .el-dialog__header {
  cursor: move;
  user-select: none;
}

.el-dialog__header {
  padding: var(--el-dialog-padding-primary);
  padding-bottom: 10px;
  margin-right: 16px;
  word-break: break-all;
}

.el-dialog__headerbtn {
  position: absolute;
  top: 6px;
  right: 0;
  padding: 0;
  width: 54px;
  height: 54px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: var(--el-message-close-size, 16px);
}
.el-dialog__headerbtn .el-dialog__close {
  color: var(--el-color-info);
  font-size: inherit;
}
.el-dialog__headerbtn:focus .el-dialog__close, .el-dialog__headerbtn:hover .el-dialog__close {
  color: var(--el-color-primary);
}

.el-dialog__title {
  line-height: var(--el-dialog-font-line-height);
  font-size: var(--el-dialog-title-font-size);
  color: var(--el-text-color-primary);
}

.el-dialog__body {
  padding: calc(var(--el-dialog-padding-primary) + 10px) var(--el-dialog-padding-primary);
  color: var(--el-text-color-regular);
  font-size: var(--el-dialog-content-font-size);
  word-break: break-all;
}

.el-dialog__footer {
  padding: var(--el-dialog-padding-primary);
  padding-top: 10px;
  text-align: right;
  box-sizing: border-box;
}

.el-dialog--center {
  text-align: center;
}
.el-dialog--center .el-dialog__body {
  text-align: initial;
  padding: 25px calc(var(--el-dialog-padding-primary) + 5px) 30px;
}

.el-dialog--center .el-dialog__footer {
  text-align: inherit;
}

.el-overlay-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}

.dialog-fade-enter-active {
  animation: modal-fade-in var(--el-transition-duration);
}
.dialog-fade-enter-active .el-overlay-dialog {
  animation: dialog-fade-in var(--el-transition-duration);
}

.dialog-fade-leave-active {
  animation: modal-fade-out var(--el-transition-duration);
}
.dialog-fade-leave-active .el-overlay-dialog {
  animation: dialog-fade-out var(--el-transition-duration);
}

@keyframes dialog-fade-in {
  0% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
}
@keyframes modal-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes modal-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.el-message {
  --el-message-min-width: 380px;
  --el-message-bg-color: var(--el-color-info-light-9);
  --el-message-border-color: var(--el-border-color-lighter);
  --el-message-padding: 15px 15px 15px 20px;
  --el-message-close-size: 16px;
  --el-message-close-icon-color: var(--el-text-color-placeholder);
  --el-message-close-hover-color: var(--el-text-color-secondary);
}

.el-message {
  min-width: var(--el-message-min-width);
  box-sizing: border-box;
  border-radius: var(--el-border-radius-base);
  border-width: var(--el-border-width-base);
  border-style: var(--el-border-style-base);
  border-color: var(--el-message-border-color);
  position: fixed;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  transition: opacity 0.3s, transform 0.4s, top 0.4s;
  background-color: var(--el-message-bg-color);
  transition: opacity var(--el-transition-duration), transform 0.4s, top 0.4s;
  padding: var(--el-message-padding);
  display: flex;
  align-items: center;
}
.el-message.is-center {
  justify-content: center;
}

.el-message.is-closable .el-message__content {
  padding-right: 16px;
}

.el-message p {
  margin: 0;
}
.el-message--success {
  --el-message-bg-color: var(--el-color-success-light-9);
  --el-message-border-color: var(--el-color-success-light-8);
  --el-message-text-color: var(--el-color-success);
}
.el-message--success .el-message__content {
  color: var(--el-message-text-color);
}

.el-message .el-message-icon--success {
  color: var(--el-message-text-color);
}
.el-message--info {
  --el-message-bg-color: var(--el-color-info-light-9);
  --el-message-border-color: var(--el-color-info-light-8);
  --el-message-text-color: var(--el-color-info);
}
.el-message--info .el-message__content {
  color: var(--el-message-text-color);
}

.el-message .el-message-icon--info {
  color: var(--el-message-text-color);
}
.el-message--warning {
  --el-message-bg-color: var(--el-color-warning-light-9);
  --el-message-border-color: var(--el-color-warning-light-8);
  --el-message-text-color: var(--el-color-warning);
}
.el-message--warning .el-message__content {
  color: var(--el-message-text-color);
}

.el-message .el-message-icon--warning {
  color: var(--el-message-text-color);
}
.el-message--error {
  --el-message-bg-color: var(--el-color-error-light-9);
  --el-message-border-color: var(--el-color-error-light-8);
  --el-message-text-color: var(--el-color-error);
}
.el-message--error .el-message__content {
  color: var(--el-message-text-color);
}

.el-message .el-message-icon--error {
  color: var(--el-message-text-color);
}
.el-message__icon {
  margin-right: 10px;
}

.el-message .el-message__badge {
  position: absolute;
  top: -8px;
  right: -8px;
}
.el-message__content {
  padding: 0;
  font-size: 14px;
  line-height: 1;
}
.el-message__content:focus {
  outline-width: 0;
}

.el-message .el-message__closeBtn {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;
  color: var(--el-message-close-icon-color);
  font-size: var(--el-message-close-size);
}
.el-message .el-message__closeBtn:focus {
  outline-width: 0;
}
.el-message .el-message__closeBtn:hover {
  color: var(--el-message-close-hover-color);
}

.el-message-fade-enter-from,
.el-message-fade-leave-to {
  opacity: 0;
  transform: translate(-50%, -100%);
}

:root {
  --el-loading-spinner-size: 42px;
  --el-loading-fullscreen-spinner-size: 50px;
}

.el-loading-parent--relative {
  position: relative !important;
}

.el-loading-parent--hidden {
  overflow: hidden !important;
}

.el-loading-mask {
  position: absolute;
  z-index: 2000;
  background-color: var(--el-mask-color);
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity var(--el-transition-duration);
}
.el-loading-mask.is-fullscreen {
  position: fixed;
}
.el-loading-mask.is-fullscreen .el-loading-spinner {
  margin-top: calc((0px - var(--el-loading-fullscreen-spinner-size)) / 2);
}
.el-loading-mask.is-fullscreen .el-loading-spinner .circular {
  height: var(--el-loading-fullscreen-spinner-size);
  width: var(--el-loading-fullscreen-spinner-size);
}

.el-loading-spinner {
  top: 50%;
  margin-top: calc((0px - var(--el-loading-spinner-size)) / 2);
  width: 100%;
  text-align: center;
  position: absolute;
}
.el-loading-spinner .el-loading-text {
  color: var(--el-color-primary);
  margin: 3px 0;
  font-size: 14px;
}
.el-loading-spinner .circular {
  display: inline;
  height: var(--el-loading-spinner-size);
  width: var(--el-loading-spinner-size);
  animation: loading-rotate 2s linear infinite;
}
.el-loading-spinner .path {
  animation: loading-dash 1.5s ease-in-out infinite;
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
  stroke-width: 2;
  stroke: var(--el-color-primary);
  stroke-linecap: round;
}
.el-loading-spinner i {
  color: var(--el-color-primary);
}

.el-loading-fade-enter-from,
.el-loading-fade-leave-to {
  opacity: 0;
}

@keyframes loading-rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loading-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40px;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120px;
  }
}
.el-aside {
  --el-aside-width: 300px;
  overflow: auto;
  box-sizing: border-box;
  flex-shrink: 0;
  width: var(--el-aside-width);
}

.el-dropdown {
  --el-dropdown-menu-box-shadow: var(--el-box-shadow-light);
  --el-dropdown-menuItem-hover-fill: var(--el-color-primary-light-9);
  --el-dropdown-menuItem-hover-color: var(--el-color-primary-light-3);
  --el-dropdown-menu-index: 10;
  display: inline-flex;
  position: relative;
  color: var(--el-text-color-regular);
  font-size: var(--el-font-size-base);
  line-height: 1;
  vertical-align: top;
}
.el-dropdown.is-disabled {
  color: var(--el-text-color-placeholder);
  cursor: not-allowed;
}
.el-dropdown__popper {
  --el-dropdown-menu-box-shadow: var(--el-box-shadow-light);
  --el-dropdown-menuItem-hover-fill: var(--el-color-primary-light-9);
  --el-dropdown-menuItem-hover-color: var(--el-color-primary-light-3);
  --el-dropdown-menu-index: 10;
}
.el-dropdown__popper.el-popper[role=tooltip] {
  background: var(--el-bg-color-overlay);
  border: 1px solid var(--el-border-color-light);
  box-shadow: var(--el-dropdown-menu-box-shadow);
}
.el-dropdown__popper.el-popper[role=tooltip] .el-popper__arrow::before {
  border: 1px solid var(--el-border-color-light);
}
.el-dropdown__popper.el-popper[role=tooltip][data-popper-placement^=top] .el-popper__arrow::before {
  border-top-color: transparent;
  border-left-color: transparent;
}
.el-dropdown__popper.el-popper[role=tooltip][data-popper-placement^=bottom] .el-popper__arrow::before {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.el-dropdown__popper.el-popper[role=tooltip][data-popper-placement^=left] .el-popper__arrow::before {
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.el-dropdown__popper.el-popper[role=tooltip][data-popper-placement^=right] .el-popper__arrow::before {
  border-right-color: transparent;
  border-top-color: transparent;
}
.el-dropdown__popper .el-dropdown-menu {
  border: none;
}
.el-dropdown__popper .el-dropdown__popper-selfdefine {
  outline: none;
}
.el-dropdown__popper .el-scrollbar__bar {
  z-index: calc(var(--el-dropdown-menu-index) + 1);
}
.el-dropdown__popper .el-dropdown__list {
  list-style: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.el-dropdown .el-dropdown__caret-button {
  padding-left: 0;
  padding-right: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  border-left: none;
}
.el-dropdown .el-dropdown__caret-button > span {
  display: inline-flex;
}
.el-dropdown .el-dropdown__caret-button::before {
  content: "";
  position: absolute;
  display: block;
  width: 1px;
  top: 5px;
  bottom: 5px;
  left: 0;
  background: var(--el-overlay-color-lighter);
}
.el-dropdown .el-dropdown__caret-button.el-button::before {
  background: var(--el-border-color);
  opacity: 0.5;
}
.el-dropdown .el-dropdown__caret-button:hover::before {
  top: 0;
  bottom: 0;
}
.el-dropdown .el-dropdown__caret-button .el-dropdown__icon {
  font-size: inherit;
  padding-left: 0;
}
.el-dropdown .el-dropdown-selfdefine {
  outline: none;
}
.el-dropdown--large .el-dropdown__caret-button {
  width: 40px;
}

.el-dropdown--small .el-dropdown__caret-button {
  width: 24px;
}

.el-dropdown-menu {
  position: relative;
  top: 0;
  left: 0;
  z-index: var(--el-dropdown-menu-index);
  padding: 5px 0;
  margin: 0;
  background-color: var(--el-bg-color-overlay);
  border: none;
  border-radius: var(--el-border-radius-base);
  box-shadow: none;
  list-style: none;
}
.el-dropdown-menu__item {
  display: flex;
  align-items: center;
  white-space: nowrap;
  list-style: none;
  line-height: 22px;
  padding: 5px 16px;
  margin: 0;
  font-size: var(--el-font-size-base);
  color: var(--el-text-color-regular);
  cursor: pointer;
  outline: none;
}
.el-dropdown-menu__item:not(.is-disabled):focus {
  background-color: var(--el-dropdown-menuItem-hover-fill);
  color: var(--el-dropdown-menuItem-hover-color);
}
.el-dropdown-menu__item i {
  margin-right: 5px;
}
.el-dropdown-menu__item--divided {
  margin: 6px 0;
  border-top: 1px solid var(--el-border-color-lighter);
}

.el-dropdown-menu__item.is-disabled {
  cursor: not-allowed;
  color: var(--el-text-color-disabled);
}

.el-dropdown-menu--large {
  padding: 7px 0;
}
.el-dropdown-menu--large .el-dropdown-menu__item {
  padding: 7px 20px;
  line-height: 22px;
  font-size: 12px;
}
.el-dropdown-menu--large .el-dropdown-menu__item--divided {
  margin: 8px 0;
}

.el-dropdown-menu--small {
  padding: 3px 0;
}
.el-dropdown-menu--small .el-dropdown-menu__item {
  padding: 2px 12px;
  line-height: 20px;
  font-size: 10px;
}
.el-dropdown-menu--small .el-dropdown-menu__item--divided {
  margin: 4px 0;
}

[class*=el-col-] {
  float: left;
  box-sizing: border-box;
}
[class*=el-col-].is-guttered {
  display: block;
  min-height: 1px;
}

.el-col-0 {
  display: none;
}
.el-col-0.is-guttered {
  display: none;
}

.el-col-0 {
  max-width: 0%;
  flex: 0 0 0%;
}

.el-col-offset-0 {
  margin-left: 0%;
}

.el-col-pull-0 {
  position: relative;
  right: 0%;
}

.el-col-push-0 {
  position: relative;
  left: 0%;
}

.el-col-1 {
  max-width: 4.1666666667%;
  flex: 0 0 4.1666666667%;
}

.el-col-offset-1 {
  margin-left: 4.1666666667%;
}

.el-col-pull-1 {
  position: relative;
  right: 4.1666666667%;
}

.el-col-push-1 {
  position: relative;
  left: 4.1666666667%;
}

.el-col-2 {
  max-width: 8.3333333333%;
  flex: 0 0 8.3333333333%;
}

.el-col-offset-2 {
  margin-left: 8.3333333333%;
}

.el-col-pull-2 {
  position: relative;
  right: 8.3333333333%;
}

.el-col-push-2 {
  position: relative;
  left: 8.3333333333%;
}

.el-col-3 {
  max-width: 12.5%;
  flex: 0 0 12.5%;
}

.el-col-offset-3 {
  margin-left: 12.5%;
}

.el-col-pull-3 {
  position: relative;
  right: 12.5%;
}

.el-col-push-3 {
  position: relative;
  left: 12.5%;
}

.el-col-4 {
  max-width: 16.6666666667%;
  flex: 0 0 16.6666666667%;
}

.el-col-offset-4 {
  margin-left: 16.6666666667%;
}

.el-col-pull-4 {
  position: relative;
  right: 16.6666666667%;
}

.el-col-push-4 {
  position: relative;
  left: 16.6666666667%;
}

.el-col-5 {
  max-width: 20.8333333333%;
  flex: 0 0 20.8333333333%;
}

.el-col-offset-5 {
  margin-left: 20.8333333333%;
}

.el-col-pull-5 {
  position: relative;
  right: 20.8333333333%;
}

.el-col-push-5 {
  position: relative;
  left: 20.8333333333%;
}

.el-col-6 {
  max-width: 25%;
  flex: 0 0 25%;
}

.el-col-offset-6 {
  margin-left: 25%;
}

.el-col-pull-6 {
  position: relative;
  right: 25%;
}

.el-col-push-6 {
  position: relative;
  left: 25%;
}

.el-col-7 {
  max-width: 29.1666666667%;
  flex: 0 0 29.1666666667%;
}

.el-col-offset-7 {
  margin-left: 29.1666666667%;
}

.el-col-pull-7 {
  position: relative;
  right: 29.1666666667%;
}

.el-col-push-7 {
  position: relative;
  left: 29.1666666667%;
}

.el-col-8 {
  max-width: 33.3333333333%;
  flex: 0 0 33.3333333333%;
}

.el-col-offset-8 {
  margin-left: 33.3333333333%;
}

.el-col-pull-8 {
  position: relative;
  right: 33.3333333333%;
}

.el-col-push-8 {
  position: relative;
  left: 33.3333333333%;
}

.el-col-9 {
  max-width: 37.5%;
  flex: 0 0 37.5%;
}

.el-col-offset-9 {
  margin-left: 37.5%;
}

.el-col-pull-9 {
  position: relative;
  right: 37.5%;
}

.el-col-push-9 {
  position: relative;
  left: 37.5%;
}

.el-col-10 {
  max-width: 41.6666666667%;
  flex: 0 0 41.6666666667%;
}

.el-col-offset-10 {
  margin-left: 41.6666666667%;
}

.el-col-pull-10 {
  position: relative;
  right: 41.6666666667%;
}

.el-col-push-10 {
  position: relative;
  left: 41.6666666667%;
}

.el-col-11 {
  max-width: 45.8333333333%;
  flex: 0 0 45.8333333333%;
}

.el-col-offset-11 {
  margin-left: 45.8333333333%;
}

.el-col-pull-11 {
  position: relative;
  right: 45.8333333333%;
}

.el-col-push-11 {
  position: relative;
  left: 45.8333333333%;
}

.el-col-12 {
  max-width: 50%;
  flex: 0 0 50%;
}

.el-col-offset-12 {
  margin-left: 50%;
}

.el-col-pull-12 {
  position: relative;
  right: 50%;
}

.el-col-push-12 {
  position: relative;
  left: 50%;
}

.el-col-13 {
  max-width: 54.1666666667%;
  flex: 0 0 54.1666666667%;
}

.el-col-offset-13 {
  margin-left: 54.1666666667%;
}

.el-col-pull-13 {
  position: relative;
  right: 54.1666666667%;
}

.el-col-push-13 {
  position: relative;
  left: 54.1666666667%;
}

.el-col-14 {
  max-width: 58.3333333333%;
  flex: 0 0 58.3333333333%;
}

.el-col-offset-14 {
  margin-left: 58.3333333333%;
}

.el-col-pull-14 {
  position: relative;
  right: 58.3333333333%;
}

.el-col-push-14 {
  position: relative;
  left: 58.3333333333%;
}

.el-col-15 {
  max-width: 62.5%;
  flex: 0 0 62.5%;
}

.el-col-offset-15 {
  margin-left: 62.5%;
}

.el-col-pull-15 {
  position: relative;
  right: 62.5%;
}

.el-col-push-15 {
  position: relative;
  left: 62.5%;
}

.el-col-16 {
  max-width: 66.6666666667%;
  flex: 0 0 66.6666666667%;
}

.el-col-offset-16 {
  margin-left: 66.6666666667%;
}

.el-col-pull-16 {
  position: relative;
  right: 66.6666666667%;
}

.el-col-push-16 {
  position: relative;
  left: 66.6666666667%;
}

.el-col-17 {
  max-width: 70.8333333333%;
  flex: 0 0 70.8333333333%;
}

.el-col-offset-17 {
  margin-left: 70.8333333333%;
}

.el-col-pull-17 {
  position: relative;
  right: 70.8333333333%;
}

.el-col-push-17 {
  position: relative;
  left: 70.8333333333%;
}

.el-col-18 {
  max-width: 75%;
  flex: 0 0 75%;
}

.el-col-offset-18 {
  margin-left: 75%;
}

.el-col-pull-18 {
  position: relative;
  right: 75%;
}

.el-col-push-18 {
  position: relative;
  left: 75%;
}

.el-col-19 {
  max-width: 79.1666666667%;
  flex: 0 0 79.1666666667%;
}

.el-col-offset-19 {
  margin-left: 79.1666666667%;
}

.el-col-pull-19 {
  position: relative;
  right: 79.1666666667%;
}

.el-col-push-19 {
  position: relative;
  left: 79.1666666667%;
}

.el-col-20 {
  max-width: 83.3333333333%;
  flex: 0 0 83.3333333333%;
}

.el-col-offset-20 {
  margin-left: 83.3333333333%;
}

.el-col-pull-20 {
  position: relative;
  right: 83.3333333333%;
}

.el-col-push-20 {
  position: relative;
  left: 83.3333333333%;
}

.el-col-21 {
  max-width: 87.5%;
  flex: 0 0 87.5%;
}

.el-col-offset-21 {
  margin-left: 87.5%;
}

.el-col-pull-21 {
  position: relative;
  right: 87.5%;
}

.el-col-push-21 {
  position: relative;
  left: 87.5%;
}

.el-col-22 {
  max-width: 91.6666666667%;
  flex: 0 0 91.6666666667%;
}

.el-col-offset-22 {
  margin-left: 91.6666666667%;
}

.el-col-pull-22 {
  position: relative;
  right: 91.6666666667%;
}

.el-col-push-22 {
  position: relative;
  left: 91.6666666667%;
}

.el-col-23 {
  max-width: 95.8333333333%;
  flex: 0 0 95.8333333333%;
}

.el-col-offset-23 {
  margin-left: 95.8333333333%;
}

.el-col-pull-23 {
  position: relative;
  right: 95.8333333333%;
}

.el-col-push-23 {
  position: relative;
  left: 95.8333333333%;
}

.el-col-24 {
  max-width: 100%;
  flex: 0 0 100%;
}

.el-col-offset-24 {
  margin-left: 100%;
}

.el-col-pull-24 {
  position: relative;
  right: 100%;
}

.el-col-push-24 {
  position: relative;
  left: 100%;
}

@media only screen and (max-width: 768px) {
  .el-col-xs-0 {
    display: none;
  }
  .el-col-xs-0.is-guttered {
    display: none;
  }

  .el-col-xs-0 {
    max-width: 0%;
    flex: 0 0 0%;
  }

  .el-col-xs-offset-0 {
    margin-left: 0%;
  }

  .el-col-xs-pull-0 {
    position: relative;
    right: 0%;
  }

  .el-col-xs-push-0 {
    position: relative;
    left: 0%;
  }

  .el-col-xs-1 {
    display: block;
    max-width: 4.1666666667%;
    flex: 0 0 4.1666666667%;
  }

  .el-col-xs-offset-1 {
    margin-left: 4.1666666667%;
  }

  .el-col-xs-pull-1 {
    position: relative;
    right: 4.1666666667%;
  }

  .el-col-xs-push-1 {
    position: relative;
    left: 4.1666666667%;
  }

  .el-col-xs-2 {
    display: block;
    max-width: 8.3333333333%;
    flex: 0 0 8.3333333333%;
  }

  .el-col-xs-offset-2 {
    margin-left: 8.3333333333%;
  }

  .el-col-xs-pull-2 {
    position: relative;
    right: 8.3333333333%;
  }

  .el-col-xs-push-2 {
    position: relative;
    left: 8.3333333333%;
  }

  .el-col-xs-3 {
    display: block;
    max-width: 12.5%;
    flex: 0 0 12.5%;
  }

  .el-col-xs-offset-3 {
    margin-left: 12.5%;
  }

  .el-col-xs-pull-3 {
    position: relative;
    right: 12.5%;
  }

  .el-col-xs-push-3 {
    position: relative;
    left: 12.5%;
  }

  .el-col-xs-4 {
    display: block;
    max-width: 16.6666666667%;
    flex: 0 0 16.6666666667%;
  }

  .el-col-xs-offset-4 {
    margin-left: 16.6666666667%;
  }

  .el-col-xs-pull-4 {
    position: relative;
    right: 16.6666666667%;
  }

  .el-col-xs-push-4 {
    position: relative;
    left: 16.6666666667%;
  }

  .el-col-xs-5 {
    display: block;
    max-width: 20.8333333333%;
    flex: 0 0 20.8333333333%;
  }

  .el-col-xs-offset-5 {
    margin-left: 20.8333333333%;
  }

  .el-col-xs-pull-5 {
    position: relative;
    right: 20.8333333333%;
  }

  .el-col-xs-push-5 {
    position: relative;
    left: 20.8333333333%;
  }

  .el-col-xs-6 {
    display: block;
    max-width: 25%;
    flex: 0 0 25%;
  }

  .el-col-xs-offset-6 {
    margin-left: 25%;
  }

  .el-col-xs-pull-6 {
    position: relative;
    right: 25%;
  }

  .el-col-xs-push-6 {
    position: relative;
    left: 25%;
  }

  .el-col-xs-7 {
    display: block;
    max-width: 29.1666666667%;
    flex: 0 0 29.1666666667%;
  }

  .el-col-xs-offset-7 {
    margin-left: 29.1666666667%;
  }

  .el-col-xs-pull-7 {
    position: relative;
    right: 29.1666666667%;
  }

  .el-col-xs-push-7 {
    position: relative;
    left: 29.1666666667%;
  }

  .el-col-xs-8 {
    display: block;
    max-width: 33.3333333333%;
    flex: 0 0 33.3333333333%;
  }

  .el-col-xs-offset-8 {
    margin-left: 33.3333333333%;
  }

  .el-col-xs-pull-8 {
    position: relative;
    right: 33.3333333333%;
  }

  .el-col-xs-push-8 {
    position: relative;
    left: 33.3333333333%;
  }

  .el-col-xs-9 {
    display: block;
    max-width: 37.5%;
    flex: 0 0 37.5%;
  }

  .el-col-xs-offset-9 {
    margin-left: 37.5%;
  }

  .el-col-xs-pull-9 {
    position: relative;
    right: 37.5%;
  }

  .el-col-xs-push-9 {
    position: relative;
    left: 37.5%;
  }

  .el-col-xs-10 {
    display: block;
    max-width: 41.6666666667%;
    flex: 0 0 41.6666666667%;
  }

  .el-col-xs-offset-10 {
    margin-left: 41.6666666667%;
  }

  .el-col-xs-pull-10 {
    position: relative;
    right: 41.6666666667%;
  }

  .el-col-xs-push-10 {
    position: relative;
    left: 41.6666666667%;
  }

  .el-col-xs-11 {
    display: block;
    max-width: 45.8333333333%;
    flex: 0 0 45.8333333333%;
  }

  .el-col-xs-offset-11 {
    margin-left: 45.8333333333%;
  }

  .el-col-xs-pull-11 {
    position: relative;
    right: 45.8333333333%;
  }

  .el-col-xs-push-11 {
    position: relative;
    left: 45.8333333333%;
  }

  .el-col-xs-12 {
    display: block;
    max-width: 50%;
    flex: 0 0 50%;
  }

  .el-col-xs-offset-12 {
    margin-left: 50%;
  }

  .el-col-xs-pull-12 {
    position: relative;
    right: 50%;
  }

  .el-col-xs-push-12 {
    position: relative;
    left: 50%;
  }

  .el-col-xs-13 {
    display: block;
    max-width: 54.1666666667%;
    flex: 0 0 54.1666666667%;
  }

  .el-col-xs-offset-13 {
    margin-left: 54.1666666667%;
  }

  .el-col-xs-pull-13 {
    position: relative;
    right: 54.1666666667%;
  }

  .el-col-xs-push-13 {
    position: relative;
    left: 54.1666666667%;
  }

  .el-col-xs-14 {
    display: block;
    max-width: 58.3333333333%;
    flex: 0 0 58.3333333333%;
  }

  .el-col-xs-offset-14 {
    margin-left: 58.3333333333%;
  }

  .el-col-xs-pull-14 {
    position: relative;
    right: 58.3333333333%;
  }

  .el-col-xs-push-14 {
    position: relative;
    left: 58.3333333333%;
  }

  .el-col-xs-15 {
    display: block;
    max-width: 62.5%;
    flex: 0 0 62.5%;
  }

  .el-col-xs-offset-15 {
    margin-left: 62.5%;
  }

  .el-col-xs-pull-15 {
    position: relative;
    right: 62.5%;
  }

  .el-col-xs-push-15 {
    position: relative;
    left: 62.5%;
  }

  .el-col-xs-16 {
    display: block;
    max-width: 66.6666666667%;
    flex: 0 0 66.6666666667%;
  }

  .el-col-xs-offset-16 {
    margin-left: 66.6666666667%;
  }

  .el-col-xs-pull-16 {
    position: relative;
    right: 66.6666666667%;
  }

  .el-col-xs-push-16 {
    position: relative;
    left: 66.6666666667%;
  }

  .el-col-xs-17 {
    display: block;
    max-width: 70.8333333333%;
    flex: 0 0 70.8333333333%;
  }

  .el-col-xs-offset-17 {
    margin-left: 70.8333333333%;
  }

  .el-col-xs-pull-17 {
    position: relative;
    right: 70.8333333333%;
  }

  .el-col-xs-push-17 {
    position: relative;
    left: 70.8333333333%;
  }

  .el-col-xs-18 {
    display: block;
    max-width: 75%;
    flex: 0 0 75%;
  }

  .el-col-xs-offset-18 {
    margin-left: 75%;
  }

  .el-col-xs-pull-18 {
    position: relative;
    right: 75%;
  }

  .el-col-xs-push-18 {
    position: relative;
    left: 75%;
  }

  .el-col-xs-19 {
    display: block;
    max-width: 79.1666666667%;
    flex: 0 0 79.1666666667%;
  }

  .el-col-xs-offset-19 {
    margin-left: 79.1666666667%;
  }

  .el-col-xs-pull-19 {
    position: relative;
    right: 79.1666666667%;
  }

  .el-col-xs-push-19 {
    position: relative;
    left: 79.1666666667%;
  }

  .el-col-xs-20 {
    display: block;
    max-width: 83.3333333333%;
    flex: 0 0 83.3333333333%;
  }

  .el-col-xs-offset-20 {
    margin-left: 83.3333333333%;
  }

  .el-col-xs-pull-20 {
    position: relative;
    right: 83.3333333333%;
  }

  .el-col-xs-push-20 {
    position: relative;
    left: 83.3333333333%;
  }

  .el-col-xs-21 {
    display: block;
    max-width: 87.5%;
    flex: 0 0 87.5%;
  }

  .el-col-xs-offset-21 {
    margin-left: 87.5%;
  }

  .el-col-xs-pull-21 {
    position: relative;
    right: 87.5%;
  }

  .el-col-xs-push-21 {
    position: relative;
    left: 87.5%;
  }

  .el-col-xs-22 {
    display: block;
    max-width: 91.6666666667%;
    flex: 0 0 91.6666666667%;
  }

  .el-col-xs-offset-22 {
    margin-left: 91.6666666667%;
  }

  .el-col-xs-pull-22 {
    position: relative;
    right: 91.6666666667%;
  }

  .el-col-xs-push-22 {
    position: relative;
    left: 91.6666666667%;
  }

  .el-col-xs-23 {
    display: block;
    max-width: 95.8333333333%;
    flex: 0 0 95.8333333333%;
  }

  .el-col-xs-offset-23 {
    margin-left: 95.8333333333%;
  }

  .el-col-xs-pull-23 {
    position: relative;
    right: 95.8333333333%;
  }

  .el-col-xs-push-23 {
    position: relative;
    left: 95.8333333333%;
  }

  .el-col-xs-24 {
    display: block;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .el-col-xs-offset-24 {
    margin-left: 100%;
  }

  .el-col-xs-pull-24 {
    position: relative;
    right: 100%;
  }

  .el-col-xs-push-24 {
    position: relative;
    left: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .el-col-sm-0 {
    display: none;
  }
  .el-col-sm-0.is-guttered {
    display: none;
  }

  .el-col-sm-0 {
    max-width: 0%;
    flex: 0 0 0%;
  }

  .el-col-sm-offset-0 {
    margin-left: 0%;
  }

  .el-col-sm-pull-0 {
    position: relative;
    right: 0%;
  }

  .el-col-sm-push-0 {
    position: relative;
    left: 0%;
  }

  .el-col-sm-1 {
    display: block;
    max-width: 4.1666666667%;
    flex: 0 0 4.1666666667%;
  }

  .el-col-sm-offset-1 {
    margin-left: 4.1666666667%;
  }

  .el-col-sm-pull-1 {
    position: relative;
    right: 4.1666666667%;
  }

  .el-col-sm-push-1 {
    position: relative;
    left: 4.1666666667%;
  }

  .el-col-sm-2 {
    display: block;
    max-width: 8.3333333333%;
    flex: 0 0 8.3333333333%;
  }

  .el-col-sm-offset-2 {
    margin-left: 8.3333333333%;
  }

  .el-col-sm-pull-2 {
    position: relative;
    right: 8.3333333333%;
  }

  .el-col-sm-push-2 {
    position: relative;
    left: 8.3333333333%;
  }

  .el-col-sm-3 {
    display: block;
    max-width: 12.5%;
    flex: 0 0 12.5%;
  }

  .el-col-sm-offset-3 {
    margin-left: 12.5%;
  }

  .el-col-sm-pull-3 {
    position: relative;
    right: 12.5%;
  }

  .el-col-sm-push-3 {
    position: relative;
    left: 12.5%;
  }

  .el-col-sm-4 {
    display: block;
    max-width: 16.6666666667%;
    flex: 0 0 16.6666666667%;
  }

  .el-col-sm-offset-4 {
    margin-left: 16.6666666667%;
  }

  .el-col-sm-pull-4 {
    position: relative;
    right: 16.6666666667%;
  }

  .el-col-sm-push-4 {
    position: relative;
    left: 16.6666666667%;
  }

  .el-col-sm-5 {
    display: block;
    max-width: 20.8333333333%;
    flex: 0 0 20.8333333333%;
  }

  .el-col-sm-offset-5 {
    margin-left: 20.8333333333%;
  }

  .el-col-sm-pull-5 {
    position: relative;
    right: 20.8333333333%;
  }

  .el-col-sm-push-5 {
    position: relative;
    left: 20.8333333333%;
  }

  .el-col-sm-6 {
    display: block;
    max-width: 25%;
    flex: 0 0 25%;
  }

  .el-col-sm-offset-6 {
    margin-left: 25%;
  }

  .el-col-sm-pull-6 {
    position: relative;
    right: 25%;
  }

  .el-col-sm-push-6 {
    position: relative;
    left: 25%;
  }

  .el-col-sm-7 {
    display: block;
    max-width: 29.1666666667%;
    flex: 0 0 29.1666666667%;
  }

  .el-col-sm-offset-7 {
    margin-left: 29.1666666667%;
  }

  .el-col-sm-pull-7 {
    position: relative;
    right: 29.1666666667%;
  }

  .el-col-sm-push-7 {
    position: relative;
    left: 29.1666666667%;
  }

  .el-col-sm-8 {
    display: block;
    max-width: 33.3333333333%;
    flex: 0 0 33.3333333333%;
  }

  .el-col-sm-offset-8 {
    margin-left: 33.3333333333%;
  }

  .el-col-sm-pull-8 {
    position: relative;
    right: 33.3333333333%;
  }

  .el-col-sm-push-8 {
    position: relative;
    left: 33.3333333333%;
  }

  .el-col-sm-9 {
    display: block;
    max-width: 37.5%;
    flex: 0 0 37.5%;
  }

  .el-col-sm-offset-9 {
    margin-left: 37.5%;
  }

  .el-col-sm-pull-9 {
    position: relative;
    right: 37.5%;
  }

  .el-col-sm-push-9 {
    position: relative;
    left: 37.5%;
  }

  .el-col-sm-10 {
    display: block;
    max-width: 41.6666666667%;
    flex: 0 0 41.6666666667%;
  }

  .el-col-sm-offset-10 {
    margin-left: 41.6666666667%;
  }

  .el-col-sm-pull-10 {
    position: relative;
    right: 41.6666666667%;
  }

  .el-col-sm-push-10 {
    position: relative;
    left: 41.6666666667%;
  }

  .el-col-sm-11 {
    display: block;
    max-width: 45.8333333333%;
    flex: 0 0 45.8333333333%;
  }

  .el-col-sm-offset-11 {
    margin-left: 45.8333333333%;
  }

  .el-col-sm-pull-11 {
    position: relative;
    right: 45.8333333333%;
  }

  .el-col-sm-push-11 {
    position: relative;
    left: 45.8333333333%;
  }

  .el-col-sm-12 {
    display: block;
    max-width: 50%;
    flex: 0 0 50%;
  }

  .el-col-sm-offset-12 {
    margin-left: 50%;
  }

  .el-col-sm-pull-12 {
    position: relative;
    right: 50%;
  }

  .el-col-sm-push-12 {
    position: relative;
    left: 50%;
  }

  .el-col-sm-13 {
    display: block;
    max-width: 54.1666666667%;
    flex: 0 0 54.1666666667%;
  }

  .el-col-sm-offset-13 {
    margin-left: 54.1666666667%;
  }

  .el-col-sm-pull-13 {
    position: relative;
    right: 54.1666666667%;
  }

  .el-col-sm-push-13 {
    position: relative;
    left: 54.1666666667%;
  }

  .el-col-sm-14 {
    display: block;
    max-width: 58.3333333333%;
    flex: 0 0 58.3333333333%;
  }

  .el-col-sm-offset-14 {
    margin-left: 58.3333333333%;
  }

  .el-col-sm-pull-14 {
    position: relative;
    right: 58.3333333333%;
  }

  .el-col-sm-push-14 {
    position: relative;
    left: 58.3333333333%;
  }

  .el-col-sm-15 {
    display: block;
    max-width: 62.5%;
    flex: 0 0 62.5%;
  }

  .el-col-sm-offset-15 {
    margin-left: 62.5%;
  }

  .el-col-sm-pull-15 {
    position: relative;
    right: 62.5%;
  }

  .el-col-sm-push-15 {
    position: relative;
    left: 62.5%;
  }

  .el-col-sm-16 {
    display: block;
    max-width: 66.6666666667%;
    flex: 0 0 66.6666666667%;
  }

  .el-col-sm-offset-16 {
    margin-left: 66.6666666667%;
  }

  .el-col-sm-pull-16 {
    position: relative;
    right: 66.6666666667%;
  }

  .el-col-sm-push-16 {
    position: relative;
    left: 66.6666666667%;
  }

  .el-col-sm-17 {
    display: block;
    max-width: 70.8333333333%;
    flex: 0 0 70.8333333333%;
  }

  .el-col-sm-offset-17 {
    margin-left: 70.8333333333%;
  }

  .el-col-sm-pull-17 {
    position: relative;
    right: 70.8333333333%;
  }

  .el-col-sm-push-17 {
    position: relative;
    left: 70.8333333333%;
  }

  .el-col-sm-18 {
    display: block;
    max-width: 75%;
    flex: 0 0 75%;
  }

  .el-col-sm-offset-18 {
    margin-left: 75%;
  }

  .el-col-sm-pull-18 {
    position: relative;
    right: 75%;
  }

  .el-col-sm-push-18 {
    position: relative;
    left: 75%;
  }

  .el-col-sm-19 {
    display: block;
    max-width: 79.1666666667%;
    flex: 0 0 79.1666666667%;
  }

  .el-col-sm-offset-19 {
    margin-left: 79.1666666667%;
  }

  .el-col-sm-pull-19 {
    position: relative;
    right: 79.1666666667%;
  }

  .el-col-sm-push-19 {
    position: relative;
    left: 79.1666666667%;
  }

  .el-col-sm-20 {
    display: block;
    max-width: 83.3333333333%;
    flex: 0 0 83.3333333333%;
  }

  .el-col-sm-offset-20 {
    margin-left: 83.3333333333%;
  }

  .el-col-sm-pull-20 {
    position: relative;
    right: 83.3333333333%;
  }

  .el-col-sm-push-20 {
    position: relative;
    left: 83.3333333333%;
  }

  .el-col-sm-21 {
    display: block;
    max-width: 87.5%;
    flex: 0 0 87.5%;
  }

  .el-col-sm-offset-21 {
    margin-left: 87.5%;
  }

  .el-col-sm-pull-21 {
    position: relative;
    right: 87.5%;
  }

  .el-col-sm-push-21 {
    position: relative;
    left: 87.5%;
  }

  .el-col-sm-22 {
    display: block;
    max-width: 91.6666666667%;
    flex: 0 0 91.6666666667%;
  }

  .el-col-sm-offset-22 {
    margin-left: 91.6666666667%;
  }

  .el-col-sm-pull-22 {
    position: relative;
    right: 91.6666666667%;
  }

  .el-col-sm-push-22 {
    position: relative;
    left: 91.6666666667%;
  }

  .el-col-sm-23 {
    display: block;
    max-width: 95.8333333333%;
    flex: 0 0 95.8333333333%;
  }

  .el-col-sm-offset-23 {
    margin-left: 95.8333333333%;
  }

  .el-col-sm-pull-23 {
    position: relative;
    right: 95.8333333333%;
  }

  .el-col-sm-push-23 {
    position: relative;
    left: 95.8333333333%;
  }

  .el-col-sm-24 {
    display: block;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .el-col-sm-offset-24 {
    margin-left: 100%;
  }

  .el-col-sm-pull-24 {
    position: relative;
    right: 100%;
  }

  .el-col-sm-push-24 {
    position: relative;
    left: 100%;
  }
}
@media only screen and (min-width: 992px) {
  .el-col-md-0 {
    display: none;
  }
  .el-col-md-0.is-guttered {
    display: none;
  }

  .el-col-md-0 {
    max-width: 0%;
    flex: 0 0 0%;
  }

  .el-col-md-offset-0 {
    margin-left: 0%;
  }

  .el-col-md-pull-0 {
    position: relative;
    right: 0%;
  }

  .el-col-md-push-0 {
    position: relative;
    left: 0%;
  }

  .el-col-md-1 {
    display: block;
    max-width: 4.1666666667%;
    flex: 0 0 4.1666666667%;
  }

  .el-col-md-offset-1 {
    margin-left: 4.1666666667%;
  }

  .el-col-md-pull-1 {
    position: relative;
    right: 4.1666666667%;
  }

  .el-col-md-push-1 {
    position: relative;
    left: 4.1666666667%;
  }

  .el-col-md-2 {
    display: block;
    max-width: 8.3333333333%;
    flex: 0 0 8.3333333333%;
  }

  .el-col-md-offset-2 {
    margin-left: 8.3333333333%;
  }

  .el-col-md-pull-2 {
    position: relative;
    right: 8.3333333333%;
  }

  .el-col-md-push-2 {
    position: relative;
    left: 8.3333333333%;
  }

  .el-col-md-3 {
    display: block;
    max-width: 12.5%;
    flex: 0 0 12.5%;
  }

  .el-col-md-offset-3 {
    margin-left: 12.5%;
  }

  .el-col-md-pull-3 {
    position: relative;
    right: 12.5%;
  }

  .el-col-md-push-3 {
    position: relative;
    left: 12.5%;
  }

  .el-col-md-4 {
    display: block;
    max-width: 16.6666666667%;
    flex: 0 0 16.6666666667%;
  }

  .el-col-md-offset-4 {
    margin-left: 16.6666666667%;
  }

  .el-col-md-pull-4 {
    position: relative;
    right: 16.6666666667%;
  }

  .el-col-md-push-4 {
    position: relative;
    left: 16.6666666667%;
  }

  .el-col-md-5 {
    display: block;
    max-width: 20.8333333333%;
    flex: 0 0 20.8333333333%;
  }

  .el-col-md-offset-5 {
    margin-left: 20.8333333333%;
  }

  .el-col-md-pull-5 {
    position: relative;
    right: 20.8333333333%;
  }

  .el-col-md-push-5 {
    position: relative;
    left: 20.8333333333%;
  }

  .el-col-md-6 {
    display: block;
    max-width: 25%;
    flex: 0 0 25%;
  }

  .el-col-md-offset-6 {
    margin-left: 25%;
  }

  .el-col-md-pull-6 {
    position: relative;
    right: 25%;
  }

  .el-col-md-push-6 {
    position: relative;
    left: 25%;
  }

  .el-col-md-7 {
    display: block;
    max-width: 29.1666666667%;
    flex: 0 0 29.1666666667%;
  }

  .el-col-md-offset-7 {
    margin-left: 29.1666666667%;
  }

  .el-col-md-pull-7 {
    position: relative;
    right: 29.1666666667%;
  }

  .el-col-md-push-7 {
    position: relative;
    left: 29.1666666667%;
  }

  .el-col-md-8 {
    display: block;
    max-width: 33.3333333333%;
    flex: 0 0 33.3333333333%;
  }

  .el-col-md-offset-8 {
    margin-left: 33.3333333333%;
  }

  .el-col-md-pull-8 {
    position: relative;
    right: 33.3333333333%;
  }

  .el-col-md-push-8 {
    position: relative;
    left: 33.3333333333%;
  }

  .el-col-md-9 {
    display: block;
    max-width: 37.5%;
    flex: 0 0 37.5%;
  }

  .el-col-md-offset-9 {
    margin-left: 37.5%;
  }

  .el-col-md-pull-9 {
    position: relative;
    right: 37.5%;
  }

  .el-col-md-push-9 {
    position: relative;
    left: 37.5%;
  }

  .el-col-md-10 {
    display: block;
    max-width: 41.6666666667%;
    flex: 0 0 41.6666666667%;
  }

  .el-col-md-offset-10 {
    margin-left: 41.6666666667%;
  }

  .el-col-md-pull-10 {
    position: relative;
    right: 41.6666666667%;
  }

  .el-col-md-push-10 {
    position: relative;
    left: 41.6666666667%;
  }

  .el-col-md-11 {
    display: block;
    max-width: 45.8333333333%;
    flex: 0 0 45.8333333333%;
  }

  .el-col-md-offset-11 {
    margin-left: 45.8333333333%;
  }

  .el-col-md-pull-11 {
    position: relative;
    right: 45.8333333333%;
  }

  .el-col-md-push-11 {
    position: relative;
    left: 45.8333333333%;
  }

  .el-col-md-12 {
    display: block;
    max-width: 50%;
    flex: 0 0 50%;
  }

  .el-col-md-offset-12 {
    margin-left: 50%;
  }

  .el-col-md-pull-12 {
    position: relative;
    right: 50%;
  }

  .el-col-md-push-12 {
    position: relative;
    left: 50%;
  }

  .el-col-md-13 {
    display: block;
    max-width: 54.1666666667%;
    flex: 0 0 54.1666666667%;
  }

  .el-col-md-offset-13 {
    margin-left: 54.1666666667%;
  }

  .el-col-md-pull-13 {
    position: relative;
    right: 54.1666666667%;
  }

  .el-col-md-push-13 {
    position: relative;
    left: 54.1666666667%;
  }

  .el-col-md-14 {
    display: block;
    max-width: 58.3333333333%;
    flex: 0 0 58.3333333333%;
  }

  .el-col-md-offset-14 {
    margin-left: 58.3333333333%;
  }

  .el-col-md-pull-14 {
    position: relative;
    right: 58.3333333333%;
  }

  .el-col-md-push-14 {
    position: relative;
    left: 58.3333333333%;
  }

  .el-col-md-15 {
    display: block;
    max-width: 62.5%;
    flex: 0 0 62.5%;
  }

  .el-col-md-offset-15 {
    margin-left: 62.5%;
  }

  .el-col-md-pull-15 {
    position: relative;
    right: 62.5%;
  }

  .el-col-md-push-15 {
    position: relative;
    left: 62.5%;
  }

  .el-col-md-16 {
    display: block;
    max-width: 66.6666666667%;
    flex: 0 0 66.6666666667%;
  }

  .el-col-md-offset-16 {
    margin-left: 66.6666666667%;
  }

  .el-col-md-pull-16 {
    position: relative;
    right: 66.6666666667%;
  }

  .el-col-md-push-16 {
    position: relative;
    left: 66.6666666667%;
  }

  .el-col-md-17 {
    display: block;
    max-width: 70.8333333333%;
    flex: 0 0 70.8333333333%;
  }

  .el-col-md-offset-17 {
    margin-left: 70.8333333333%;
  }

  .el-col-md-pull-17 {
    position: relative;
    right: 70.8333333333%;
  }

  .el-col-md-push-17 {
    position: relative;
    left: 70.8333333333%;
  }

  .el-col-md-18 {
    display: block;
    max-width: 75%;
    flex: 0 0 75%;
  }

  .el-col-md-offset-18 {
    margin-left: 75%;
  }

  .el-col-md-pull-18 {
    position: relative;
    right: 75%;
  }

  .el-col-md-push-18 {
    position: relative;
    left: 75%;
  }

  .el-col-md-19 {
    display: block;
    max-width: 79.1666666667%;
    flex: 0 0 79.1666666667%;
  }

  .el-col-md-offset-19 {
    margin-left: 79.1666666667%;
  }

  .el-col-md-pull-19 {
    position: relative;
    right: 79.1666666667%;
  }

  .el-col-md-push-19 {
    position: relative;
    left: 79.1666666667%;
  }

  .el-col-md-20 {
    display: block;
    max-width: 83.3333333333%;
    flex: 0 0 83.3333333333%;
  }

  .el-col-md-offset-20 {
    margin-left: 83.3333333333%;
  }

  .el-col-md-pull-20 {
    position: relative;
    right: 83.3333333333%;
  }

  .el-col-md-push-20 {
    position: relative;
    left: 83.3333333333%;
  }

  .el-col-md-21 {
    display: block;
    max-width: 87.5%;
    flex: 0 0 87.5%;
  }

  .el-col-md-offset-21 {
    margin-left: 87.5%;
  }

  .el-col-md-pull-21 {
    position: relative;
    right: 87.5%;
  }

  .el-col-md-push-21 {
    position: relative;
    left: 87.5%;
  }

  .el-col-md-22 {
    display: block;
    max-width: 91.6666666667%;
    flex: 0 0 91.6666666667%;
  }

  .el-col-md-offset-22 {
    margin-left: 91.6666666667%;
  }

  .el-col-md-pull-22 {
    position: relative;
    right: 91.6666666667%;
  }

  .el-col-md-push-22 {
    position: relative;
    left: 91.6666666667%;
  }

  .el-col-md-23 {
    display: block;
    max-width: 95.8333333333%;
    flex: 0 0 95.8333333333%;
  }

  .el-col-md-offset-23 {
    margin-left: 95.8333333333%;
  }

  .el-col-md-pull-23 {
    position: relative;
    right: 95.8333333333%;
  }

  .el-col-md-push-23 {
    position: relative;
    left: 95.8333333333%;
  }

  .el-col-md-24 {
    display: block;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .el-col-md-offset-24 {
    margin-left: 100%;
  }

  .el-col-md-pull-24 {
    position: relative;
    right: 100%;
  }

  .el-col-md-push-24 {
    position: relative;
    left: 100%;
  }
}
@media only screen and (min-width: 1200px) {
  .el-col-lg-0 {
    display: none;
  }
  .el-col-lg-0.is-guttered {
    display: none;
  }

  .el-col-lg-0 {
    max-width: 0%;
    flex: 0 0 0%;
  }

  .el-col-lg-offset-0 {
    margin-left: 0%;
  }

  .el-col-lg-pull-0 {
    position: relative;
    right: 0%;
  }

  .el-col-lg-push-0 {
    position: relative;
    left: 0%;
  }

  .el-col-lg-1 {
    display: block;
    max-width: 4.1666666667%;
    flex: 0 0 4.1666666667%;
  }

  .el-col-lg-offset-1 {
    margin-left: 4.1666666667%;
  }

  .el-col-lg-pull-1 {
    position: relative;
    right: 4.1666666667%;
  }

  .el-col-lg-push-1 {
    position: relative;
    left: 4.1666666667%;
  }

  .el-col-lg-2 {
    display: block;
    max-width: 8.3333333333%;
    flex: 0 0 8.3333333333%;
  }

  .el-col-lg-offset-2 {
    margin-left: 8.3333333333%;
  }

  .el-col-lg-pull-2 {
    position: relative;
    right: 8.3333333333%;
  }

  .el-col-lg-push-2 {
    position: relative;
    left: 8.3333333333%;
  }

  .el-col-lg-3 {
    display: block;
    max-width: 12.5%;
    flex: 0 0 12.5%;
  }

  .el-col-lg-offset-3 {
    margin-left: 12.5%;
  }

  .el-col-lg-pull-3 {
    position: relative;
    right: 12.5%;
  }

  .el-col-lg-push-3 {
    position: relative;
    left: 12.5%;
  }

  .el-col-lg-4 {
    display: block;
    max-width: 16.6666666667%;
    flex: 0 0 16.6666666667%;
  }

  .el-col-lg-offset-4 {
    margin-left: 16.6666666667%;
  }

  .el-col-lg-pull-4 {
    position: relative;
    right: 16.6666666667%;
  }

  .el-col-lg-push-4 {
    position: relative;
    left: 16.6666666667%;
  }

  .el-col-lg-5 {
    display: block;
    max-width: 20.8333333333%;
    flex: 0 0 20.8333333333%;
  }

  .el-col-lg-offset-5 {
    margin-left: 20.8333333333%;
  }

  .el-col-lg-pull-5 {
    position: relative;
    right: 20.8333333333%;
  }

  .el-col-lg-push-5 {
    position: relative;
    left: 20.8333333333%;
  }

  .el-col-lg-6 {
    display: block;
    max-width: 25%;
    flex: 0 0 25%;
  }

  .el-col-lg-offset-6 {
    margin-left: 25%;
  }

  .el-col-lg-pull-6 {
    position: relative;
    right: 25%;
  }

  .el-col-lg-push-6 {
    position: relative;
    left: 25%;
  }

  .el-col-lg-7 {
    display: block;
    max-width: 29.1666666667%;
    flex: 0 0 29.1666666667%;
  }

  .el-col-lg-offset-7 {
    margin-left: 29.1666666667%;
  }

  .el-col-lg-pull-7 {
    position: relative;
    right: 29.1666666667%;
  }

  .el-col-lg-push-7 {
    position: relative;
    left: 29.1666666667%;
  }

  .el-col-lg-8 {
    display: block;
    max-width: 33.3333333333%;
    flex: 0 0 33.3333333333%;
  }

  .el-col-lg-offset-8 {
    margin-left: 33.3333333333%;
  }

  .el-col-lg-pull-8 {
    position: relative;
    right: 33.3333333333%;
  }

  .el-col-lg-push-8 {
    position: relative;
    left: 33.3333333333%;
  }

  .el-col-lg-9 {
    display: block;
    max-width: 37.5%;
    flex: 0 0 37.5%;
  }

  .el-col-lg-offset-9 {
    margin-left: 37.5%;
  }

  .el-col-lg-pull-9 {
    position: relative;
    right: 37.5%;
  }

  .el-col-lg-push-9 {
    position: relative;
    left: 37.5%;
  }

  .el-col-lg-10 {
    display: block;
    max-width: 41.6666666667%;
    flex: 0 0 41.6666666667%;
  }

  .el-col-lg-offset-10 {
    margin-left: 41.6666666667%;
  }

  .el-col-lg-pull-10 {
    position: relative;
    right: 41.6666666667%;
  }

  .el-col-lg-push-10 {
    position: relative;
    left: 41.6666666667%;
  }

  .el-col-lg-11 {
    display: block;
    max-width: 45.8333333333%;
    flex: 0 0 45.8333333333%;
  }

  .el-col-lg-offset-11 {
    margin-left: 45.8333333333%;
  }

  .el-col-lg-pull-11 {
    position: relative;
    right: 45.8333333333%;
  }

  .el-col-lg-push-11 {
    position: relative;
    left: 45.8333333333%;
  }

  .el-col-lg-12 {
    display: block;
    max-width: 50%;
    flex: 0 0 50%;
  }

  .el-col-lg-offset-12 {
    margin-left: 50%;
  }

  .el-col-lg-pull-12 {
    position: relative;
    right: 50%;
  }

  .el-col-lg-push-12 {
    position: relative;
    left: 50%;
  }

  .el-col-lg-13 {
    display: block;
    max-width: 54.1666666667%;
    flex: 0 0 54.1666666667%;
  }

  .el-col-lg-offset-13 {
    margin-left: 54.1666666667%;
  }

  .el-col-lg-pull-13 {
    position: relative;
    right: 54.1666666667%;
  }

  .el-col-lg-push-13 {
    position: relative;
    left: 54.1666666667%;
  }

  .el-col-lg-14 {
    display: block;
    max-width: 58.3333333333%;
    flex: 0 0 58.3333333333%;
  }

  .el-col-lg-offset-14 {
    margin-left: 58.3333333333%;
  }

  .el-col-lg-pull-14 {
    position: relative;
    right: 58.3333333333%;
  }

  .el-col-lg-push-14 {
    position: relative;
    left: 58.3333333333%;
  }

  .el-col-lg-15 {
    display: block;
    max-width: 62.5%;
    flex: 0 0 62.5%;
  }

  .el-col-lg-offset-15 {
    margin-left: 62.5%;
  }

  .el-col-lg-pull-15 {
    position: relative;
    right: 62.5%;
  }

  .el-col-lg-push-15 {
    position: relative;
    left: 62.5%;
  }

  .el-col-lg-16 {
    display: block;
    max-width: 66.6666666667%;
    flex: 0 0 66.6666666667%;
  }

  .el-col-lg-offset-16 {
    margin-left: 66.6666666667%;
  }

  .el-col-lg-pull-16 {
    position: relative;
    right: 66.6666666667%;
  }

  .el-col-lg-push-16 {
    position: relative;
    left: 66.6666666667%;
  }

  .el-col-lg-17 {
    display: block;
    max-width: 70.8333333333%;
    flex: 0 0 70.8333333333%;
  }

  .el-col-lg-offset-17 {
    margin-left: 70.8333333333%;
  }

  .el-col-lg-pull-17 {
    position: relative;
    right: 70.8333333333%;
  }

  .el-col-lg-push-17 {
    position: relative;
    left: 70.8333333333%;
  }

  .el-col-lg-18 {
    display: block;
    max-width: 75%;
    flex: 0 0 75%;
  }

  .el-col-lg-offset-18 {
    margin-left: 75%;
  }

  .el-col-lg-pull-18 {
    position: relative;
    right: 75%;
  }

  .el-col-lg-push-18 {
    position: relative;
    left: 75%;
  }

  .el-col-lg-19 {
    display: block;
    max-width: 79.1666666667%;
    flex: 0 0 79.1666666667%;
  }

  .el-col-lg-offset-19 {
    margin-left: 79.1666666667%;
  }

  .el-col-lg-pull-19 {
    position: relative;
    right: 79.1666666667%;
  }

  .el-col-lg-push-19 {
    position: relative;
    left: 79.1666666667%;
  }

  .el-col-lg-20 {
    display: block;
    max-width: 83.3333333333%;
    flex: 0 0 83.3333333333%;
  }

  .el-col-lg-offset-20 {
    margin-left: 83.3333333333%;
  }

  .el-col-lg-pull-20 {
    position: relative;
    right: 83.3333333333%;
  }

  .el-col-lg-push-20 {
    position: relative;
    left: 83.3333333333%;
  }

  .el-col-lg-21 {
    display: block;
    max-width: 87.5%;
    flex: 0 0 87.5%;
  }

  .el-col-lg-offset-21 {
    margin-left: 87.5%;
  }

  .el-col-lg-pull-21 {
    position: relative;
    right: 87.5%;
  }

  .el-col-lg-push-21 {
    position: relative;
    left: 87.5%;
  }

  .el-col-lg-22 {
    display: block;
    max-width: 91.6666666667%;
    flex: 0 0 91.6666666667%;
  }

  .el-col-lg-offset-22 {
    margin-left: 91.6666666667%;
  }

  .el-col-lg-pull-22 {
    position: relative;
    right: 91.6666666667%;
  }

  .el-col-lg-push-22 {
    position: relative;
    left: 91.6666666667%;
  }

  .el-col-lg-23 {
    display: block;
    max-width: 95.8333333333%;
    flex: 0 0 95.8333333333%;
  }

  .el-col-lg-offset-23 {
    margin-left: 95.8333333333%;
  }

  .el-col-lg-pull-23 {
    position: relative;
    right: 95.8333333333%;
  }

  .el-col-lg-push-23 {
    position: relative;
    left: 95.8333333333%;
  }

  .el-col-lg-24 {
    display: block;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .el-col-lg-offset-24 {
    margin-left: 100%;
  }

  .el-col-lg-pull-24 {
    position: relative;
    right: 100%;
  }

  .el-col-lg-push-24 {
    position: relative;
    left: 100%;
  }
}
@media only screen and (min-width: 1920px) {
  .el-col-xl-0 {
    display: none;
  }
  .el-col-xl-0.is-guttered {
    display: none;
  }

  .el-col-xl-0 {
    max-width: 0%;
    flex: 0 0 0%;
  }

  .el-col-xl-offset-0 {
    margin-left: 0%;
  }

  .el-col-xl-pull-0 {
    position: relative;
    right: 0%;
  }

  .el-col-xl-push-0 {
    position: relative;
    left: 0%;
  }

  .el-col-xl-1 {
    display: block;
    max-width: 4.1666666667%;
    flex: 0 0 4.1666666667%;
  }

  .el-col-xl-offset-1 {
    margin-left: 4.1666666667%;
  }

  .el-col-xl-pull-1 {
    position: relative;
    right: 4.1666666667%;
  }

  .el-col-xl-push-1 {
    position: relative;
    left: 4.1666666667%;
  }

  .el-col-xl-2 {
    display: block;
    max-width: 8.3333333333%;
    flex: 0 0 8.3333333333%;
  }

  .el-col-xl-offset-2 {
    margin-left: 8.3333333333%;
  }

  .el-col-xl-pull-2 {
    position: relative;
    right: 8.3333333333%;
  }

  .el-col-xl-push-2 {
    position: relative;
    left: 8.3333333333%;
  }

  .el-col-xl-3 {
    display: block;
    max-width: 12.5%;
    flex: 0 0 12.5%;
  }

  .el-col-xl-offset-3 {
    margin-left: 12.5%;
  }

  .el-col-xl-pull-3 {
    position: relative;
    right: 12.5%;
  }

  .el-col-xl-push-3 {
    position: relative;
    left: 12.5%;
  }

  .el-col-xl-4 {
    display: block;
    max-width: 16.6666666667%;
    flex: 0 0 16.6666666667%;
  }

  .el-col-xl-offset-4 {
    margin-left: 16.6666666667%;
  }

  .el-col-xl-pull-4 {
    position: relative;
    right: 16.6666666667%;
  }

  .el-col-xl-push-4 {
    position: relative;
    left: 16.6666666667%;
  }

  .el-col-xl-5 {
    display: block;
    max-width: 20.8333333333%;
    flex: 0 0 20.8333333333%;
  }

  .el-col-xl-offset-5 {
    margin-left: 20.8333333333%;
  }

  .el-col-xl-pull-5 {
    position: relative;
    right: 20.8333333333%;
  }

  .el-col-xl-push-5 {
    position: relative;
    left: 20.8333333333%;
  }

  .el-col-xl-6 {
    display: block;
    max-width: 25%;
    flex: 0 0 25%;
  }

  .el-col-xl-offset-6 {
    margin-left: 25%;
  }

  .el-col-xl-pull-6 {
    position: relative;
    right: 25%;
  }

  .el-col-xl-push-6 {
    position: relative;
    left: 25%;
  }

  .el-col-xl-7 {
    display: block;
    max-width: 29.1666666667%;
    flex: 0 0 29.1666666667%;
  }

  .el-col-xl-offset-7 {
    margin-left: 29.1666666667%;
  }

  .el-col-xl-pull-7 {
    position: relative;
    right: 29.1666666667%;
  }

  .el-col-xl-push-7 {
    position: relative;
    left: 29.1666666667%;
  }

  .el-col-xl-8 {
    display: block;
    max-width: 33.3333333333%;
    flex: 0 0 33.3333333333%;
  }

  .el-col-xl-offset-8 {
    margin-left: 33.3333333333%;
  }

  .el-col-xl-pull-8 {
    position: relative;
    right: 33.3333333333%;
  }

  .el-col-xl-push-8 {
    position: relative;
    left: 33.3333333333%;
  }

  .el-col-xl-9 {
    display: block;
    max-width: 37.5%;
    flex: 0 0 37.5%;
  }

  .el-col-xl-offset-9 {
    margin-left: 37.5%;
  }

  .el-col-xl-pull-9 {
    position: relative;
    right: 37.5%;
  }

  .el-col-xl-push-9 {
    position: relative;
    left: 37.5%;
  }

  .el-col-xl-10 {
    display: block;
    max-width: 41.6666666667%;
    flex: 0 0 41.6666666667%;
  }

  .el-col-xl-offset-10 {
    margin-left: 41.6666666667%;
  }

  .el-col-xl-pull-10 {
    position: relative;
    right: 41.6666666667%;
  }

  .el-col-xl-push-10 {
    position: relative;
    left: 41.6666666667%;
  }

  .el-col-xl-11 {
    display: block;
    max-width: 45.8333333333%;
    flex: 0 0 45.8333333333%;
  }

  .el-col-xl-offset-11 {
    margin-left: 45.8333333333%;
  }

  .el-col-xl-pull-11 {
    position: relative;
    right: 45.8333333333%;
  }

  .el-col-xl-push-11 {
    position: relative;
    left: 45.8333333333%;
  }

  .el-col-xl-12 {
    display: block;
    max-width: 50%;
    flex: 0 0 50%;
  }

  .el-col-xl-offset-12 {
    margin-left: 50%;
  }

  .el-col-xl-pull-12 {
    position: relative;
    right: 50%;
  }

  .el-col-xl-push-12 {
    position: relative;
    left: 50%;
  }

  .el-col-xl-13 {
    display: block;
    max-width: 54.1666666667%;
    flex: 0 0 54.1666666667%;
  }

  .el-col-xl-offset-13 {
    margin-left: 54.1666666667%;
  }

  .el-col-xl-pull-13 {
    position: relative;
    right: 54.1666666667%;
  }

  .el-col-xl-push-13 {
    position: relative;
    left: 54.1666666667%;
  }

  .el-col-xl-14 {
    display: block;
    max-width: 58.3333333333%;
    flex: 0 0 58.3333333333%;
  }

  .el-col-xl-offset-14 {
    margin-left: 58.3333333333%;
  }

  .el-col-xl-pull-14 {
    position: relative;
    right: 58.3333333333%;
  }

  .el-col-xl-push-14 {
    position: relative;
    left: 58.3333333333%;
  }

  .el-col-xl-15 {
    display: block;
    max-width: 62.5%;
    flex: 0 0 62.5%;
  }

  .el-col-xl-offset-15 {
    margin-left: 62.5%;
  }

  .el-col-xl-pull-15 {
    position: relative;
    right: 62.5%;
  }

  .el-col-xl-push-15 {
    position: relative;
    left: 62.5%;
  }

  .el-col-xl-16 {
    display: block;
    max-width: 66.6666666667%;
    flex: 0 0 66.6666666667%;
  }

  .el-col-xl-offset-16 {
    margin-left: 66.6666666667%;
  }

  .el-col-xl-pull-16 {
    position: relative;
    right: 66.6666666667%;
  }

  .el-col-xl-push-16 {
    position: relative;
    left: 66.6666666667%;
  }

  .el-col-xl-17 {
    display: block;
    max-width: 70.8333333333%;
    flex: 0 0 70.8333333333%;
  }

  .el-col-xl-offset-17 {
    margin-left: 70.8333333333%;
  }

  .el-col-xl-pull-17 {
    position: relative;
    right: 70.8333333333%;
  }

  .el-col-xl-push-17 {
    position: relative;
    left: 70.8333333333%;
  }

  .el-col-xl-18 {
    display: block;
    max-width: 75%;
    flex: 0 0 75%;
  }

  .el-col-xl-offset-18 {
    margin-left: 75%;
  }

  .el-col-xl-pull-18 {
    position: relative;
    right: 75%;
  }

  .el-col-xl-push-18 {
    position: relative;
    left: 75%;
  }

  .el-col-xl-19 {
    display: block;
    max-width: 79.1666666667%;
    flex: 0 0 79.1666666667%;
  }

  .el-col-xl-offset-19 {
    margin-left: 79.1666666667%;
  }

  .el-col-xl-pull-19 {
    position: relative;
    right: 79.1666666667%;
  }

  .el-col-xl-push-19 {
    position: relative;
    left: 79.1666666667%;
  }

  .el-col-xl-20 {
    display: block;
    max-width: 83.3333333333%;
    flex: 0 0 83.3333333333%;
  }

  .el-col-xl-offset-20 {
    margin-left: 83.3333333333%;
  }

  .el-col-xl-pull-20 {
    position: relative;
    right: 83.3333333333%;
  }

  .el-col-xl-push-20 {
    position: relative;
    left: 83.3333333333%;
  }

  .el-col-xl-21 {
    display: block;
    max-width: 87.5%;
    flex: 0 0 87.5%;
  }

  .el-col-xl-offset-21 {
    margin-left: 87.5%;
  }

  .el-col-xl-pull-21 {
    position: relative;
    right: 87.5%;
  }

  .el-col-xl-push-21 {
    position: relative;
    left: 87.5%;
  }

  .el-col-xl-22 {
    display: block;
    max-width: 91.6666666667%;
    flex: 0 0 91.6666666667%;
  }

  .el-col-xl-offset-22 {
    margin-left: 91.6666666667%;
  }

  .el-col-xl-pull-22 {
    position: relative;
    right: 91.6666666667%;
  }

  .el-col-xl-push-22 {
    position: relative;
    left: 91.6666666667%;
  }

  .el-col-xl-23 {
    display: block;
    max-width: 95.8333333333%;
    flex: 0 0 95.8333333333%;
  }

  .el-col-xl-offset-23 {
    margin-left: 95.8333333333%;
  }

  .el-col-xl-pull-23 {
    position: relative;
    right: 95.8333333333%;
  }

  .el-col-xl-push-23 {
    position: relative;
    left: 95.8333333333%;
  }

  .el-col-xl-24 {
    display: block;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .el-col-xl-offset-24 {
    margin-left: 100%;
  }

  .el-col-xl-pull-24 {
    position: relative;
    right: 100%;
  }

  .el-col-xl-push-24 {
    position: relative;
    left: 100%;
  }
}
.el-row {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  box-sizing: border-box;
}
.el-row.is-justify-center {
  justify-content: center;
}

.el-row.is-justify-end {
  justify-content: flex-end;
}

.el-row.is-justify-space-between {
  justify-content: space-between;
}

.el-row.is-justify-space-around {
  justify-content: space-around;
}

.el-row.is-justify-space-evenly {
  justify-content: space-evenly;
}

.el-row.is-align-middle {
  align-items: center;
}

.el-row.is-align-bottom {
  align-items: flex-end;
}

.el-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-basis: auto;
  box-sizing: border-box;
  min-width: 0;
}
.el-container.is-vertical {
  flex-direction: column;
}

.el-main {
  --el-main-padding: 20px;
  display: block;
  flex: 1;
  flex-basis: auto;
  overflow: auto;
  box-sizing: border-box;
  padding: var(--el-main-padding);
}

.el-menu {
  --el-menu-active-color: #FFFFFF;
  --el-menu-text-color: #FFFFFF;
  --el-menu-hover-text-color: #FFFFFF;
  --el-menu-bg-color: #17783C;
  --el-menu-hover-bg-color: #10542a;
  --el-menu-item-height: 70px;
  --el-menu-item-font-size: var(--el-font-size-base);
  --el-menu-item-hover-fill: var(--el-color-primary-light-9);
  --el-menu-border-color: transparent;
  border-right: solid 1px var(--el-menu-border-color);
  list-style: none;
  position: relative;
  margin: 0;
  padding-left: 0;
  background-color: var(--el-menu-bg-color);
  box-sizing: border-box;
}
.el-menu--horizontal {
  display: flex;
  flex-wrap: nowrap;
  border-bottom: solid 1px var(--el-menu-border-color);
  border-right: none;
}
.el-menu--horizontal > .el-menu-item {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
  border-bottom: 2px solid transparent;
  color: var(--el-menu-text-color);
}
.el-menu--horizontal > .el-menu-item a,
.el-menu--horizontal > .el-menu-item a:hover {
  color: inherit;
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover, .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus {
  background-color: #fff;
}
.el-menu--horizontal > .el-sub-menu:focus, .el-menu--horizontal > .el-sub-menu:hover {
  outline: none;
}
.el-menu--horizontal > .el-sub-menu:hover .el-sub-menu__title {
  color: var(--el-menu-hover-text-color);
}
.el-menu--horizontal > .el-sub-menu.is-active .el-sub-menu__title {
  border-bottom: 2px solid var(--el-menu-active-color);
  color: var(--el-menu-active-color);
}
.el-menu--horizontal > .el-sub-menu .el-sub-menu__title {
  height: 100%;
  border-bottom: 2px solid transparent;
  color: var(--el-menu-text-color);
}
.el-menu--horizontal > .el-sub-menu .el-sub-menu__title:hover {
  background-color: var(--el-bg-color-overlay);
}
.el-menu--horizontal > .el-sub-menu .el-sub-menu__icon-arrow {
  position: static;
  vertical-align: middle;
  margin-left: 8px;
  margin-top: -3px;
}
.el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-sub-menu__title {
  background-color: var(--el-menu-bg-color);
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 10px;
  color: var(--el-menu-text-color);
}
.el-menu--horizontal .el-menu .el-sub-menu__title {
  padding-right: 40px;
}
.el-menu--horizontal .el-menu .el-menu-item.is-active, .el-menu--horizontal .el-menu .el-sub-menu.is-active > .el-sub-menu__title {
  color: var(--el-menu-active-color);
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover, .el-menu--horizontal .el-menu-item:not(.is-disabled):focus {
  outline: none;
  color: var(--el-menu-hover-text-color);
  background-color: var(--el-menu-hover-bg-color);
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid var(--el-menu-active-color);
  color: var(--el-menu-active-color) !important;
}

.el-menu--collapse {
  width: 64px;
}
.el-menu--collapse > .el-menu-item [class^=el-icon],
.el-menu--collapse > .el-sub-menu > .el-sub-menu__title [class^=el-icon] {
  margin: 0;
  vertical-align: middle;
  width: 24px;
  text-align: center;
}
.el-menu--collapse > .el-menu-item .el-sub-menu__icon-arrow,
.el-menu--collapse > .el-sub-menu > .el-sub-menu__title .el-sub-menu__icon-arrow {
  display: none;
}
.el-menu--collapse > .el-menu-item > span,
.el-menu--collapse > .el-sub-menu > .el-sub-menu__title > span {
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
  display: inline-block;
}
.el-menu--collapse > .el-menu-item.is-active i {
  color: inherit;
}
.el-menu--collapse .el-menu .el-sub-menu {
  min-width: 200px;
}
.el-menu--collapse .el-sub-menu {
  position: relative;
}
.el-menu--collapse .el-sub-menu .el-menu {
  position: absolute;
  margin-left: 5px;
  top: 0;
  left: 100%;
  z-index: 10;
  border: 1px solid var(--el-border-color-light);
  border-radius: var(--el-border-radius-small);
  box-shadow: var(--el-box-shadow-light);
}
.el-menu--collapse .el-sub-menu.is-opened > .el-sub-menu__title .el-sub-menu__icon-arrow {
  transform: none;
}
.el-menu--collapse .el-sub-menu.is-active .el-sub-menu__title {
  color: var(--el-menu-active-color);
}

.el-menu--popup {
  z-index: 100;
  min-width: 200px;
  border: none;
  padding: 5px 0;
  border-radius: var(--el-border-radius-small);
  box-shadow: var(--el-box-shadow-light);
}

.el-menu .el-icon {
  flex-shrink: 0;
}

.el-menu-item {
  display: flex;
  align-items: center;
  height: var(--el-menu-item-height);
  line-height: var(--el-menu-item-height);
  font-size: var(--el-menu-item-font-size);
  color: var(--el-menu-text-color);
  padding: 0 20px;
  list-style: none;
  cursor: pointer;
  position: relative;
  transition: border-color var(--el-transition-duration), background-color var(--el-transition-duration), color var(--el-transition-duration);
  box-sizing: border-box;
  white-space: nowrap;
}
.el-menu-item * {
  vertical-align: bottom;
}
.el-menu-item i {
  color: inherit;
}
.el-menu-item:hover, .el-menu-item:focus {
  outline: none;
}
.el-menu-item:hover {
  background-color: var(--el-menu-hover-bg-color);
}
.el-menu-item.is-disabled {
  opacity: 0.25;
  cursor: not-allowed;
  background: none !important;
}

.el-menu-item [class^=el-icon] {
  margin-right: 5px;
  width: 24px;
  text-align: center;
  font-size: 18px;
  vertical-align: middle;
}
.el-menu-item.is-active {
  color: var(--el-menu-active-color);
}
.el-menu-item.is-active i {
  color: inherit;
}

.el-menu-item .el-menu-tooltip__trigger {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
}

.el-sub-menu {
  list-style: none;
  margin: 0;
  padding-left: 0;
}
.el-sub-menu__title {
  display: flex;
  align-items: center;
  height: var(--el-menu-item-height);
  line-height: var(--el-menu-item-height);
  font-size: var(--el-menu-item-font-size);
  color: var(--el-menu-text-color);
  padding: 0 20px;
  list-style: none;
  cursor: pointer;
  position: relative;
  transition: border-color var(--el-transition-duration), background-color var(--el-transition-duration), color var(--el-transition-duration);
  box-sizing: border-box;
  white-space: nowrap;
}
.el-sub-menu__title * {
  vertical-align: bottom;
}
.el-sub-menu__title i {
  color: inherit;
}
.el-sub-menu__title:hover, .el-sub-menu__title:focus {
  outline: none;
}
.el-sub-menu__title:hover {
  background-color: var(--el-menu-hover-bg-color);
}
.el-sub-menu__title.is-disabled {
  opacity: 0.25;
  cursor: not-allowed;
  background: none !important;
}

.el-sub-menu__title:hover {
  background-color: var(--el-menu-hover-bg-color);
}

.el-sub-menu .el-menu {
  border: none;
}
.el-sub-menu .el-menu-item {
  height: 50px;
  line-height: 50px;
  padding: 0 45px;
  min-width: 200px;
}
.el-sub-menu__hide-arrow .el-sub-menu__icon-arrow {
  display: none !important;
}

.el-sub-menu.is-active .el-sub-menu__title {
  border-bottom-color: var(--el-menu-active-color);
}

.el-sub-menu.is-opened > .el-sub-menu__title .el-sub-menu__icon-arrow {
  transform: rotateZ(180deg);
}

.el-sub-menu.is-disabled .el-sub-menu__title,
.el-sub-menu.is-disabled .el-menu-item {
  opacity: 0.25;
  cursor: not-allowed;
  background: none !important;
}

.el-sub-menu .el-icon {
  vertical-align: middle;
  margin-right: 5px;
  width: 24px;
  text-align: center;
  font-size: 18px;
}
.el-sub-menu .el-icon.el-sub-menu__icon-more {
  margin-right: 0 !important;
}
.el-sub-menu .el-sub-menu__icon-arrow {
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -7px;
  transition: transform var(--el-transition-duration);
  font-size: 12px;
  margin-right: 0;
  width: inherit;
}

.el-menu-item-group > ul {
  padding: 0;
}
.el-menu-item-group__title {
  padding: 7px 0 7px 20px;
  line-height: normal;
  font-size: 12px;
  color: var(--el-text-color-secondary);
}

.horizontal-collapse-transition .el-sub-menu__title .el-sub-menu__icon-arrow {
  transition: var(--el-transition-duration-fast);
  opacity: 0;
}

.el-message-box {
  --el-messagebox-title-color: var(--el-text-color-primary);
  --el-messagebox-width: 420px;
  --el-messagebox-border-radius: 4px;
  --el-messagebox-font-size: var(--el-font-size-large);
  --el-messagebox-content-font-size: var(--el-font-size-base);
  --el-messagebox-content-color: var(--el-text-color-regular);
  --el-messagebox-error-font-size: 12px;
  --el-messagebox-padding-primary: 15px;
}

.el-message-box {
  display: inline-block;
  width: var(--el-messagebox-width);
  padding-bottom: 10px;
  vertical-align: middle;
  background-color: var(--el-bg-color);
  border-radius: var(--el-messagebox-border-radius);
  border: 1px solid var(--el-border-color-lighter);
  font-size: var(--el-messagebox-font-size);
  box-shadow: var(--el-box-shadow-light);
  text-align: left;
  overflow: hidden;
  backface-visibility: hidden;
}
.el-overlay.is-message-box .el-overlay-message-box {
  text-align: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}
.el-overlay.is-message-box .el-overlay-message-box::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 0;
  vertical-align: middle;
}

.el-message-box.is-draggable .el-message-box__header {
  cursor: move;
  user-select: none;
}

.el-message-box__header {
  position: relative;
  padding: var(--el-messagebox-padding-primary);
  padding-bottom: 10px;
}

.el-message-box__title {
  padding-left: 0;
  margin-bottom: 0;
  font-size: var(--el-messagebox-font-size);
  line-height: 1;
  color: var(--el-messagebox-title-color);
}

.el-message-box__headerbtn {
  position: absolute;
  top: var(--el-messagebox-padding-primary);
  right: var(--el-messagebox-padding-primary);
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  font-size: var(--el-message-close-size, 16px);
  cursor: pointer;
}
.el-message-box__headerbtn .el-message-box__close {
  color: var(--el-color-info);
  font-size: inherit;
}
.el-message-box__headerbtn:focus .el-message-box__close, .el-message-box__headerbtn:hover .el-message-box__close {
  color: var(--el-color-primary);
}

.el-message-box__content {
  padding: 10px var(--el-messagebox-padding-primary);
  color: var(--el-messagebox-content-color);
  font-size: var(--el-messagebox-content-font-size);
}

.el-message-box__container {
  position: relative;
}

.el-message-box__input {
  padding-top: 15px;
}
.el-message-box__input div.invalid > input {
  border-color: var(--el-color-error);
}
.el-message-box__input div.invalid > input:focus {
  border-color: var(--el-color-error);
}

.el-message-box__status {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px !important;
}
.el-message-box__status::before {
  padding-left: 1px;
}
.el-message-box__status.el-icon {
  position: absolute;
}
.el-message-box__status + .el-message-box__message {
  padding-left: 36px;
  padding-right: 12px;
  word-break: break-word;
}
.el-message-box__status.el-message-box-icon--success {
  --el-messagebox-color: var(--el-color-success);
  color: var(--el-messagebox-color);
}
.el-message-box__status.el-message-box-icon--info {
  --el-messagebox-color: var(--el-color-info);
  color: var(--el-messagebox-color);
}
.el-message-box__status.el-message-box-icon--warning {
  --el-messagebox-color: var(--el-color-warning);
  color: var(--el-messagebox-color);
}
.el-message-box__status.el-message-box-icon--error {
  --el-messagebox-color: var(--el-color-error);
  color: var(--el-messagebox-color);
}

.el-message-box__message {
  margin: 0;
}
.el-message-box__message p {
  margin: 0;
  line-height: 24px;
}

.el-message-box__errormsg {
  color: var(--el-color-error);
  font-size: var(--el-messagebox-error-font-size);
  min-height: 18px;
  margin-top: 2px;
}

.el-message-box__btns {
  padding: 5px 15px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}
.el-message-box__btns button:nth-child(2) {
  margin-left: 10px;
}

.el-message-box__btns-reverse {
  flex-direction: row-reverse;
}

.el-message-box--center .el-message-box__title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-message-box--center .el-message-box__status {
  position: relative;
  top: auto;
  padding-right: 5px;
  text-align: center;
  transform: translateY(-1px);
}

.el-message-box--center .el-message-box__message {
  margin-left: 0;
}

.el-message-box--center .el-message-box__btns {
  justify-content: center;
}

.el-message-box--center .el-message-box__content {
  padding-left: calc(var(--el-messagebox-padding-primary) + 12px);
  padding-right: calc(var(--el-messagebox-padding-primary) + 12px);
  text-align: center;
}

.fade-in-linear-enter-active .el-overlay-message-box {
  animation: msgbox-fade-in var(--el-transition-duration);
}

.fade-in-linear-leave-active .el-overlay-message-box {
  animation: msgbox-fade-in var(--el-transition-duration) reverse;
}

@keyframes msgbox-fade-in {
  0% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes msgbox-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
}
.el-radio-button {
  --el-radio-button-checked-bg-color: var(--el-color-primary);
  --el-radio-button-checked-text-color: var(--el-color-white);
  --el-radio-button-checked-border-color: var(--el-color-primary);
  --el-radio-button-disabled-checked-fill: var(--el-border-color-extra-light);
}

.el-radio-button {
  position: relative;
  display: inline-block;
  outline: none;
}
.el-radio-button__inner {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  background: var(--el-button-bg-color, var(--el-fill-color-blank));
  border: var(--el-border);
  font-weight: var(--el-button-font-weight, 600);
  border-left: 0;
  color: var(--el-button-text-color, var(--el-text-color-regular));
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  position: relative;
  cursor: pointer;
  transition: var(--el-transition-all);
  user-select: none;
  padding: 6px 15px;
  font-size: 12px;
  border-radius: 0;
}
.el-radio-button__inner.is-round {
  padding: 6px 15px;
}
.el-radio-button__inner:hover {
  color: var(--el-color-primary);
}
.el-radio-button__inner [class*=el-icon-] {
  line-height: 0.9;
}
.el-radio-button__inner [class*=el-icon-] + span {
  margin-left: 5px;
}

.el-radio-button:first-child .el-radio-button__inner {
  border-left: var(--el-border);
  border-radius: var(--el-border-radius-base) 0 0 var(--el-border-radius-base);
  box-shadow: none !important;
}
.el-radio-button__original-radio {
  opacity: 0;
  outline: none;
  position: absolute;
  z-index: -1;
}
.el-radio-button__original-radio:checked + .el-radio-button__inner {
  color: var(--el-radio-button-checked-text-color, var(--el-color-white));
  background-color: var(--el-radio-button-checked-bg-color, var(--el-color-primary));
  border-color: var(--el-radio-button-checked-border-color, var(--el-color-primary));
  box-shadow: -1px 0 0 0 var(--el-radio-button-checked-border-color, var(--el-color-primary));
}
.el-radio-button__original-radio:disabled + .el-radio-button__inner {
  color: var(--el-disabled-text-color);
  cursor: not-allowed;
  background-image: none;
  background-color: var(--el-button-disabled-bg-color, var(--el-fill-color-blank));
  border-color: var(--el-button-disabled-border-color, var(--el-border-color-light));
  box-shadow: none;
}
.el-radio-button__original-radio:disabled:checked + .el-radio-button__inner {
  background-color: var(--el-radio-button-disabled-checked-fill);
}

.el-radio-button:last-child .el-radio-button__inner {
  border-radius: 0 var(--el-border-radius-base) var(--el-border-radius-base) 0;
}
.el-radio-button:first-child:last-child .el-radio-button__inner {
  border-radius: var(--el-border-radius-base);
}
.el-radio-button--large .el-radio-button__inner {
  padding: 12px 19px;
  font-size: 12px;
  border-radius: 0;
}
.el-radio-button--large .el-radio-button__inner.is-round {
  padding: 12px 19px;
}

.el-radio-button--small .el-radio-button__inner {
  padding: 5px 11px;
  font-size: 10px;
  border-radius: 0;
}
.el-radio-button--small .el-radio-button__inner.is-round {
  padding: 5px 11px;
}

.el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
  /*获得焦点时 样式提醒*/
  box-shadow: 0 0 2px 2px var(--el-radio-button-checked-border-color);
}

.el-radio-group {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 0;
}

.el-date-table {
  font-size: 12px;
  user-select: none;
}
.el-date-table.is-week-mode .el-date-table__row:hover .el-date-table-cell {
  background-color: var(--el-datepicker-inrange-bg-color);
}
.el-date-table.is-week-mode .el-date-table__row:hover td.available:hover {
  color: var(--el-datepicker-text-color);
}
.el-date-table.is-week-mode .el-date-table__row:hover td:first-child .el-date-table-cell {
  margin-left: 5px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.el-date-table.is-week-mode .el-date-table__row:hover td:last-child .el-date-table-cell {
  margin-right: 5px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.el-date-table.is-week-mode .el-date-table__row.current .el-date-table-cell {
  background-color: var(--el-datepicker-inrange-bg-color);
}

.el-date-table td {
  width: 32px;
  height: 30px;
  padding: 4px 0;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  position: relative;
}
.el-date-table td .el-date-table-cell {
  height: 30px;
  padding: 3px 0;
  box-sizing: border-box;
}
.el-date-table td .el-date-table-cell .el-date-table-cell__text {
  width: 24px;
  height: 24px;
  display: block;
  margin: 0 auto;
  line-height: 24px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
}
.el-date-table td.next-month, .el-date-table td.prev-month {
  color: var(--el-datepicker-off-text-color);
}
.el-date-table td.today {
  position: relative;
}
.el-date-table td.today .el-date-table-cell__text {
  color: var(--el-color-primary);
  font-weight: bold;
}
.el-date-table td.today.start-date .el-date-table-cell__text, .el-date-table td.today.end-date .el-date-table-cell__text {
  color: #ffffff;
}
.el-date-table td.available:hover {
  color: var(--el-datepicker-hover-text-color);
}
.el-date-table td.in-range .el-date-table-cell {
  background-color: var(--el-datepicker-inrange-bg-color);
}
.el-date-table td.in-range .el-date-table-cell:hover {
  background-color: var(--el-datepicker-inrange-hover-bg-color);
}
.el-date-table td.current:not(.disabled) .el-date-table-cell__text {
  color: #ffffff;
  background-color: var(--el-datepicker-active-color);
}
.el-date-table td.start-date .el-date-table-cell, .el-date-table td.end-date .el-date-table-cell {
  color: #ffffff;
}
.el-date-table td.start-date .el-date-table-cell__text, .el-date-table td.end-date .el-date-table-cell__text {
  background-color: var(--el-datepicker-active-color);
}
.el-date-table td.start-date .el-date-table-cell {
  margin-left: 5px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.el-date-table td.end-date .el-date-table-cell {
  margin-right: 5px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.el-date-table td.disabled .el-date-table-cell {
  background-color: var(--el-fill-color-light);
  opacity: 1;
  cursor: not-allowed;
  color: var(--el-text-color-placeholder);
}
.el-date-table td.selected .el-date-table-cell {
  margin-left: 5px;
  margin-right: 5px;
  background-color: var(--el-datepicker-inrange-bg-color);
  border-radius: 15px;
}
.el-date-table td.selected .el-date-table-cell:hover {
  background-color: var(--el-datepicker-inrange-hover-bg-color);
}
.el-date-table td.selected .el-date-table-cell__text {
  background-color: var(--el-datepicker-active-color);
  color: #ffffff;
  border-radius: 15px;
}
.el-date-table td.week {
  font-size: 80%;
  color: var(--el-datepicker-header-text-color);
}
.el-date-table th {
  padding: 5px;
  color: var(--el-datepicker-header-text-color);
  font-weight: 400;
  border-bottom: solid 1px var(--el-border-color-lighter);
}

.el-month-table {
  font-size: 12px;
  margin: -1px;
  border-collapse: collapse;
}
.el-month-table td {
  text-align: center;
  padding: 8px 0;
  cursor: pointer;
}
.el-month-table td div {
  height: 48px;
  padding: 6px 0;
  box-sizing: border-box;
}
.el-month-table td.today .cell {
  color: var(--el-color-primary);
  font-weight: bold;
}
.el-month-table td.today.start-date .cell, .el-month-table td.today.end-date .cell {
  color: #ffffff;
}
.el-month-table td.disabled .cell {
  background-color: var(--el-fill-color-light);
  cursor: not-allowed;
  color: var(--el-text-color-placeholder);
}
.el-month-table td.disabled .cell:hover {
  color: var(--el-text-color-placeholder);
}
.el-month-table td .cell {
  width: 60px;
  height: 36px;
  display: block;
  line-height: 36px;
  color: var(--el-datepicker-text-color);
  margin: 0 auto;
  border-radius: 18px;
}
.el-month-table td .cell:hover {
  color: var(--el-datepicker-hover-text-color);
}
.el-month-table td.in-range div {
  background-color: var(--el-datepicker-inrange-bg-color);
}
.el-month-table td.in-range div:hover {
  background-color: var(--el-datepicker-inrange-hover-bg-color);
}
.el-month-table td.start-date div, .el-month-table td.end-date div {
  color: #ffffff;
}
.el-month-table td.start-date .cell, .el-month-table td.end-date .cell {
  color: #ffffff;
  background-color: var(--el-datepicker-active-color);
}
.el-month-table td.start-date div {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}
.el-month-table td.end-date div {
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}
.el-month-table td.current:not(.disabled) .cell {
  color: var(--el-datepicker-active-color);
}

.el-year-table {
  font-size: 12px;
  margin: -1px;
  border-collapse: collapse;
}
.el-year-table .el-icon {
  color: var(--el-datepicker-icon-color);
}
.el-year-table td {
  text-align: center;
  padding: 20px 3px;
  cursor: pointer;
}
.el-year-table td.today .cell {
  color: var(--el-color-primary);
  font-weight: bold;
}
.el-year-table td.disabled .cell {
  background-color: var(--el-fill-color-light);
  cursor: not-allowed;
  color: var(--el-text-color-placeholder);
}
.el-year-table td.disabled .cell:hover {
  color: var(--el-text-color-placeholder);
}
.el-year-table td .cell {
  width: 48px;
  height: 32px;
  display: block;
  line-height: 32px;
  color: var(--el-datepicker-text-color);
  margin: 0 auto;
}
.el-year-table td .cell:hover {
  color: var(--el-datepicker-hover-text-color);
}
.el-year-table td.current:not(.disabled) .cell {
  color: var(--el-datepicker-active-color);
}

.el-time-spinner.has-seconds .el-time-spinner__wrapper {
  width: 33.3%;
}
.el-time-spinner__wrapper {
  max-height: 192px;
  overflow: auto;
  display: inline-block;
  width: 50%;
  vertical-align: top;
  position: relative;
}
.el-time-spinner__wrapper.el-scrollbar__wrap:not(.el-scrollbar__wrap--hidden-default) {
  padding-bottom: 15px;
}
.el-time-spinner__wrapper.is-arrow {
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
}
.el-time-spinner__wrapper.is-arrow .el-time-spinner__list {
  transform: translateY(-32px);
}
.el-time-spinner__wrapper.is-arrow .el-time-spinner__item:hover:not(.is-disabled):not(.is-active) {
  background: var(--el-fill-color-light);
  cursor: default;
}

.el-time-spinner__arrow {
  font-size: 12px;
  color: var(--el-text-color-secondary);
  position: absolute;
  left: 0;
  width: 100%;
  z-index: var(--el-index-normal);
  text-align: center;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
}
.el-time-spinner__arrow:hover {
  color: var(--el-color-primary);
}
.el-time-spinner__arrow.arrow-up {
  top: 10px;
}
.el-time-spinner__arrow.arrow-down {
  bottom: 10px;
}

.el-time-spinner__input.el-input {
  width: 70%;
}
.el-time-spinner__input.el-input .el-input__inner {
  padding: 0;
  text-align: center;
}

.el-time-spinner__list {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.el-time-spinner__list::after, .el-time-spinner__list::before {
  content: "";
  display: block;
  width: 100%;
  height: 80px;
}

.el-time-spinner__item {
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  color: var(--el-text-color-regular);
}
.el-time-spinner__item:hover:not(.is-disabled):not(.is-active) {
  background: var(--el-fill-color-light);
  cursor: pointer;
}
.el-time-spinner__item.is-active:not(.is-disabled) {
  color: var(--el-text-color-primary);
  font-weight: bold;
}
.el-time-spinner__item.is-disabled {
  color: var(--el-text-color-placeholder);
  cursor: not-allowed;
}

.el-picker__popper {
  --el-datepicker-border-color: var(--el-disabled-border-color);
}
.el-picker__popper.el-popper[role=tooltip] {
  background: var(--el-bg-color-overlay);
  border: 1px solid var(--el-datepicker-border-color);
  box-shadow: var(--el-box-shadow-light);
}
.el-picker__popper.el-popper[role=tooltip] .el-popper__arrow::before {
  border: 1px solid var(--el-datepicker-border-color);
}
.el-picker__popper.el-popper[role=tooltip][data-popper-placement^=top] .el-popper__arrow::before {
  border-top-color: transparent;
  border-left-color: transparent;
}
.el-picker__popper.el-popper[role=tooltip][data-popper-placement^=bottom] .el-popper__arrow::before {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.el-picker__popper.el-popper[role=tooltip][data-popper-placement^=left] .el-popper__arrow::before {
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.el-picker__popper.el-popper[role=tooltip][data-popper-placement^=right] .el-popper__arrow::before {
  border-right-color: transparent;
  border-top-color: transparent;
}

.el-date-editor {
  --el-date-editor-width: 100%;
  --el-date-editor-monthrange-width: 100%;
  --el-date-editor-daterange-width: 100%;
  --el-date-editor-datetimerange-width: 100%;
  --el-input-text-color: var(--el-text-color-regular);
  --el-input-border: var(--el-border);
  --el-input-hover-border: var(--el-border-color-hover);
  --el-input-focus-border: var(--el-color-primary);
  --el-input-transparent-border: 0 0 0 2px transparent inset;
  --el-input-border-color: var(--el-border-color);
  --el-input-border-radius: 3px;
  --el-input-bg-color: var(--el-fill-color-blank);
  --el-input-icon-color: var(--el-text-color-placeholder);
  --el-input-placeholder-color: var(--el-text-color-placeholder);
  --el-input-hover-border-color: var(--el-border-color-hover);
  --el-input-clear-hover-color: var(--el-text-color-secondary);
  --el-input-focus-border-color: var(--el-color-primary);
  position: relative;
  display: inline-block;
  text-align: left;
}
.el-date-editor.el-input__inner {
  border-radius: var(--el-input-border-radius, var(--el-border-radius-base));
  box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color)) inset;
}
.el-date-editor.el-input__inner:hover {
  box-shadow: 0 0 0 1px var(--el-input-hover-border-color) inset;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: var(--el-date-editor-width);
}
.el-date-editor--monthrange.el-input, .el-date-editor--monthrange.el-input__inner {
  width: var(--el-date-editor-monthrange-width);
}

.el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
  width: var(--el-date-editor-daterange-width);
}

.el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner {
  width: var(--el-date-editor-datetimerange-width);
}

.el-date-editor--dates .el-input__inner {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-date-editor .close-icon {
  cursor: pointer;
}
.el-date-editor .clear-icon {
  cursor: pointer;
}
.el-date-editor .clear-icon:hover {
  color: var(--el-text-color-secondary);
}
.el-date-editor .el-range__icon {
  height: inherit;
  font-size: 14px;
  color: var(--el-text-color-placeholder);
  float: left;
}
.el-date-editor .el-range__icon svg {
  vertical-align: middle;
}
.el-date-editor .el-range-input {
  appearance: none;
  border: none;
  outline: none;
  display: inline-block;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 39%;
  text-align: center;
  font-size: var(--el-font-size-base);
  color: var(--el-text-color-regular);
  background-color: transparent;
}
.el-date-editor .el-range-input::placeholder {
  color: var(--el-text-color-placeholder);
}
.el-date-editor .el-range-separator {
  flex: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 5px;
  margin: 0;
  font-size: 14px;
  word-break: keep-all;
  color: var(--el-text-color-primary);
}
.el-date-editor .el-range__close-icon {
  font-size: 14px;
  color: var(--el-text-color-placeholder);
  height: inherit;
  width: unset;
  cursor: pointer;
}
.el-date-editor .el-range__close-icon:hover {
  color: var(--el-text-color-secondary);
}
.el-date-editor .el-range__close-icon svg {
  vertical-align: middle;
}
.el-date-editor .el-range__close-icon--hidden {
  opacity: 0;
  visibility: hidden;
}

.el-range-editor.el-input__inner {
  display: inline-flex;
  align-items: center;
  padding: 3px 10px;
}
.el-range-editor .el-range-input {
  line-height: 1;
}
.el-range-editor.is-active {
  box-shadow: 0 0 0 1px var(--el-input-focus-border-color) inset;
}
.el-range-editor.is-active:hover {
  box-shadow: 0 0 0 1px var(--el-input-focus-border-color) inset;
}

.el-range-editor--large {
  line-height: 40px;
}
.el-range-editor--large.el-input__inner {
  height: 40px;
}
.el-range-editor--large .el-range-separator {
  line-height: 40px;
  font-size: 12px;
}
.el-range-editor--large .el-range-input {
  font-size: 12px;
}

.el-range-editor--small {
  line-height: 24px;
}
.el-range-editor--small.el-input__inner {
  height: 24px;
}
.el-range-editor--small .el-range-separator {
  line-height: 24px;
  font-size: 10px;
}
.el-range-editor--small .el-range-input {
  font-size: 10px;
}

.el-range-editor.is-disabled {
  background-color: var(--el-disabled-bg-color);
  border-color: var(--el-disabled-border-color);
  color: var(--el-disabled-text-color);
  cursor: not-allowed;
}
.el-range-editor.is-disabled:hover, .el-range-editor.is-disabled:focus {
  border-color: var(--el-disabled-border-color);
}
.el-range-editor.is-disabled input {
  background-color: var(--el-disabled-bg-color);
  color: var(--el-disabled-text-color);
  cursor: not-allowed;
}
.el-range-editor.is-disabled input::placeholder {
  color: var(--el-text-color-placeholder);
}
.el-range-editor.is-disabled .el-range-separator {
  color: var(--el-disabled-text-color);
}

.el-picker-panel {
  color: var(--el-text-color-regular);
  background: var(--el-bg-color-overlay);
  border-radius: var(--el-border-radius-base);
  line-height: 30px;
}
.el-picker-panel .el-time-panel {
  margin: 5px 0;
  border: solid 1px var(--el-datepicker-border-color);
  background-color: var(--el-color-white);
  box-shadow: var(--el-box-shadow-light);
}
.el-picker-panel__body::after, .el-picker-panel__body-wrapper::after {
  content: "";
  display: table;
  clear: both;
}

.el-picker-panel__content {
  position: relative;
  margin: 15px;
}

.el-picker-panel__footer {
  border-top: 1px solid var(--el-datepicker-inner-border-color);
  padding: 4px 12px;
  text-align: right;
  background-color: var(--el-bg-color-overlay);
  position: relative;
  font-size: 0;
}

.el-picker-panel__shortcut {
  display: block;
  width: 100%;
  border: 0;
  background-color: transparent;
  line-height: 28px;
  font-size: 14px;
  color: var(--el-datepicker-text-color);
  padding-left: 12px;
  text-align: left;
  outline: none;
  cursor: pointer;
}
.el-picker-panel__shortcut:hover {
  color: var(--el-datepicker-hover-text-color);
}
.el-picker-panel__shortcut.active {
  background-color: #e6f1fe;
  color: var(--el-datepicker-active-color);
}

.el-picker-panel__btn {
  border: 1px solid var(--el-fill-color-darker);
  color: var(--el-text-color-primary);
  line-height: 24px;
  border-radius: 2px;
  padding: 0 20px;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  font-size: 12px;
}
.el-picker-panel__btn[disabled] {
  color: var(--el-text-color-disabled);
  cursor: not-allowed;
}

.el-picker-panel__icon-btn {
  font-size: 12px;
  color: var(--el-datepicker-icon-color);
  border: 0;
  background: transparent;
  cursor: pointer;
  outline: none;
  margin-top: 8px;
}
.el-picker-panel__icon-btn:hover {
  color: var(--el-datepicker-hover-text-color);
}
.el-picker-panel__icon-btn.is-disabled {
  color: var(--el-text-color-disabled);
}
.el-picker-panel__icon-btn.is-disabled:hover {
  cursor: not-allowed;
}

.el-picker-panel__icon-btn .el-icon {
  cursor: pointer;
  font-size: inherit;
}

.el-picker-panel__link-btn {
  vertical-align: middle;
}

.el-picker-panel *[slot=sidebar],
.el-picker-panel__sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 110px;
  border-right: 1px solid var(--el-datepicker-inner-border-color);
  box-sizing: border-box;
  padding-top: 6px;
  background-color: var(--el-bg-color-overlay);
  overflow: auto;
}

.el-picker-panel *[slot=sidebar] + .el-picker-panel__body,
.el-picker-panel__sidebar + .el-picker-panel__body {
  margin-left: 110px;
}

.el-date-picker {
  --el-datepicker-text-color: var(--el-text-color-regular);
  --el-datepicker-off-text-color: var(--el-text-color-placeholder);
  --el-datepicker-header-text-color: var(--el-text-color-regular);
  --el-datepicker-icon-color: var(--el-text-color-primary);
  --el-datepicker-border-color: var(--el-disabled-border-color);
  --el-datepicker-inner-border-color: var(--el-border-color-light);
  --el-datepicker-inrange-bg-color: var(--el-border-color-extra-light);
  --el-datepicker-inrange-hover-bg-color: var(--el-border-color-extra-light);
  --el-datepicker-active-color: var(--el-color-primary);
  --el-datepicker-hover-text-color: var(--el-color-primary);
}

.el-date-picker {
  width: 322px;
}
.el-date-picker.has-sidebar.has-time {
  width: 434px;
}
.el-date-picker.has-sidebar {
  width: 438px;
}
.el-date-picker.has-time .el-picker-panel__body-wrapper {
  position: relative;
}
.el-date-picker .el-picker-panel__content {
  width: 292px;
}
.el-date-picker table {
  table-layout: fixed;
  width: 100%;
}
.el-date-picker__editor-wrap {
  position: relative;
  display: table-cell;
  padding: 0 5px;
}

.el-date-picker__time-header {
  position: relative;
  border-bottom: 1px solid var(--el-datepicker-inner-border-color);
  font-size: 12px;
  padding: 8px 5px 5px;
  display: table;
  width: 100%;
  box-sizing: border-box;
}

.el-date-picker__header {
  margin: 12px;
  text-align: center;
}
.el-date-picker__header--bordered {
  margin-bottom: 0;
  padding-bottom: 12px;
  border-bottom: solid 1px var(--el-border-color-lighter);
}
.el-date-picker__header--bordered + .el-picker-panel__content {
  margin-top: 0;
}

.el-date-picker__header-label {
  font-size: 16px;
  font-weight: 500;
  padding: 0 5px;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
  color: var(--el-text-color-regular);
}
.el-date-picker__header-label:hover {
  color: var(--el-datepicker-hover-text-color);
}
.el-date-picker__header-label.active {
  color: var(--el-datepicker-active-color);
}

.el-date-picker__prev-btn {
  float: left;
}

.el-date-picker__next-btn {
  float: right;
}

.el-date-picker__time-wrap {
  padding: 10px;
  text-align: center;
}

.el-date-picker__time-label {
  float: left;
  cursor: pointer;
  line-height: 30px;
  margin-left: 10px;
}

.el-date-picker .el-time-panel {
  position: absolute;
}

.el-date-range-picker {
  --el-datepicker-text-color: var(--el-text-color-regular);
  --el-datepicker-off-text-color: var(--el-text-color-placeholder);
  --el-datepicker-header-text-color: var(--el-text-color-regular);
  --el-datepicker-icon-color: var(--el-text-color-primary);
  --el-datepicker-border-color: var(--el-disabled-border-color);
  --el-datepicker-inner-border-color: var(--el-border-color-light);
  --el-datepicker-inrange-bg-color: var(--el-border-color-extra-light);
  --el-datepicker-inrange-hover-bg-color: var(--el-border-color-extra-light);
  --el-datepicker-active-color: var(--el-color-primary);
  --el-datepicker-hover-text-color: var(--el-color-primary);
}

.el-date-range-picker {
  width: 646px;
}
.el-date-range-picker.has-sidebar {
  width: 756px;
}
.el-date-range-picker table {
  table-layout: fixed;
  width: 100%;
}
.el-date-range-picker .el-picker-panel__body {
  min-width: 513px;
}
.el-date-range-picker .el-picker-panel__content {
  margin: 0;
}
.el-date-range-picker__header {
  position: relative;
  text-align: center;
  height: 28px;
}
.el-date-range-picker__header [class*=arrow-left] {
  float: left;
}
.el-date-range-picker__header [class*=arrow-right] {
  float: right;
}
.el-date-range-picker__header div {
  font-size: 16px;
  font-weight: 500;
  margin-right: 50px;
}

.el-date-range-picker__content {
  float: left;
  width: 50%;
  box-sizing: border-box;
  margin: 0;
  padding: 16px;
}
.el-date-range-picker__content.is-left {
  border-right: 1px solid var(--el-datepicker-inner-border-color);
}

.el-date-range-picker__content .el-date-range-picker__header div {
  margin-left: 50px;
  margin-right: 50px;
}

.el-date-range-picker__editors-wrap {
  box-sizing: border-box;
  display: table-cell;
}
.el-date-range-picker__editors-wrap.is-right {
  text-align: right;
}

.el-date-range-picker__time-header {
  position: relative;
  border-bottom: 1px solid var(--el-datepicker-inner-border-color);
  font-size: 12px;
  padding: 8px 5px 5px 5px;
  display: table;
  width: 100%;
  box-sizing: border-box;
}
.el-date-range-picker__time-header > .el-icon-arrow-right {
  font-size: 20px;
  vertical-align: middle;
  display: table-cell;
  color: var(--el-datepicker-icon-color);
}

.el-date-range-picker__time-picker-wrap {
  position: relative;
  display: table-cell;
  padding: 0 5px;
}
.el-date-range-picker__time-picker-wrap .el-picker-panel {
  position: absolute;
  top: 13px;
  right: 0;
  z-index: 1;
  background: #ffffff;
}
.el-date-range-picker__time-picker-wrap .el-time-panel {
  position: absolute;
}

.el-time-range-picker {
  width: 354px;
  overflow: visible;
}
.el-time-range-picker__content {
  position: relative;
  text-align: center;
  padding: 10px;
  z-index: 1;
}

.el-time-range-picker__cell {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 7px 7px;
  width: 50%;
  display: inline-block;
}

.el-time-range-picker__header {
  margin-bottom: 5px;
  text-align: center;
  font-size: 14px;
}

.el-time-range-picker__body {
  border-radius: 2px;
  border: 1px solid var(--el-datepicker-border-color);
}

.el-time-panel {
  border-radius: 2px;
  position: relative;
  width: 180px;
  left: 0;
  z-index: var(--el-index-top);
  user-select: none;
  box-sizing: content-box;
}
.el-time-panel__content {
  font-size: 0;
  position: relative;
  overflow: hidden;
}
.el-time-panel__content::after, .el-time-panel__content::before {
  content: "";
  top: 50%;
  position: absolute;
  margin-top: -16px;
  height: 32px;
  z-index: -1;
  left: 0;
  right: 0;
  box-sizing: border-box;
  padding-top: 6px;
  text-align: left;
  border-top: 1px solid var(--el-border-color-light);
  border-bottom: 1px solid var(--el-border-color-light);
}
.el-time-panel__content::after {
  left: 50%;
  margin-left: 12%;
  margin-right: 12%;
}
.el-time-panel__content::before {
  padding-left: 50%;
  margin-right: 12%;
  margin-left: 12%;
}
.el-time-panel__content.has-seconds::after {
  left: 66.6666666667%;
}
.el-time-panel__content.has-seconds::before {
  padding-left: 33.3333333333%;
}

.el-time-panel__footer {
  border-top: 1px solid var(--el-timepicker-inner-border-color, var(--el-border-color-light));
  padding: 4px;
  height: 36px;
  line-height: 25px;
  text-align: right;
  box-sizing: border-box;
}

.el-time-panel__btn {
  border: none;
  line-height: 28px;
  padding: 0 5px;
  margin: 0 5px;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  font-size: 12px;
  color: var(--el-text-color-primary);
}
.el-time-panel__btn.confirm {
  font-weight: 800;
  color: var(--el-timepicker-active-color, var(--el-color-primary));
}

.time-select {
  margin: 5px 0;
  min-width: 0;
}
.time-select .el-picker-panel__content {
  max-height: 200px;
  margin: 0;
}
.time-select-item {
  padding: 8px 10px;
  font-size: 14px;
  line-height: 20px;
}
.time-select-item.disabled {
  color: var(--el-datepicker-border-color);
  cursor: not-allowed;
}
.time-select-item:hover {
  background-color: var(--el-fill-color-light);
  font-weight: bold;
  cursor: pointer;
}
.time-select .time-select-item.selected:not(.disabled) {
  color: var(--el-color-primary);
  font-weight: bold;
}

.el-pagination {
  --el-pagination-font-size: 14px;
  --el-pagination-bg-color: var(--el-fill-color-blank);
  --el-pagination-text-color: var(--el-text-color-primary);
  --el-pagination-border-radius: 3px;
  --el-pagination-button-color: var(--el-text-color-primary);
  --el-pagination-button-width: 32px;
  --el-pagination-button-height: 32px;
  --el-pagination-button-disabled-color: var(--el-text-color-placeholder);
  --el-pagination-button-disabled-bg-color: var(--el-fill-color-blank);
  --el-pagination-button-bg-color: var(--el-fill-color);
  --el-pagination-hover-color: var(--el-color-primary);
  --el-pagination-height-extra-small: 24px;
  --el-pagination-line-height-extra-small: var(--el-pagination-height-extra-small);
  white-space: nowrap;
  padding: 2px 5px;
  color: var(--el-pagination-text-color);
  font-weight: normal;
  display: flex;
  align-items: center;
}
.el-pagination::before,
.el-pagination::after {
  display: table;
  content: "";
}

.el-pagination::after {
  clear: both;
}

.el-pagination span:not([class*=suffix]),
.el-pagination button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--el-pagination-font-size);
  min-width: var(--el-pagination-button-width);
  height: var(--el-pagination-button-height);
  line-height: var(--el-pagination-button-height);
  box-sizing: border-box;
}
.el-pagination .el-input__inner {
  text-align: center;
  -moz-appearance: textfield;
  line-height: normal;
}
.el-pagination .el-select .el-input {
  width: 128px;
}
.el-pagination button {
  border: none;
  padding: 0 6px;
  background: transparent;
}
.el-pagination button:focus {
  outline: none;
}
.el-pagination button:hover {
  color: var(--el-pagination-hover-color);
}
.el-pagination button:disabled {
  color: var(--el-pagination-button-disabled-color);
  background-color: var(--el-pagination-button-disabled-bg-color);
  cursor: not-allowed;
}
.el-pagination .btn-prev,
.el-pagination .btn-next {
  background: center center no-repeat;
  background-size: 16px;
  background-color: var(--el-pagination-bg-color);
  cursor: pointer;
  margin: 0;
  color: var(--el-pagination-button-color);
}
.el-pagination .btn-prev .el-icon,
.el-pagination .btn-next .el-icon {
  display: block;
  font-size: 12px;
  font-weight: bold;
  width: inherit;
}
.el-pagination .el-pager li.is-disabled {
  color: var(--el-text-color-placeholder);
  cursor: not-allowed;
}
.el-pagination--small .btn-prev,
.el-pagination--small .btn-next,
.el-pagination--small .el-pager li,
.el-pagination--small .el-pager li.btn-quicknext,
.el-pagination--small .el-pager li.btn-quickprev,
.el-pagination--small .el-pager li:last-child {
  border-color: transparent;
  font-size: var(--el-font-size-extra-small);
  line-height: var(--el-pagination-line-height-extra-small);
  height: var(--el-pagination-height-extra-small);
  min-width: 24px;
}
.el-pagination--small .arrow.is-disabled {
  visibility: hidden;
}
.el-pagination--small .more::before,
.el-pagination--small li.more::before {
  line-height: var(--el-pagination-line-height-extra-small);
}
.el-pagination--small span:not([class*=suffix]),
.el-pagination--small button {
  height: var(--el-pagination-height-extra-small);
  line-height: var(--el-pagination-line-height-extra-small);
  font-size: var(--el-font-size-extra-small);
}
.el-pagination--small .el-pagination__editor {
  height: var(--el-pagination-line-height-extra-small);
}
.el-pagination--small .el-pagination__editor.el-input .el-input__inner {
  height: var(--el-pagination-height-extra-small);
}

.el-pagination--small .el-input__inner,
.el-pagination--small .el-input--small {
  height: var(--el-pagination-height-extra-small) !important;
  line-height: var(--el-pagination-line-height-extra-small);
}
.el-pagination--small .el-input__suffix {
  line-height: var(--el-pagination-line-height-extra-small);
}
.el-pagination--small .el-input__suffix .el-input__suffix-inner {
  line-height: var(--el-pagination-line-height-extra-small);
}
.el-pagination--small .el-input__suffix .el-input__suffix-inner i.el-select__caret {
  line-height: var(--el-pagination-line-height-extra-small);
}
.el-pagination--small .el-select .el-input {
  width: 100px;
}

.el-pagination__sizes {
  margin: 0 16px 0 0;
  font-weight: normal;
  color: var(--el-text-color-regular);
}
.el-pagination__sizes + button.btn-prev[type=button] {
  margin-left: 0;
}
.el-pagination__sizes + .el-pager .number:first-child {
  margin-left: 0;
}
.el-pagination__sizes + .el-pager .number:last-child {
  margin-right: 0;
}

.el-pagination__total {
  margin-right: 16px;
  font-weight: normal;
  color: var(--el-text-color-regular);
}
.el-pagination__total + button.btn-prev[type=button] {
  margin-left: 0;
}
.el-pagination__total + .el-pager .number:first-child {
  margin-left: 0;
}
.el-pagination__total + .el-pager .number:last-child {
  margin-right: 0;
}
.el-pagination__total[disabled=true] {
  color: var(--el-text-color-placeholder);
}

.el-pagination__jump {
  margin-left: 16px;
  font-weight: normal;
  color: var(--el-text-color-regular);
}
.el-pagination__jump .el-input__inner {
  padding: 0 3px;
}
.el-pagination__jump[disabled=true] {
  color: var(--el-text-color-placeholder);
}

.el-pagination__rightwrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.el-pagination__editor {
  line-height: 18px;
  margin: 0 8px;
  height: var(--el-pagination-button-height);
  min-width: 56px;
  text-align: center;
  box-sizing: border-box;
  border-radius: var(--el-pagination-border-radius);
}
.el-pagination__editor.el-input {
  width: 50px;
}
.el-pagination__editor.el-input .el-input__inner {
  height: var(--el-pagination-button-height);
}
.el-pagination__editor .el-input__inner::-webkit-inner-spin-button,
.el-pagination__editor .el-input__inner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.el-pagination.is-background .btn-prev,
.el-pagination.is-background .btn-next,
.el-pagination.is-background .el-pager li {
  margin: 0 4px;
  background-color: var(--el-pagination-button-bg-color);
  color: var(--el-text-color-regular);
  min-width: 32px;
  border-radius: 2px;
}
.el-pagination.is-background .btn-prev.is-disabled,
.el-pagination.is-background .btn-next.is-disabled,
.el-pagination.is-background .el-pager li.is-disabled {
  color: var(--el-text-color-placeholder);
  background-color: var(--el-disabled-bg-color);
}
.el-pagination.is-background .btn-prev.is-first,
.el-pagination.is-background .btn-next.is-first,
.el-pagination.is-background .el-pager li.is-first {
  margin-left: 0;
}

.el-pagination.is-background .btn-prev.is-last,
.el-pagination.is-background .btn-next.is-last,
.el-pagination.is-background .el-pager li.is-last {
  margin-right: 0;
}

.el-pagination.is-background .btn-prev,
.el-pagination.is-background .btn-next {
  padding: 0;
}
.el-pagination.is-background .btn-prev:disabled,
.el-pagination.is-background .btn-next:disabled {
  color: var(--el-text-color-placeholder);
  background-color: var(--el-disabled-bg-color);
}
.el-pagination.is-background .btn-prev:hover:not([disabled]),
.el-pagination.is-background .btn-next:hover:not([disabled]) {
  color: var(--el-pagination-hover-color);
}
.el-pagination.is-background .el-pager li:not(.is-disabled):hover {
  color: var(--el-pagination-hover-color);
}
.el-pagination.is-background .el-pager li:not(.is-disabled).is-active {
  background-color: var(--el-color-primary);
  color: var(--el-color-white);
  font-weight: bold;
}
.el-pagination.is-background.el-pagination--small .btn-prev,
.el-pagination.is-background.el-pagination--small .btn-next,
.el-pagination.is-background.el-pagination--small .el-pager li {
  min-width: 24px;
}

.el-pager {
  user-select: none;
  list-style: none;
  font-size: 0;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}
.el-pager li {
  padding: 0 4px;
  background: var(--el-pagination-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--el-pagination-font-size);
  min-width: var(--el-pagination-button-width);
  height: var(--el-pagination-button-height);
  line-height: var(--el-pagination-button-height);
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
}
.el-pager li.btn-quickprev:hover {
  cursor: pointer;
}
.el-pager li.btn-quicknext:hover {
  cursor: pointer;
}
.el-pager li.btn-quicknext, .el-pager li.btn-quickprev {
  line-height: 32px;
  color: var(--el-pagination-button-color);
}
.el-pager li.btn-quicknext.is-disabled, .el-pager li.btn-quickprev.is-disabled {
  color: var(--el-text-color-placeholder);
  cursor: not-allowed;
}
.el-pager li.btn-quicknext svg, .el-pager li.btn-quickprev svg {
  pointer-events: none;
}
.el-pager li.is-active + li {
  border-left: 0;
}
.el-pager li:focus-visible {
  outline: 1px solid var(--el-pagination-hover-color);
}
.el-pager li:hover {
  color: var(--el-pagination-hover-color);
}
.el-pager li.is-active {
  color: var(--el-pagination-hover-color);
  cursor: default;
}
.el-pager + button.btn-next[type=button] {
  margin-right: 0;
}

.el-scrollbar {
  --el-scrollbar-opacity: 0.3;
  --el-scrollbar-bg-color: var(--el-text-color-secondary);
  --el-scrollbar-hover-opacity: 0.5;
  --el-scrollbar-hover-bg-color: var(--el-text-color-secondary);
}

.el-scrollbar {
  overflow: hidden;
  position: relative;
  height: 100%;
}
.el-scrollbar__wrap {
  overflow: auto;
  height: 100%;
}
.el-scrollbar__wrap--hidden-default {
  scrollbar-width: none;
}
.el-scrollbar__wrap--hidden-default::-webkit-scrollbar {
  display: none;
}

.el-scrollbar__thumb {
  position: relative;
  display: block;
  width: 0;
  height: 0;
  cursor: pointer;
  border-radius: inherit;
  background-color: var(--el-scrollbar-bg-color, var(--el-text-color-secondary));
  transition: var(--el-transition-duration) background-color;
  opacity: var(--el-scrollbar-opacity, 0.3);
}
.el-scrollbar__thumb:hover {
  background-color: var(--el-scrollbar-hover-bg-color, var(--el-text-color-secondary));
  opacity: var(--el-scrollbar-hover-opacity, 0.5);
}

.el-scrollbar__bar {
  position: absolute;
  right: 2px;
  bottom: 2px;
  z-index: 1;
  border-radius: 4px;
}
.el-scrollbar__bar.is-vertical {
  width: 6px;
  top: 2px;
}
.el-scrollbar__bar.is-vertical > div {
  width: 100%;
}

.el-scrollbar__bar.is-horizontal {
  height: 6px;
  left: 2px;
}
.el-scrollbar__bar.is-horizontal > div {
  height: 100%;
}

.el-scrollbar-fade-enter-active {
  transition: opacity 340ms ease-out;
}
.el-scrollbar-fade-leave-active {
  transition: opacity 120ms ease-out;
}
.el-scrollbar-fade-enter-from, .el-scrollbar-fade-leave-active {
  opacity: 0;
}

.el-popover {
  --el-popover-bg-color: var(--el-color-white);
  --el-popover-font-size: var(--el-font-size-base);
  --el-popover-border-color: var(--el-border-color-lighter);
  --el-popover-padding: 12px;
  --el-popover-padding-large: 18px 20px;
  --el-popover-title-font-size: 16px;
  --el-popover-title-text-color: var(--el-text-color-primary);
  --el-popover-border-radius: 4px;
}
.el-popover.el-popper {
  background: var(--el-popover-bg-color);
  min-width: 150px;
  border-radius: var(--el-popover-border-radius);
  border: 1px solid var(--el-popover-border-color);
  padding: var(--el-popover-padding);
  z-index: var(--el-index-popper);
  color: var(--el-text-color-regular);
  line-height: 1.4;
  text-align: justify;
  font-size: var(--el-popover-font-size);
  box-shadow: var(--el-box-shadow-light);
  word-break: break-all;
}
.el-popover.el-popper--plain {
  padding: var(--el-popover-padding-large);
}

.el-popover__title {
  color: var(--el-popover-title-text-color);
  font-size: var(--el-popover-title-font-size);
  line-height: 1;
  margin-bottom: 12px;
}

.el-popover__reference:focus:not(.focusing), .el-popover__reference:focus:hover {
  outline-width: 0;
}

.el-popover.el-popper:focus:active, .el-popover.el-popper:focus {
  outline-width: 0;
}

.el-checkbox {
  --el-checkbox-font-size: 14px;
  --el-checkbox-font-weight: var(--el-font-weight-primary);
  --el-checkbox-text-color: var(--el-text-color-regular);
  --el-checkbox-input-height: 14px;
  --el-checkbox-input-width: 14px;
  --el-checkbox-border-radius: var(--el-border-radius-small);
  --el-checkbox-bg-color: var(--el-fill-color-blank);
  --el-checkbox-input-border: var(--el-border);
  --el-checkbox-disabled-border-color: var(--el-border-color);
  --el-checkbox-disabled-input-fill: var(--el-fill-color-light);
  --el-checkbox-disabled-icon-color: var(--el-text-color-placeholder);
  --el-checkbox-disabled-checked-input-fill: var(--el-border-color-extra-light);
  --el-checkbox-disabled-checked-input-border-color: var(--el-border-color);
  --el-checkbox-disabled-checked-icon-color: var(--el-text-color-placeholder);
  --el-checkbox-checked-text-color: var(--el-color-primary);
  --el-checkbox-checked-input-border-color: var(--el-color-primary);
  --el-checkbox-checked-bg-color: var(--el-color-primary);
  --el-checkbox-checked-icon-color: var(--el-color-white);
  --el-checkbox-input-border-color-hover: var(--el-color-primary);
}

.el-checkbox {
  color: var(--el-checkbox-text-color);
  font-weight: var(--el-checkbox-font-weight);
  font-size: var(--el-font-size-base);
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  user-select: none;
  margin-right: 30px;
  height: 30px;
}
.el-checkbox.is-bordered {
  padding: 0 15px 0 9px;
  border-radius: var(--el-border-radius-base);
  border: var(--el-border);
  box-sizing: border-box;
}
.el-checkbox.is-bordered.is-checked {
  border-color: var(--el-color-primary);
}
.el-checkbox.is-bordered.is-disabled {
  border-color: var(--el-border-color-lighter);
  cursor: not-allowed;
}
.el-checkbox.is-bordered.el-checkbox--large {
  padding: 0 19px 0 11px;
  border-radius: 3px;
}
.el-checkbox.is-bordered.el-checkbox--large .el-checkbox__label {
  font-size: 12px;
}
.el-checkbox.is-bordered.el-checkbox--large .el-checkbox__inner {
  height: 14px;
  width: 14px;
}
.el-checkbox.is-bordered.el-checkbox--small {
  padding: 0 11px 0 7px;
  border-radius: 2px;
}
.el-checkbox.is-bordered.el-checkbox--small .el-checkbox__label {
  font-size: 10px;
}
.el-checkbox.is-bordered.el-checkbox--small .el-checkbox__inner {
  height: 12px;
  width: 12px;
}
.el-checkbox.is-bordered.el-checkbox--small .el-checkbox__inner::after {
  height: 6px;
  width: 2px;
}

.el-checkbox__input {
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  display: inline-flex;
  position: relative;
}
.el-checkbox__input.is-disabled .el-checkbox__inner {
  background-color: var(--el-checkbox-disabled-input-fill);
  border-color: var(--el-checkbox-disabled-border-color);
  cursor: not-allowed;
}
.el-checkbox__input.is-disabled .el-checkbox__inner::after {
  cursor: not-allowed;
  border-color: var(--el-checkbox-disabled-icon-color);
}
.el-checkbox__input.is-disabled .el-checkbox__inner + .el-checkbox__label {
  cursor: not-allowed;
}
.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: var(--el-checkbox-disabled-checked-input-fill);
  border-color: var(--el-checkbox-disabled-checked-input-border-color);
}
.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: var(--el-checkbox-disabled-checked-icon-color);
}
.el-checkbox__input.is-disabled.is-indeterminate .el-checkbox__inner {
  background-color: var(--el-checkbox-disabled-checked-input-fill);
  border-color: var(--el-checkbox-disabled-checked-input-border-color);
}
.el-checkbox__input.is-disabled.is-indeterminate .el-checkbox__inner::before {
  background-color: var(--el-checkbox-disabled-checked-icon-color);
  border-color: var(--el-checkbox-disabled-checked-icon-color);
}
.el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: var(--el-disabled-text-color);
  cursor: not-allowed;
}

.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: var(--el-checkbox-checked-bg-color);
  border-color: var(--el-checkbox-checked-input-border-color);
}
.el-checkbox__input.is-checked .el-checkbox__inner::after {
  transform: rotate(45deg) scaleY(1);
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: var(--el-checkbox-checked-text-color);
}

.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: var(--el-checkbox-input-border-color-hover);
}

.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: var(--el-checkbox-checked-bg-color);
  border-color: var(--el-checkbox-checked-input-border-color);
}
.el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
  content: "";
  position: absolute;
  display: block;
  background-color: var(--el-checkbox-checked-icon-color);
  height: 2px;
  transform: scale(0.5);
  left: 0;
  right: 0;
  top: 5px;
}
.el-checkbox__input.is-indeterminate .el-checkbox__inner::after {
  display: none;
}

.el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: var(--el-checkbox-input-border);
  border-radius: var(--el-checkbox-border-radius);
  box-sizing: border-box;
  width: var(--el-checkbox-input-width);
  height: var(--el-checkbox-input-height);
  background-color: var(--el-checkbox-bg-color);
  z-index: var(--el-index-normal);
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46), background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}
.el-checkbox__inner:hover {
  border-color: var(--el-checkbox-input-border-color-hover);
}
.el-checkbox__inner::after {
  box-sizing: content-box;
  content: "";
  border: 1px solid var(--el-checkbox-checked-icon-color);
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 4px;
  position: absolute;
  top: 1px;
  transform: rotate(45deg) scaleY(0);
  width: 3px;
  transition: transform 0.15s ease-in 0.05s;
  transform-origin: center;
}

.el-checkbox__original {
  opacity: 0;
  outline: none;
  position: absolute;
  margin: 0;
  width: 0;
  height: 0;
  z-index: -1;
}

.el-checkbox__label {
  display: inline-block;
  padding-left: 8px;
  line-height: 1;
  font-size: var(--el-checkbox-font-size);
}

.el-checkbox.el-checkbox--large {
  height: 40px;
}
.el-checkbox.el-checkbox--large .el-checkbox__label {
  font-size: 14px;
}

.el-checkbox.el-checkbox--large .el-checkbox__inner {
  width: 14px;
  height: 14px;
}

.el-checkbox.el-checkbox--small {
  height: 24px;
}
.el-checkbox.el-checkbox--small .el-checkbox__label {
  font-size: 12px;
}

.el-checkbox.el-checkbox--small .el-checkbox__inner {
  width: 12px;
  height: 12px;
}

.el-checkbox.el-checkbox--small .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
  top: 4px;
}

.el-checkbox.el-checkbox--small .el-checkbox__inner::after {
  width: 2px;
  height: 6px;
}

.el-checkbox:last-of-type {
  margin-right: 0;
}

.el-checkbox-group {
  font-size: 0;
  line-height: 0;
}

.el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0 0 15px;
}

.el-tabs__active-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: var(--el-color-primary);
  z-index: 1;
  transition: width var(--el-transition-duration) var(--el-transition-function-ease-in-out-bezier), transform var(--el-transition-duration) var(--el-transition-function-ease-in-out-bezier);
  list-style: none;
}

.el-tabs__new-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  border: 1px solid var(--el-border-color);
  height: 20px;
  width: 20px;
  line-height: 20px;
  margin: 10px 0 10px 10px;
  border-radius: 3px;
  text-align: center;
  font-size: 12px;
  color: var(--el-text-color-primary);
  cursor: pointer;
  transition: all 0.15s;
}
.el-tabs__new-tab .is-icon-plus {
  height: inherit;
  width: inherit;
  transform: scale(0.8, 0.8);
}
.el-tabs__new-tab .is-icon-plus svg {
  vertical-align: middle;
}
.el-tabs__new-tab:hover {
  color: var(--el-color-primary);
}

.el-tabs__nav-wrap {
  overflow: hidden;
  margin-bottom: -1px;
  position: relative;
}
.el-tabs__nav-wrap::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: var(--el-border-color-light);
  z-index: var(--el-index-normal);
}
.el-tabs__nav-wrap.is-scrollable {
  padding: 0 20px;
  box-sizing: border-box;
}

.el-tabs__nav-scroll {
  overflow: hidden;
}

.el-tabs__nav-next, .el-tabs__nav-prev {
  position: absolute;
  cursor: pointer;
  line-height: 44px;
  font-size: 12px;
  color: var(--el-text-color-secondary);
}

.el-tabs__nav-next {
  right: 0;
}

.el-tabs__nav-prev {
  left: 0;
}

.el-tabs__nav {
  white-space: nowrap;
  position: relative;
  transition: transform var(--el-transition-duration);
  float: left;
  z-index: calc(var(--el-index-normal) + 1);
}
.el-tabs__nav.is-stretch {
  min-width: 100%;
  display: flex;
}
.el-tabs__nav.is-stretch > * {
  flex: 1;
  text-align: center;
}

.el-tabs__item {
  padding: 0 20px;
  height: 40px;
  box-sizing: border-box;
  line-height: 40px;
  display: inline-block;
  list-style: none;
  font-size: 14px;
  font-weight: 500;
  color: var(--el-text-color-primary);
  position: relative;
}
.el-tabs__item:focus, .el-tabs__item:focus:active {
  outline: none;
}
.el-tabs__item .is-icon-close {
  border-radius: 50%;
  text-align: center;
  transition: all var(--el-transition-duration) var(--el-transition-function-ease-in-out-bezier);
  margin-left: 5px;
}
.el-tabs__item .is-icon-close:before {
  transform: scale(0.9);
  display: inline-block;
}
.el-tabs__item .is-icon-close:hover {
  background-color: var(--el-text-color-placeholder);
  color: #ffffff;
}
.el-tabs__item .is-icon-close svg {
  margin-top: 1px;
}
.el-tabs__item.is-active {
  color: var(--el-color-primary);
}

.el-tabs__item:hover {
  color: var(--el-color-primary);
  cursor: pointer;
}
.el-tabs__item.is-disabled {
  color: var(--el-disabled-text-color);
  cursor: default;
}

.el-tabs__content {
  overflow: hidden;
  position: relative;
}

.el-tabs--card > .el-tabs__header {
  border-bottom: 1px solid var(--el-border-color-light);
}
.el-tabs--card > .el-tabs__header .el-tabs__nav-wrap::after {
  content: none;
}
.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 1px solid var(--el-border-color-light);
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
}
.el-tabs--card > .el-tabs__header .el-tabs__active-bar {
  display: none;
}
.el-tabs--card > .el-tabs__header .el-tabs__item .is-icon-close {
  position: relative;
  font-size: 12px;
  width: 0;
  height: 14px;
  vertical-align: middle;
  line-height: 15px;
  overflow: hidden;
  top: -1px;
  right: -2px;
  transform-origin: 100% 50%;
}
.el-tabs--card > .el-tabs__header .el-tabs__item {
  border-bottom: 1px solid transparent;
  border-left: 1px solid var(--el-border-color-light);
  transition: color var(--el-transition-duration) var(--el-transition-function-ease-in-out-bezier), padding var(--el-transition-duration) var(--el-transition-function-ease-in-out-bezier);
}
.el-tabs--card > .el-tabs__header .el-tabs__item:first-child {
  border-left: none;
}
.el-tabs--card > .el-tabs__header .el-tabs__item.is-closable:hover {
  padding-left: 13px;
  padding-right: 13px;
}
.el-tabs--card > .el-tabs__header .el-tabs__item.is-closable:hover .is-icon-close {
  width: 14px;
}
.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #ffffff;
}
.el-tabs--card > .el-tabs__header .el-tabs__item.is-active.is-closable {
  padding-left: 20px;
  padding-right: 20px;
}
.el-tabs--card > .el-tabs__header .el-tabs__item.is-active.is-closable .is-icon-close {
  width: 14px;
}

.el-tabs--border-card {
  background: var(--el-bg-color-overlay);
  border: 1px solid var(--el-border-color);
}
.el-tabs--border-card > .el-tabs__content {
  padding: 15px;
}
.el-tabs--border-card > .el-tabs__header {
  background-color: var(--el-fill-color-light);
  border-bottom: 1px solid var(--el-border-color-light);
  margin: 0;
}
.el-tabs--border-card > .el-tabs__header .el-tabs__nav-wrap::after {
  content: none;
}
.el-tabs--border-card > .el-tabs__header .el-tabs__item {
  transition: all var(--el-transition-duration) var(--el-transition-function-ease-in-out-bezier);
  border: 1px solid transparent;
  margin-top: -1px;
  color: var(--el-text-color-secondary);
}
.el-tabs--border-card > .el-tabs__header .el-tabs__item:first-child {
  margin-left: -1px;
}
.el-tabs--border-card > .el-tabs__header .el-tabs__item + .el-tabs__item {
  margin-left: -1px;
}
.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: var(--el-color-primary);
  background-color: var(--el-bg-color-overlay);
  border-right-color: var(--el-border-color);
  border-left-color: var(--el-border-color);
}
.el-tabs--border-card > .el-tabs__header .el-tabs__item:not(.is-disabled):hover {
  color: var(--el-color-primary);
}
.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-disabled {
  color: var(--el-disabled-text-color);
}
.el-tabs--border-card > .el-tabs__header .is-scrollable .el-tabs__item:first-child {
  margin-left: 0;
}

.el-tabs--top .el-tabs__item.is-top:nth-child(2),
.el-tabs--top .el-tabs__item.is-bottom:nth-child(2), .el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
.el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2) {
  padding-left: 0;
}
.el-tabs--top .el-tabs__item.is-top:last-child,
.el-tabs--top .el-tabs__item.is-bottom:last-child, .el-tabs--bottom .el-tabs__item.is-top:last-child,
.el-tabs--bottom .el-tabs__item.is-bottom:last-child {
  padding-right: 0;
}
.el-tabs--top.el-tabs--border-card > .el-tabs__header .el-tabs__item:nth-child(2), .el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top .el-tabs--left > .el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top .el-tabs--right > .el-tabs__header .el-tabs__item:nth-child(2), .el-tabs--bottom.el-tabs--border-card > .el-tabs__header .el-tabs__item:nth-child(2), .el-tabs--bottom.el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom .el-tabs--left > .el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom .el-tabs--right > .el-tabs__header .el-tabs__item:nth-child(2) {
  padding-left: 20px;
}
.el-tabs--top.el-tabs--border-card > .el-tabs__header .el-tabs__item:last-child, .el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item:last-child,
.el-tabs--top .el-tabs--left > .el-tabs__header .el-tabs__item:last-child,
.el-tabs--top .el-tabs--right > .el-tabs__header .el-tabs__item:last-child, .el-tabs--bottom.el-tabs--border-card > .el-tabs__header .el-tabs__item:last-child, .el-tabs--bottom.el-tabs--card > .el-tabs__header .el-tabs__item:last-child,
.el-tabs--bottom .el-tabs--left > .el-tabs__header .el-tabs__item:last-child,
.el-tabs--bottom .el-tabs--right > .el-tabs__header .el-tabs__item:last-child {
  padding-right: 20px;
}

.el-tabs--bottom .el-tabs__header.is-bottom {
  margin-bottom: 0;
  margin-top: 10px;
}
.el-tabs--bottom.el-tabs--border-card .el-tabs__header.is-bottom {
  border-bottom: 0;
  border-top: 1px solid var(--el-border-color);
}
.el-tabs--bottom.el-tabs--border-card .el-tabs__nav-wrap.is-bottom {
  margin-top: -1px;
  margin-bottom: 0;
}
.el-tabs--bottom.el-tabs--border-card .el-tabs__item.is-bottom:not(.is-active) {
  border: 1px solid transparent;
}
.el-tabs--bottom.el-tabs--border-card .el-tabs__item.is-bottom {
  margin: 0 -1px -1px;
}

.el-tabs--left, .el-tabs--right {
  overflow: hidden;
}
.el-tabs--left .el-tabs__header.is-left,
.el-tabs--left .el-tabs__header.is-right,
.el-tabs--left .el-tabs__nav-wrap.is-left,
.el-tabs--left .el-tabs__nav-wrap.is-right,
.el-tabs--left .el-tabs__nav-scroll, .el-tabs--right .el-tabs__header.is-left,
.el-tabs--right .el-tabs__header.is-right,
.el-tabs--right .el-tabs__nav-wrap.is-left,
.el-tabs--right .el-tabs__nav-wrap.is-right,
.el-tabs--right .el-tabs__nav-scroll {
  height: 100%;
}
.el-tabs--left .el-tabs__active-bar.is-left,
.el-tabs--left .el-tabs__active-bar.is-right, .el-tabs--right .el-tabs__active-bar.is-left,
.el-tabs--right .el-tabs__active-bar.is-right {
  top: 0;
  bottom: auto;
  width: 2px;
  height: auto;
}
.el-tabs--left .el-tabs__nav-wrap.is-left,
.el-tabs--left .el-tabs__nav-wrap.is-right, .el-tabs--right .el-tabs__nav-wrap.is-left,
.el-tabs--right .el-tabs__nav-wrap.is-right {
  margin-bottom: 0;
}
.el-tabs--left .el-tabs__nav-wrap.is-left > .el-tabs__nav-prev,
.el-tabs--left .el-tabs__nav-wrap.is-left > .el-tabs__nav-next,
.el-tabs--left .el-tabs__nav-wrap.is-right > .el-tabs__nav-prev,
.el-tabs--left .el-tabs__nav-wrap.is-right > .el-tabs__nav-next, .el-tabs--right .el-tabs__nav-wrap.is-left > .el-tabs__nav-prev,
.el-tabs--right .el-tabs__nav-wrap.is-left > .el-tabs__nav-next,
.el-tabs--right .el-tabs__nav-wrap.is-right > .el-tabs__nav-prev,
.el-tabs--right .el-tabs__nav-wrap.is-right > .el-tabs__nav-next {
  height: 30px;
  line-height: 30px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.el-tabs--left .el-tabs__nav-wrap.is-left > .el-tabs__nav-prev i,
.el-tabs--left .el-tabs__nav-wrap.is-left > .el-tabs__nav-next i,
.el-tabs--left .el-tabs__nav-wrap.is-right > .el-tabs__nav-prev i,
.el-tabs--left .el-tabs__nav-wrap.is-right > .el-tabs__nav-next i, .el-tabs--right .el-tabs__nav-wrap.is-left > .el-tabs__nav-prev i,
.el-tabs--right .el-tabs__nav-wrap.is-left > .el-tabs__nav-next i,
.el-tabs--right .el-tabs__nav-wrap.is-right > .el-tabs__nav-prev i,
.el-tabs--right .el-tabs__nav-wrap.is-right > .el-tabs__nav-next i {
  transform: rotateZ(90deg);
}
.el-tabs--left .el-tabs__nav-wrap.is-left > .el-tabs__nav-prev,
.el-tabs--left .el-tabs__nav-wrap.is-right > .el-tabs__nav-prev, .el-tabs--right .el-tabs__nav-wrap.is-left > .el-tabs__nav-prev,
.el-tabs--right .el-tabs__nav-wrap.is-right > .el-tabs__nav-prev {
  left: auto;
  top: 0;
}
.el-tabs--left .el-tabs__nav-wrap.is-left > .el-tabs__nav-next,
.el-tabs--left .el-tabs__nav-wrap.is-right > .el-tabs__nav-next, .el-tabs--right .el-tabs__nav-wrap.is-left > .el-tabs__nav-next,
.el-tabs--right .el-tabs__nav-wrap.is-right > .el-tabs__nav-next {
  right: auto;
  bottom: 0;
}
.el-tabs--left .el-tabs__nav-wrap.is-left.is-scrollable,
.el-tabs--left .el-tabs__nav-wrap.is-right.is-scrollable, .el-tabs--right .el-tabs__nav-wrap.is-left.is-scrollable,
.el-tabs--right .el-tabs__nav-wrap.is-right.is-scrollable {
  padding: 30px 0;
}
.el-tabs--left .el-tabs__nav-wrap.is-left::after,
.el-tabs--left .el-tabs__nav-wrap.is-right::after, .el-tabs--right .el-tabs__nav-wrap.is-left::after,
.el-tabs--right .el-tabs__nav-wrap.is-right::after {
  height: 100%;
  width: 2px;
  bottom: auto;
  top: 0;
}
.el-tabs--left .el-tabs__nav.is-left,
.el-tabs--left .el-tabs__nav.is-right, .el-tabs--right .el-tabs__nav.is-left,
.el-tabs--right .el-tabs__nav.is-right {
  float: none;
}
.el-tabs--left .el-tabs__item.is-left,
.el-tabs--left .el-tabs__item.is-right, .el-tabs--right .el-tabs__item.is-left,
.el-tabs--right .el-tabs__item.is-right {
  display: block;
}

.el-tabs--left .el-tabs__header.is-left {
  float: left;
  margin-bottom: 0;
  margin-right: 10px;
}
.el-tabs--left .el-tabs__nav-wrap.is-left {
  margin-right: -1px;
}
.el-tabs--left .el-tabs__nav-wrap.is-left::after {
  left: auto;
  right: 0;
}
.el-tabs--left .el-tabs__active-bar.is-left {
  right: 0;
  left: auto;
}
.el-tabs--left .el-tabs__item.is-left {
  text-align: right;
}
.el-tabs--left.el-tabs--card .el-tabs__active-bar.is-left {
  display: none;
}
.el-tabs--left.el-tabs--card .el-tabs__item.is-left {
  border-left: none;
  border-right: 1px solid var(--el-border-color-light);
  border-bottom: none;
  border-top: 1px solid var(--el-border-color-light);
  text-align: left;
}
.el-tabs--left.el-tabs--card .el-tabs__item.is-left:first-child {
  border-right: 1px solid var(--el-border-color-light);
  border-top: none;
}
.el-tabs--left.el-tabs--card .el-tabs__item.is-left.is-active {
  border: 1px solid var(--el-border-color-light);
  border-right-color: #fff;
  border-left: none;
  border-bottom: none;
}
.el-tabs--left.el-tabs--card .el-tabs__item.is-left.is-active:first-child {
  border-top: none;
}
.el-tabs--left.el-tabs--card .el-tabs__item.is-left.is-active:last-child {
  border-bottom: none;
}
.el-tabs--left.el-tabs--card .el-tabs__nav {
  border-radius: 4px 0 0 4px;
  border-bottom: 1px solid var(--el-border-color-light);
  border-right: none;
}
.el-tabs--left.el-tabs--card .el-tabs__new-tab {
  float: none;
}
.el-tabs--left.el-tabs--border-card .el-tabs__header.is-left {
  border-right: 1px solid var(--el-border-color);
}
.el-tabs--left.el-tabs--border-card .el-tabs__item.is-left {
  border: 1px solid transparent;
  margin: -1px 0 -1px -1px;
}
.el-tabs--left.el-tabs--border-card .el-tabs__item.is-left.is-active {
  border-color: transparent;
  border-top-color: rgb(209, 219, 229);
  border-bottom-color: rgb(209, 219, 229);
}

.el-tabs--right .el-tabs__header.is-right {
  float: right;
  margin-bottom: 0;
  margin-left: 10px;
}
.el-tabs--right .el-tabs__nav-wrap.is-right {
  margin-left: -1px;
}
.el-tabs--right .el-tabs__nav-wrap.is-right::after {
  left: 0;
  right: auto;
}
.el-tabs--right .el-tabs__active-bar.is-right {
  left: 0;
}
.el-tabs--right.el-tabs--card .el-tabs__active-bar.is-right {
  display: none;
}
.el-tabs--right.el-tabs--card .el-tabs__item.is-right {
  border-bottom: none;
  border-top: 1px solid var(--el-border-color-light);
}
.el-tabs--right.el-tabs--card .el-tabs__item.is-right:first-child {
  border-left: 1px solid var(--el-border-color-light);
  border-top: none;
}
.el-tabs--right.el-tabs--card .el-tabs__item.is-right.is-active {
  border: 1px solid var(--el-border-color-light);
  border-left-color: #fff;
  border-right: none;
  border-bottom: none;
}
.el-tabs--right.el-tabs--card .el-tabs__item.is-right.is-active:first-child {
  border-top: none;
}
.el-tabs--right.el-tabs--card .el-tabs__item.is-right.is-active:last-child {
  border-bottom: none;
}
.el-tabs--right.el-tabs--card .el-tabs__nav {
  border-radius: 0 4px 4px 0;
  border-bottom: 1px solid var(--el-border-color-light);
  border-left: none;
}
.el-tabs--right.el-tabs--border-card .el-tabs__header.is-right {
  border-left: 1px solid var(--el-border-color);
}
.el-tabs--right.el-tabs--border-card .el-tabs__item.is-right {
  border: 1px solid transparent;
  margin: -1px -1px -1px 0;
}
.el-tabs--right.el-tabs--border-card .el-tabs__item.is-right.is-active {
  border-color: transparent;
  border-top-color: rgb(209, 219, 229);
  border-bottom-color: rgb(209, 219, 229);
}

.slideInRight-transition,
.slideInLeft-transition {
  display: inline-block;
}

.slideInRight-enter {
  animation: slideInRight-enter var(--el-transition-duration);
}

.slideInRight-leave {
  position: absolute;
  left: 0;
  right: 0;
  animation: slideInRight-leave var(--el-transition-duration);
}

.slideInLeft-enter {
  animation: slideInLeft-enter var(--el-transition-duration);
}

.slideInLeft-leave {
  position: absolute;
  left: 0;
  right: 0;
  animation: slideInLeft-leave var(--el-transition-duration);
}

@keyframes slideInRight-enter {
  0% {
    opacity: 0;
    transform-origin: 0 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform-origin: 0 0;
    transform: translateX(0);
  }
}
@keyframes slideInRight-leave {
  0% {
    transform-origin: 0 0;
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform-origin: 0 0;
    transform: translateX(100%);
    opacity: 0;
  }
}
@keyframes slideInLeft-enter {
  0% {
    opacity: 0;
    transform-origin: 0 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform-origin: 0 0;
    transform: translateX(0);
  }
}
@keyframes slideInLeft-leave {
  0% {
    transform-origin: 0 0;
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform-origin: 0 0;
    transform: translateX(-100%);
    opacity: 0;
  }
}
.el-tag {
  --el-tag-font-size: 12px;
  --el-tag-border-radius: 4px;
  --el-tag-border-radius-rounded: 9999px;
}

.el-tag {
  --el-tag-bg-color: var(--el-color-primary-light-9);
  --el-tag-border-color: var(--el-color-primary-light-8);
  --el-tag-hover-color: var(--el-color-primary);
  --el-tag-text-color: var(--el-color-primary);
  background-color: var(--el-tag-bg-color);
  border-color: var(--el-tag-border-color);
  color: var(--el-tag-text-color);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  padding: 0 9px;
  font-size: var(--el-tag-font-size);
  line-height: 1;
  border-width: 1px;
  border-style: solid;
  border-radius: var(--el-tag-border-radius);
  box-sizing: border-box;
  white-space: nowrap;
  --el-icon-size: 14px;
}
.el-tag.el-tag--primary {
  --el-tag-bg-color: var(--el-color-primary-light-9);
  --el-tag-border-color: var(--el-color-primary-light-8);
  --el-tag-hover-color: var(--el-color-primary);
}
.el-tag.el-tag--success {
  --el-tag-bg-color: var(--el-color-success-light-9);
  --el-tag-border-color: var(--el-color-success-light-8);
  --el-tag-hover-color: var(--el-color-success);
}
.el-tag.el-tag--warning {
  --el-tag-bg-color: var(--el-color-warning-light-9);
  --el-tag-border-color: var(--el-color-warning-light-8);
  --el-tag-hover-color: var(--el-color-warning);
}
.el-tag.el-tag--danger {
  --el-tag-bg-color: var(--el-color-danger-light-9);
  --el-tag-border-color: var(--el-color-danger-light-8);
  --el-tag-hover-color: var(--el-color-danger);
}
.el-tag.el-tag--error {
  --el-tag-bg-color: var(--el-color-error-light-9);
  --el-tag-border-color: var(--el-color-error-light-8);
  --el-tag-hover-color: var(--el-color-error);
}
.el-tag.el-tag--info {
  --el-tag-bg-color: var(--el-color-info-light-9);
  --el-tag-border-color: var(--el-color-info-light-8);
  --el-tag-hover-color: var(--el-color-info);
}
.el-tag.el-tag--primary {
  --el-tag-text-color: var(--el-color-primary);
}
.el-tag.el-tag--success {
  --el-tag-text-color: var(--el-color-success);
}
.el-tag.el-tag--warning {
  --el-tag-text-color: var(--el-color-warning);
}
.el-tag.el-tag--danger {
  --el-tag-text-color: var(--el-color-danger);
}
.el-tag.el-tag--error {
  --el-tag-text-color: var(--el-color-error);
}
.el-tag.el-tag--info {
  --el-tag-text-color: var(--el-color-info);
}
.el-tag.is-hit {
  border-color: var(--el-color-primary);
}

.el-tag.is-round {
  border-radius: var(--el-tag-border-radius-rounded);
}

.el-tag .el-tag__close {
  color: var(--el-tag-text-color);
}
.el-tag .el-tag__close:hover {
  color: var(--el-color-white);
  background-color: var(--el-tag-hover-color);
}
.el-tag .el-icon {
  border-radius: 50%;
  cursor: pointer;
  font-size: calc(var(--el-icon-size) - 2px);
  height: var(--el-icon-size);
  width: var(--el-icon-size);
}
.el-tag .el-tag__close {
  margin-left: 6px;
}
.el-tag--dark {
  --el-tag-bg-color: var(--el-color-primary);
  --el-tag-border-color: var(--el-color-primary);
  --el-tag-hover-color: var(--el-color-primary-light-3);
  --el-tag-text-color: var(--el-color-white);
  --el-tag-text-color: var(--el-color-white);
}
.el-tag--dark.el-tag--primary {
  --el-tag-bg-color: var(--el-color-primary);
  --el-tag-border-color: var(--el-color-primary);
  --el-tag-hover-color: var(--el-color-primary-light-3);
}
.el-tag--dark.el-tag--success {
  --el-tag-bg-color: var(--el-color-success);
  --el-tag-border-color: var(--el-color-success);
  --el-tag-hover-color: var(--el-color-success-light-3);
}
.el-tag--dark.el-tag--warning {
  --el-tag-bg-color: var(--el-color-warning);
  --el-tag-border-color: var(--el-color-warning);
  --el-tag-hover-color: var(--el-color-warning-light-3);
}
.el-tag--dark.el-tag--danger {
  --el-tag-bg-color: var(--el-color-danger);
  --el-tag-border-color: var(--el-color-danger);
  --el-tag-hover-color: var(--el-color-danger-light-3);
}
.el-tag--dark.el-tag--error {
  --el-tag-bg-color: var(--el-color-error);
  --el-tag-border-color: var(--el-color-error);
  --el-tag-hover-color: var(--el-color-error-light-3);
}
.el-tag--dark.el-tag--info {
  --el-tag-bg-color: var(--el-color-info);
  --el-tag-border-color: var(--el-color-info);
  --el-tag-hover-color: var(--el-color-info-light-3);
}
.el-tag--dark.el-tag--primary {
  --el-tag-text-color: var(--el-color-white);
}
.el-tag--dark.el-tag--success {
  --el-tag-text-color: var(--el-color-white);
}
.el-tag--dark.el-tag--warning {
  --el-tag-text-color: var(--el-color-white);
}
.el-tag--dark.el-tag--danger {
  --el-tag-text-color: var(--el-color-white);
}
.el-tag--dark.el-tag--error {
  --el-tag-text-color: var(--el-color-white);
}
.el-tag--dark.el-tag--info {
  --el-tag-text-color: var(--el-color-white);
}

.el-tag--plain {
  --el-tag-bg-color: var(--el-fill-color-blank);
  --el-tag-border-color: var(--el-color-primary-light-5);
  --el-tag-hover-color: var(--el-color-primary);
  --el-tag-bg-color: var(--el-fill-color-blank);
}
.el-tag--plain.el-tag--primary {
  --el-tag-bg-color: var(--el-fill-color-blank);
  --el-tag-border-color: var(--el-color-primary-light-5);
  --el-tag-hover-color: var(--el-color-primary);
}
.el-tag--plain.el-tag--success {
  --el-tag-bg-color: var(--el-fill-color-blank);
  --el-tag-border-color: var(--el-color-success-light-5);
  --el-tag-hover-color: var(--el-color-success);
}
.el-tag--plain.el-tag--warning {
  --el-tag-bg-color: var(--el-fill-color-blank);
  --el-tag-border-color: var(--el-color-warning-light-5);
  --el-tag-hover-color: var(--el-color-warning);
}
.el-tag--plain.el-tag--danger {
  --el-tag-bg-color: var(--el-fill-color-blank);
  --el-tag-border-color: var(--el-color-danger-light-5);
  --el-tag-hover-color: var(--el-color-danger);
}
.el-tag--plain.el-tag--error {
  --el-tag-bg-color: var(--el-fill-color-blank);
  --el-tag-border-color: var(--el-color-error-light-5);
  --el-tag-hover-color: var(--el-color-error);
}
.el-tag--plain.el-tag--info {
  --el-tag-bg-color: var(--el-fill-color-blank);
  --el-tag-border-color: var(--el-color-info-light-5);
  --el-tag-hover-color: var(--el-color-info);
}

.el-tag.is-closable {
  padding-right: 5px;
}
.el-tag--large {
  padding: 0 11px;
  height: 32px;
  --el-icon-size: 16px;
}
.el-tag--large .el-tag__close {
  margin-left: 8px;
}
.el-tag--large.is-closable {
  padding-right: 7px;
}

.el-tag--small {
  padding: 0 7px;
  height: 20px;
  --el-icon-size: 12px;
}
.el-tag--small .el-tag__close {
  margin-left: 4px;
}
.el-tag--small.is-closable {
  padding-right: 3px;
}

.el-tag--small .el-icon-close {
  transform: scale(0.8);
}

.el-tag.el-tag--primary.is-hit {
  border-color: var(--el-color-primary);
}

.el-tag.el-tag--success.is-hit {
  border-color: var(--el-color-success);
}

.el-tag.el-tag--warning.is-hit {
  border-color: var(--el-color-warning);
}

.el-tag.el-tag--danger.is-hit {
  border-color: var(--el-color-danger);
}

.el-tag.el-tag--error.is-hit {
  border-color: var(--el-color-error);
}

.el-tag.el-tag--info.is-hit {
  border-color: var(--el-color-info);
}

.el-select-group {
  margin: 0;
  padding: 0;
}
.el-select-group__wrap {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
}
.el-select-group__wrap:not(:last-of-type) {
  padding-bottom: 24px;
}
.el-select-group__wrap:not(:last-of-type)::after {
  content: "";
  position: absolute;
  display: block;
  left: 20px;
  right: 20px;
  bottom: 12px;
  height: 1px;
  background: var(--el-border-color-light);
}

.el-select-group__split-dash {
  position: absolute;
  left: 20px;
  right: 20px;
  height: 1px;
  background: var(--el-border-color-light);
}

.el-select-group__title {
  padding-left: 20px;
  font-size: 12px;
  color: var(--el-color-info);
  line-height: 30px;
}

.el-select-group .el-select-dropdown__item {
  padding-left: 20px;
}

.el-select-dropdown__option-item {
  font-size: var(--el-select-font-size);
  padding: 0 32px 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--el-text-color-regular);
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
  cursor: pointer;
}
.el-select-dropdown__option-item.is-disabled {
  color: var(--el-text-color-placeholder);
  cursor: not-allowed;
}
.el-select-dropdown__option-item.is-disabled:hover {
  background-color: var(--el-color-white);
}

.el-select-dropdown__option-item.is-selected {
  background-color: var(--el-fill-color-light);
  font-weight: 700;
}
.el-select-dropdown__option-item.is-selected:not(.is-multiple) {
  color: var(--el-color-primary);
}

.el-select-dropdown__option-item.hover {
  background-color: var(--el-fill-color-light) !important;
}
.el-select-dropdown__option-item:hover {
  background-color: var(--el-fill-color-light);
}

.el-select-dropdown.is-multiple .el-select-dropdown__option-item.is-selected {
  color: var(--el-color-primary);
  background-color: var(--el-bg-color-overlay);
}
.el-select-dropdown.is-multiple .el-select-dropdown__option-item.is-selected .el-icon {
  position: absolute;
  right: 20px;
  top: 0;
  height: inherit;
  font-size: 12px;
}
.el-select-dropdown.is-multiple .el-select-dropdown__option-item.is-selected .el-icon svg {
  height: inherit;
  vertical-align: middle;
}

.el-image__error, .el-image__placeholder, .el-image__inner {
  width: 100%;
  height: 100%;
}

.el-image {
  position: relative;
  display: inline-block;
  overflow: hidden;
}
.el-image__inner {
  vertical-align: top;
}

.el-image__placeholder {
  background: var(--el-fill-color-light);
}

.el-image__error {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-placeholder);
  vertical-align: middle;
}

.el-image__preview {
  cursor: pointer;
}

.el-image-viewer__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.el-image-viewer__btn {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 0.8;
  cursor: pointer;
  box-sizing: border-box;
  user-select: none;
}
.el-image-viewer__btn .el-icon {
  font-size: inherit;
  cursor: pointer;
}

.el-image-viewer__close {
  top: 40px;
  right: 40px;
  width: 40px;
  height: 40px;
  font-size: 40px;
}

.el-image-viewer__canvas {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.el-image-viewer__actions {
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  width: 282px;
  height: 44px;
  padding: 0 23px;
  background-color: var(--el-text-color-regular);
  border-color: #fff;
  border-radius: 22px;
}
.el-image-viewer__actions__inner {
  width: 100%;
  height: 100%;
  text-align: justify;
  cursor: default;
  font-size: 23px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.el-image-viewer__prev {
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
  width: 44px;
  height: 44px;
  font-size: 24px;
  color: #fff;
  background-color: var(--el-text-color-regular);
  border-color: #fff;
}

.el-image-viewer__next {
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
  text-indent: 2px;
  width: 44px;
  height: 44px;
  font-size: 24px;
  color: #fff;
  background-color: var(--el-text-color-regular);
  border-color: #fff;
}

.el-image-viewer__close {
  width: 44px;
  height: 44px;
  font-size: 24px;
  color: #fff;
  background-color: var(--el-text-color-regular);
  border-color: #fff;
}

.el-image-viewer__mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.5;
  background: #000;
}

.viewer-fade-enter-active {
  animation: viewer-fade-in var(--el-transition-duration);
}

.viewer-fade-leave-active {
  animation: viewer-fade-out var(--el-transition-duration);
}

@keyframes viewer-fade-in {
  0% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes viewer-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
}
.el-descriptions {
  --el-descriptions-table-border: 1px solid var(--el-border-color-lighter);
  --el-descriptions-item-bordered-label-background: var(--el-fill-color-light);
  box-sizing: border-box;
  font-size: var(--el-font-size-base);
  color: var(--el-text-color-primary);
}
.el-descriptions__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.el-descriptions__title {
  color: var(--el-text-color-primary);
  font-size: 16px;
  font-weight: bold;
}

.el-descriptions__body {
  background-color: var(--el-fill-color-blank);
}
.el-descriptions__body .el-descriptions__table {
  border-collapse: collapse;
  width: 100%;
}
.el-descriptions__body .el-descriptions__table .el-descriptions__cell {
  box-sizing: border-box;
  text-align: left;
  font-weight: normal;
  line-height: 23px;
  font-size: 14px;
}
.el-descriptions__body .el-descriptions__table .el-descriptions__cell.is-left {
  text-align: left;
}

.el-descriptions__body .el-descriptions__table .el-descriptions__cell.is-center {
  text-align: center;
}

.el-descriptions__body .el-descriptions__table .el-descriptions__cell.is-right {
  text-align: right;
}

.el-descriptions__body .el-descriptions__table.is-bordered .el-descriptions__cell {
  border: var(--el-descriptions-table-border);
  padding: 8px 11px;
}
.el-descriptions__body .el-descriptions__table:not(.is-bordered) .el-descriptions__cell {
  padding-bottom: 12px;
}

.el-descriptions--large {
  font-size: 12px;
}
.el-descriptions--large .el-descriptions__header {
  margin-bottom: 20px;
}
.el-descriptions--large .el-descriptions__header .el-descriptions__title {
  font-size: 16px;
}

.el-descriptions--large .el-descriptions__body .el-descriptions__table .el-descriptions__cell {
  font-size: 14px;
}
.el-descriptions--large .el-descriptions__body .el-descriptions__table.is-bordered .el-descriptions__cell {
  padding: 12px 15px;
}
.el-descriptions--large .el-descriptions__body .el-descriptions__table:not(.is-bordered) .el-descriptions__cell {
  padding-bottom: 16px;
}

.el-descriptions--small {
  font-size: 10px;
}
.el-descriptions--small .el-descriptions__header {
  margin-bottom: 12px;
}
.el-descriptions--small .el-descriptions__header .el-descriptions__title {
  font-size: 14px;
}

.el-descriptions--small .el-descriptions__body .el-descriptions__table .el-descriptions__cell {
  font-size: 12px;
}
.el-descriptions--small .el-descriptions__body .el-descriptions__table.is-bordered .el-descriptions__cell {
  padding: 4px 7px;
}
.el-descriptions--small .el-descriptions__body .el-descriptions__table:not(.is-bordered) .el-descriptions__cell {
  padding-bottom: 8px;
}

.el-descriptions__label.el-descriptions__cell.is-bordered-label {
  font-weight: bold;
  color: var(--el-text-color-regular);
  background: var(--el-descriptions-item-bordered-label-background);
}
.el-descriptions__label:not(.is-bordered-label) {
  color: var(--el-text-color-primary);
  margin-right: 16px;
}
.el-descriptions__label.el-descriptions__cell:not(.is-bordered-label).is-vertical-label {
  padding-bottom: 6px;
}

.el-descriptions__content.el-descriptions__cell.is-bordered-content {
  color: var(--el-text-color-primary);
}
.el-descriptions__content:not(.is-bordered-label) {
  color: var(--el-text-color-regular);
}

.el-descriptions--large .el-descriptions__label:not(.is-bordered-label) {
  margin-right: 16px;
}
.el-descriptions--large .el-descriptions__label.el-descriptions__cell:not(.is-bordered-label).is-vertical-label {
  padding-bottom: 8px;
}

.el-descriptions--small .el-descriptions__label:not(.is-bordered-label) {
  margin-right: 12px;
}
.el-descriptions--small .el-descriptions__label.el-descriptions__cell:not(.is-bordered-label).is-vertical-label {
  padding-bottom: 4px;
}

.el-button:not(.el-button--primary):not(.el-button--danger):not(.el-button--info) {
  border-width: 2px;
  border-color: #116732;
  border-radius: 4px;
  color: #116732;
}
.el-button:not(.el-button--primary):not(.el-button--danger):not(.el-button--info).is-disabled {
  color: #888888;
  border-color: #888888;
}
.el-button:not(.el-button--primary):not(.el-button--danger):not(.el-button--info):hover:not(.is-disabled), .el-button:not(.el-button--primary):not(.el-button--danger):not(.el-button--info):focus:not(.is-disabled) {
  border-color: #116732;
}
.el-button:not(.el-button--primary):not(.el-button--danger):not(.el-button--info).icon-button {
  padding-left: 6px;
  padding-right: 6px;
}

.el-input, .el-textarea {
  font: 12px Roboto, sans-serif;
}
.el-input .el-input__wrapper, .el-input .el-textarea__inner, .el-textarea .el-input__wrapper, .el-textarea .el-textarea__inner {
  box-shadow: 0 0 0 2px #4F5A6E inset;
}

.el-input .el-input__wrapper {
  box-shadow: 0 0 0 2px #4F5A6E inset;
}
.el-input .el-input-group__prepend {
  width: 1px;
}
.el-input .centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.el-textarea__inner {
  padding: 5px 7px;
}

.el-radio-group {
  display: flex;
}
.el-radio-group .el-radio-button {
  flex: 1;
  height: 30px;
}
.el-radio-group .el-radio-button__inner {
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-color: #4F5A6E;
}
.el-radio-group .el-radio-button--large {
  height: 40px;
}

.el-radio-button:first-child .el-radio-button__inner {
  border-left-width: 2px;
  border-left-color: #4F5A6E;
}

.el-textarea.span-2 .el-textarea__inner {
  height: 91px;
}

.el-select {
  display: block;
}

.el-select-dropdown__item {
  font: 12px Roboto, sans-serif;
  line-height: 30px;
  height: 30px;
}

.el-select-dropdown__item.selected {
  font-weight: 500;
}

.el-select .el-select__tags-text {
  justify-content: left;
}

.el-select-group__title {
  color: black;
  font-size: 18px;
}

.el-dialog__header {
  border-bottom: 1px solid #a3edbf;
  padding: 15px;
}

.el-dialog__title {
  font: 18px "Neo Sans Pro Regular", serif;
}

.el-dialog__body {
  padding: 0;
  font: 12px Roboto, sans-serif;
}

.el-tabs__item {
  font: 12px Roboto, sans-serif;
  font-weight: 500;
  height: 32px;
  line-height: 32px;
}

.el-tabs__nav-wrap::after {
  background-color: #e3faec;
  line-height: 32px;
  height: 32px;
}

.el-tabs__header {
  background-color: #e3faec;
  margin: 0;
  padding: 0 10px;
}

.el-tabs__nav-next, .el-tabs__nav-prev {
  z-index: 2;
  line-height: 32px !important;
  height: 32px !important;
}

.status-panel {
  display: flex;
  justify-content: space-between;
}

.img-warning {
  color: #dc122a;
}
.img-warning div {
  word-break: normal;
  margin-bottom: 20px;
}

.el-upload__tip {
  font-size: 12px;
  color: #606266;
  margin-top: 7px;
  font-style: italic;
}

.el-upload__upload_button {
  margin-left: 10px;
}

.el-upload-list {
  margin: 15px 0 0;
  padding: 0;
  list-style: none;
  position: relative;
}
.el-upload-list__item {
  margin-bottom: 5px;
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
}
.el-upload-list__item:last-child {
  margin-bottom: 0;
}
.el-upload-list__item-name {
  cursor: pointer;
}
.el-upload-list__item-info {
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 30px);
  margin-left: 4px;
}
.el-upload-list__item-status-label {
  position: absolute;
  right: 5px;
  top: 0;
  line-height: inherit;
  display: inline-flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: var(--el-color-success);
}
.el-upload-list__item .el-icon--close {
  display: none;
  position: absolute;
  right: 5px;
  top: 50%;
  cursor: pointer;
  opacity: 0.75;
  color: var(--el-color-danger);
  transition: opacity var(--el-transition-duration);
  transform: translateY(-50%);
}
.el-upload-list__item .el-icon--close-tip {
  display: none;
  position: absolute;
  top: 1px;
  right: 5px;
  font-size: 12px;
  cursor: pointer;
  opacity: 1;
  color: var(--el-color-primary);
  font-style: normal;
}
.el-upload-list__item:hover .el-upload-list__item-status-label {
  display: none;
  opacity: 0;
}
.el-upload-list__item:hover .el-icon--close {
  display: inline-flex;
}

.controls {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  row-gap: 12px;
}
.controls .el-button + .el-button {
  margin-left: 0;
}

.loading-block {
  width: 100%;
  height: 30px;
}

.category-tree-btn {
  margin-left: 15px;
}

.el-descriptions__title {
  font-size: 14px;
  /* Розмір шрифту для заголовків */
}
.el-descriptions .el-descriptions__body .el-descriptions__table .el-descriptions__cell {
  font-size: 12px;
  /* Розмір шрифту для тексту в комірках */
  white-space: nowrap;
  /* Забороняє перенос тексту */
  overflow: hidden;
  /* Обрізає текст, якщо він не вміщується */
  text-overflow: ellipsis;
  /* Додає три крапки для обрізаного тексту */
  vertical-align: middle;
  /* Вирівнює текст вертикально по центру */
}
.el-descriptions .el-descriptions__body .el-descriptions__table.is-bordered .el-descriptions__cell {
  padding: 4px 11px;
  /* Відступи у межах комірок */
}

a {
  color: #17783C;
  text-decoration: none;
}
a:hover {
  color: #65e194;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.rigid-column {
  display: flex;
  flex-direction: column;
  flex: none;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.rigid-row {
  display: flex;
  flex-direction: row;
  flex: none;
}

.items-center {
  align-items: center;
}

.flex-expand {
  flex: 1;
}

.flex-shrink {
  flex: none;
}

.mb-15 {
  margin-bottom: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-15 {
  padding-left: 15px;
}

.mb-80 {
  margin-bottom: 80px;
}

.w-full {
  width: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 7px;
}

.question > .el-col {
  display: flex;
  align-items: flex-end;
}
.question > .el-col .form-row {
  word-break: break-word;
  width: 100%;
}
.question > .el-col .form-row .el-col {
  margin-top: 5px !important;
}

.answer .el-checkbox {
  display: flex;
  align-items: flex-end;
}
.answer .content {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  flex-wrap: wrap;
}
.answer .checkbox-field-input {
  max-width: 250px;
}

.special-title label {
  font-size: 16px;
  font-weight: bold;
}

.page-heading__header {
  display: flex;
  gap: 15px;
  align-items: center;
}
.page-heading__header .buttons {
  display: flex;
  gap: 40px;
  align-items: center;
}

.main-control {
  height: 420px;
  overflow-y: auto;
}
.main-control .buttons {
  display: flex;
  justify-content: space-between;
}
.main-control .buttons .el-button {
  flex: 1;
  text-transform: uppercase;
}
.main-control .el-button.grey {
  color: #6D7C97 !important;
  border-color: #6D7C97 !important;
}
.main-control .el-col {
  margin-top: 10px;
}

.search-filter {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.search-filter .filter-buttons {
  padding: 10px 0;
}

.img-preview {
  max-width: 200px;
  height: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
}

body {
  margin: 0;
  padding: 0;
  background: none;
  font: 12px Roboto, sans-serif;
}

.main-container {
  min-height: 100vh;
}

.el-main {
  padding: 0;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.page .page-heading {
  padding: 0 10px;
  flex: none;
}
.page .page-heading__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page .page-heading__header__addons {
  display: flex;
  align-items: center;
  gap: 40px;
}
.page .page-heading__header h2 {
  font: 24px "Neo Sans Pro Regular", serif;
  margin: 10px 0;
}

section.dialog-body {
  padding: 15px 40px;
}

section.dialog-body + section.dialog-body {
  border-top: 1px solid #a3edbf;
}

section.dialog-footer {
  padding: 15px;
  border-top: 1px solid #a3edbf;
  text-align: right;
}

section.popover-footer {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid #a3edbf;
  text-align: right;
}

.el-col {
  margin-top: 20px;
}

.bg-login {
  background: url("/svg/start-screen.png") #116732 no-repeat center;
  background-size: cover;
}
.bg-login .content .el-main {
  padding: 20px !important;
}

.login-form-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 370px;
  margin: 0 auto;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  color: #FFFFFF;
}
.login-form-content .login-app-logo {
  width: 300px;
  height: 130px;
  background: url("/svg/app-logo.svg") #116732 no-repeat center;
  border-radius: 12px;
}
.login-form-content h1 {
  color: #FFFFFF;
  margin: 25px 0 20px 0;
  font: 24px "Neo Sans Pro Regular", serif;
  line-height: 30px;
}
.login-form-content .login-form-data {
  max-width: 200px;
}
.login-form-content .form-row {
  text-align: start;
}
.login-form-content .form-row label {
  font: 10px Roboto, sans-serif;
  color: #FFFFFF;
}
.login-form-content .login-form-input {
  text-align: left;
  font: 12px Roboto, sans-serif;
}
.login-form-content .form-footer {
  margin: 20px 0;
}
.login-form-content .form-input {
  margin-bottom: 10px;
}
.login-form-content .el-input .el-input__inner {
  background: none;
  border: none;
  border-radius: 0;
  color: #000000;
  height: auto;
  line-height: 30px;
  padding-left: 0;
}
.login-form-content .el-input .el-input__inner:focus {
  color: #000000;
}
.login-form-content label {
  text-align: left;
  color: #FFFFFF;
}
.login-form-content .btn-diia {
  width: 180px;
  height: 50px;
  background: url("/svg/idGovUaDiia.svg") #116732 no-repeat center;
  background-size: 75% auto;
}
.login-form-content .btn-diia1 {
  width: 180px;
  height: 50px;
  background: url("/svg/idgov_logo.png") no-repeat center;
  background-size: 75% auto;
  cursor: pointer;
}
.login-form-content .btn-diia1:hover {
  box-shadow: 0 0 0 2px #FFFFFF inset;
  border-radius: 4px;
}

h1 {
  font: inherit;
  font-size: 24px;
}

.el-input__wrapper {
  width: -webkit-fill-available;
}

.footer-buttons {
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.footer-buttons .button {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0 20px;
  width: -webkit-fill-available;
}

.el-aside {
  transition: width 0.5s;
}

.aside-wrapper {
  background-color: #17783C;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0 8px 8px 0;
}
.aside-wrapper .logo, .aside-wrapper .menu-footer {
  flex-shrink: 1;
}
.aside-wrapper .side-menu-container {
  flex-grow: 1;
}
.aside-wrapper .logo {
  font: 18px "Neo Sans Pro Regular", serif;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  color: #FFFFFF;
  overflow: hidden;
  white-space: nowrap;
}
.aside-wrapper .logo .logo-item {
  flex: none;
  width: 97px;
  height: 32px;
  background: url("/svg/sidebar-logo.svg") #17783C no-repeat center;
  margin-left: 15px;
  border-radius: 8px;
}
.aside-wrapper .logo .version {
  margin-right: 15px;
}
.aside-wrapper .menu-footer {
  margin: 5px;
  border-top: 1px solid #136331;
  color: #FFFFFF;
  padding: 10px 5px 5px 5px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.aside-wrapper .menu-footer .user-info {
  flex-grow: 1;
}
.aside-wrapper .menu-footer .user-info .name {
  margin: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: auto;
  font: 14px Roboto, sans-serif;
}
.aside-wrapper .menu-footer .user-info svg {
  width: 28px;
  height: 28px;
}
.aside-wrapper .menu-footer .toggle-menu-btn {
  width: 28px;
  height: 28px;
  flex-shrink: 1;
  position: relative;
}
.aside-wrapper .menu-footer .toggle-menu-btn::before {
  content: " ";
  width: 14px;
  height: 14px;
  border-top: 3px solid #FFFFFF;
  border-left: 3px solid #FFFFFF;
  display: block;
  top: 50%;
  left: 50%;
  margin: -7px 0 0 -7px;
  position: absolute;
  transform: rotate(-45deg);
  transition: transform 0.5s;
}
.aside-wrapper.collapsed .menu-footer .toggle-menu-btn::before {
  transform: rotate(135deg);
}
.aside-wrapper.collapsed .el-menu-item .svg-icon svg {
  margin-right: 0;
}
.aside-wrapper.collapsed .logo .logo-item {
  width: 60px;
  background: url("/svg/min-logo.svg") #17783C no-repeat center;
  margin-left: 0;
  height: 40px;
}
.aside-wrapper .el-menu-item {
  font: 12px Roboto, sans-serif;
  font-weight: 500 !important;
  display: flex;
  padding: 0 10px !important;
  height: 40px;
}
.aside-wrapper .el-menu-item .el-tooltip__trigger {
  padding: 0 10px !important;
}
.aside-wrapper .el-menu-item .svg-icon svg {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  margin-left: 5px;
}
.aside-wrapper .el-menu-item.is-active {
  background: #116732;
}

.el-menu--horizontal {
  border-bottom: none;
  background-color: #FFFFFF;
}
.el-menu--horizontal .el-menu-item {
  height: 30px;
  font: 12px Roboto, sans-serif;
  color: #4F5A6E;
  font-weight: 500;
  border-bottom-width: 3px;
}
.el-menu--horizontal .el-menu-item.is-active {
  color: #116732 !important;
  border-bottom-color: #116732;
  border-bottom-width: 3px;
}
.el-menu--horizontal .el-sub-menu.is-active .el-sub-menu__title {
  border-bottom-color: #116732;
  border-bottom-width: 3px;
}
.el-menu--horizontal .el-sub-menu .el-sub-menu__title:hover {
  color: #116732;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus {
  background-color: #FFFFFF;
  color: #116732 !important;
}

.svg-icon {
  display: inline-block;
}
.svg-icon svg, .svg-icon span {
  vertical-align: middle;
}
.svg-icon span {
  margin-left: 5px;
}
.svg-icon svg {
  width: 25px;
  height: 25px;
  fill: #116732;
}
.svg-icon.disabled svg {
  fill: grey !important;
  cursor: default !important;
}
.svg-icon.icon-large svg {
  width: 30px;
  height: 30px;
}
.svg-icon.icon-small svg {
  width: 20px;
  height: 20px;
}
.svg-icon.icon-tiny svg {
  width: 16px;
  height: 16px;
}
.svg-icon.icon-xtiny svg {
  width: 13px;
  height: 13px;
}
.svg-icon.icon-tiddly svg {
  width: 10px;
  height: 10px;
}
.svg-icon.icon-white svg {
  fill: #FFFFFF;
}
.svg-icon.icon-grey svg {
  fill: #17783C;
}
.svg-icon.icon-hover:hover svg {
  fill: #116732;
}
.svg-icon.icon-white-hover:hover svg {
  fill: #FFFFFF;
}
.svg-icon.icon-grey-hover:hover svg {
  fill: #17783C;
}

.employee_pib {
  padding: 10px;
  font: 18px "Neo Sans Pro Regular", serif;
}

.form-row + .form-row,
.form-block + .form-block,
.form-block + .form-row,
.form-row + .form-block {
  margin-top: 15px;
}

.form-row__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.form-row__header label {
  margin-bottom: 2px;
  display: inline-block;
  font-weight: 500;
  color: #4F5A6E;
}
.form-row .errors {
  padding: 0;
  margin: 1px 0 0 0;
  list-style: none;
  position: absolute;
}
.form-row .errors li {
  color: #CF0C47;
  font: 10px Roboto, sans-serif;
}
.form-row.inline {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.form-row.inline label {
  flex: 1;
  margin-bottom: 0;
  color: #4F5A6E;
}
.form-row.inline-between {
  justify-content: space-between;
}
.form-row .daterange {
  margin-bottom: 10px;
}

.required label:not(.el-checkbox)::after {
  content: "*";
  color: #CF0C47;
}

.form-input-group {
  display: flex;
  justify-content: center;
  gap: 5px;
}
.form-input-group .form-row + .form-row,
.form-input-group .form-block + .form-block,
.form-input-group .form-block + .form-row,
.form-input-group .form-row + .form-block {
  margin-top: 0 !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.hidden {
  display: none;
}

.data-table .el-table {
  font: 12px Roboto, sans-serif;
}
.data-table .el-table .cell {
  line-height: 18px;
}
.data-table .el-table th .column-title, .data-table .el-table td .cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.data-table .el-table .error-row {
  --el-table-tr-bg-color: var(--el-color-error-light-9);
}
.data-table .footer {
  border-top: 1px solid #a3edbf;
  padding: 8px 10px;
}
.data-table .footer .pages {
  align-items: center;
}
.data-table .footer .pages > * {
  margin-left: 10px;
}
.data-table .el-table__cell.is-sortable .cell {
  position: relative;
  padding-right: 24px;
}
.data-table .el-table__cell.is-sortable .cell .caret-wrapper {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -7px;
}

.table-settings label.el-checkbox {
  height: auto;
  display: block;
  margin: 0 0 5px 0;
  max-width: 250px;
}
.table-settings label.el-checkbox .el-checkbox__label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tree-component {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 15px;
}
.tree-component .tree-container {
  overflow-y: auto;
}
.tree-component .tree-container .el-tree .el-tree-node:hover > .el-tree-node__content {
  background-color: #E3FAEC;
}
.tree-component .tree-container .el-tree .el-tree-node.is-expanded > .el-tree-node__content .el-tree-node__expand-icon {
  transform: rotate(90deg);
  transition: transform 0.3s ease-in-out;
}
.tree-component .tree-container .el-tree .el-tree-node__content {
  font-size: 16px;
  line-height: 21px;
  color: #4F5A6E;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
}
.tree-component .tree-container .el-tree .el-tree-node__content .item-tree-controls {
  display: none;
  gap: 15px;
}
.tree-component .tree-container .el-tree .el-tree-node__content:hover .item-tree-controls {
  display: inline-flex;
}
.tree-component .tree-container .el-tree .el-tree-node .grey {
  color: #888888;
}
.tree-component .tree-container .el-tree .el-tree-node__expand-icon {
  transform: rotate(0);
  transition: transform 0.3s ease-in-out;
  padding-right: 5px;
}
.tree-component .tree-container .el-tree .el-tree-node__expand-icon.is-leaf {
  color: transparent;
  cursor: default;
}
.tree-component .tree-container .el-tree .el-tree-node .custom-tree-node__additional {
  margin-left: 15px;
}
.tree-component .tree-container .el-tree .el-tree-node.is-current > .el-tree-node__content {
  background-color: #E3FAEC;
}

.change-log {
  line-height: 150%;
  white-space: normal;
}
.change-log .attribute {
  font-weight: 500;
}
.change-log .old {
  text-decoration: line-through;
}

.cv-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.cv-wrapper,
.cv-wrapper div {
  box-sizing: border-box;
  line-height: 1em;
  font-size: 1em;
}

.cv-header-days {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  flex-flow: row nowrap;
  border-width: 0 0 0 1px;
}

.cv-header-day {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-width: 1px 1px 0 0;
}

.cv-weeks {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  flex-flow: column nowrap;
  border-width: 0 0 1px 1px;
  overflow-y: auto;
  -ms-overflow-style: none;
}

.cv-weeknumber {
  width: 2rem;
  position: relative;
  text-align: center;
  border-width: 1px 1px 0 0;
  border-style: solid;
  line-height: 1;
}

.cv-week {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  flex-flow: row nowrap;
  min-height: 3em;
  border-width: 0;
  position: relative;
  width: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
}

.cv-weekdays {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  flex-flow: row nowrap;
  direction: ltr;
  position: relative;
  overflow-y: auto;
}

.cv-day {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border-width: 1px 1px 0 0;
  direction: initial;
}

.cv-day-number {
  height: auto;
  align-self: flex-start;
}

.cv-day[draggable],
.cv-item[draggable] {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.cv-wrapper.wrap-item-title-on-hover .cv-item:hover {
  white-space: normal;
  z-index: 1;
}

.cv-header-days,
.cv-header-day,
.cv-weeks,
.cv-week,
.cv-day {
  border-style: solid;
  border-color: #a3edbf;
}

.cv-item {
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  direction: initial;
  background-color: #e3faec;
  color: #116732;
  border-radius: 3px;
  padding: 6px 3px;
}
.cv-item .endTime::before {
  content: " - ";
}
.cv-item .endTime::after {
  content: ".";
}

.cv-header-day {
  padding: 0.2em;
}

.cv-day-number {
  padding: 5px;
  flex-grow: 1;
}

.cv-item.offset0 {
  left: 0;
}

.cv-item.offset1 {
  left: 14.28571%;
}

.cv-item.offset2 {
  left: 28.57143%;
}

.cv-item.offset3 {
  left: 42.85714%;
}

.cv-item.offset4 {
  left: 57.14286%;
}

.cv-item.offset5 {
  left: 71.42857%;
}

.cv-item.offset6 {
  left: 85.71429%;
}

.cv-item.span1 {
  width: calc(14.28571% - 0.05em);
}

.cv-item.span2 {
  width: calc(28.57143% - 0.05em);
}

.cv-item.span3 {
  width: calc(42.85714% - 0.05em);
}

.cv-item.span4 {
  width: calc(57.14286% - 0.05em);
}

.cv-item.span5 {
  width: calc(71.42857% - 0.05em);
}

.cv-item.span6 {
  width: calc(85.71429% - 0.05em);
}

.cv-item.span7 {
  width: calc(100% - 0.05em);
}

.holidaytime {
  background-color: red;
  color: white;
}

.cv-weeks::-webkit-scrollbar,
.cv-week::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.cv-header {
  display: flex;
  flex-grow: 1;
  flex-flow: row nowrap;
  align-items: center;
}
.cv-header button {
  border: 2px solid #116732;
  border-radius: 3px;
  background-color: #FFFFFF;
  padding: 6px 15px;
  color: #116732;
  font-weight: 600;
  margin-right: 10px;
  cursor: pointer;
}
.cv-header button:focus {
  outline: none;
}
.cv-header .periodLabel {
  font: 18px Roboto, sans-serif;
}

.schedule-container .buttons {
  border-top: 1px solid #a3edbf;
  padding: 8px 10px;
}
.schedule-container .header-container {
  border: 1px solid #a3edbf;
  padding: 8px 10px;
}

.cv-day.outsideOfMonth {
  background-color: #e3faec;
}

.cv-day .el-checkbox {
  width: 18px;
  height: 20px;
}

.schedule-form .schedule-item {
  position: relative;
}
.schedule-form .schedule-item + .schedule-item {
  padding-top: 25px;
}
.schedule-form .remove-item {
  position: absolute;
  top: 5px;
  right: 15px;
  color: #CF0C47;
}

.miision-form a {
  cursor: pointer;
}

.image-viewer {
  padding: 10px;
}

.image-cropper .buttons {
  display: flex;
  gap: 15px;
}
.image-cropper .cropper-wrapper {
  display: flex;
  justify-content: center;
}
.image-cropper .cropper-wrapper img {
  max-width: 100%;
  height: auto;
}
.image-cropper .cropper-wrapper .cropper {
  margin-top: 30px;
  display: flex;
  gap: 15px;
  justify-content: center;
}
.image-cropper .cropper-wrapper .cropper__cropper {
  width: 300px;
}
.image-cropper .cropper-wrapper .cropper__preview {
  overflow: hidden;
  width: 105px;
  height: 135px;
}

.el-upload {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  outline: none;
}
.el-upload__input {
  display: none;
}
.el-upload__tip {
  font-size: 12px;
  color: #606266;
  margin-top: 7px;
}

.pie-chart-set {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
}

.analytics-filters {
  padding: 20px;
}
.analytics-filters .buttons {
  margin-top: 20px;
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sample-component {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 109px);
}
.sample-component .sample-condition-content {
  display: flex;
  height: calc(100vh - 300px);
}
.sample-component .sample-condition-content .sample-condition-table {
  flex: 1;
  overflow-y: auto;
  margin-right: 15px;
}
.sample-component .sample-condition-content .sample-condition {
  flex: 2;
  margin-bottom: 15px;
}
.sample-component .sample-result-content {
  height: 300px;
  width: 100%;
}