@use '../variables' as *;

$result-content-height: 300px;
$hat-height: 109px;

.sample-component {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - $hat-height);

    .sample-condition-content {
        display: flex;
        height: calc(100vh - $result-content-height);
        .sample-condition-table {
            flex: 1;
            overflow-y: auto;
            margin-right: 15px;
        }

        .sample-condition {
            flex: 2;
            margin-bottom: 15px;

            .sample-condition-list {

                .sample-control {

                }
            }
            .sample-condition-button-group {

            }
        }
    }

    .sample-result-content {
        height: $result-content-height;
        width: 100%;
    }
}
